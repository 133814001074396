import React, { useContext } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { AccordionContext, useAccordionToggle } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import './accordion.css';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { QUESTIONS } from './questions';

function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 15px',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        color: '#fff',
      }}
      className='collapse_question_row'
      onClick={decoratedOnClick}
    >
      {children}
      {isCurrentEventKey ? (
        <RemoveIcon style={{ color: 'white' }} />
      ) : (
        <AddIcon style={{ color: 'white' }} />
      )}
    </div>
  );
}

export default function FAQList() {
  const data = QUESTIONS;
  return (
    <div className='container'>
      <div className='block'>
        <div className='header'>
          <h1 className='accordion_h_text'>Frequently Asked Questions (FAQ)</h1>
        </div>
        <div className='accordion_description'>
          <p className='accordion_desc_text'>
            Here are some common questions small business owners had while signing up for FREE
            business listing or posting a deal on{' '}
            <span className='accordion_desc_highlighted'>Mom n Pop Hub.</span>
          </p>
        </div>
        <div
          style={{
            border: '1px solid rgba(0, 0, 0, 0.1)',
            boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.07)',
            borderRadius: '8px',
          }}
        >
          {data.map((item, index) => {
            return (
              <Accordion defaultActiveKey={1}>
                <Card
                  key={item.id}
                  style={{
                    marginBottom: data.length === index + 1 ? '0' : '12px',
                    border: 'none',
                  }}
                >
                  <Card.Header>
                    <ContextAwareToggle eventKey={item.id}>{item.question}</ContextAwareToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey={item.id}>
                    <Card.Body dangerouslySetInnerHTML={{ __html: item.answer }}></Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            );
          })}
        </div>
      </div>
    </div>
  );
}
