import './ShareDeals.css';

import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import Loader from 'react-loader-spinner';
import Moment from 'react-moment';
import { useHistory, useParams } from 'react-router-dom';

import { callApi } from '../../../api/API';
import onlineLogo from '../../../assets/images/online_logo.png';
import oflineLogo from '../../../assets/images/store_logo.png';
import { _empty } from '../../../utils/utils';
import ShareModal from '../../share-bussiness/react-share/ShareModal';
import ShareBussinessApp from '../../share-bussiness/share-bussiness-app/shareBussinessApp';
import DateIcon from './dateicon';
import DealIcon from './dealTicketIcon';
import Globeicon from './globeicon';
import LocationIcon from './locationIcon';
import PercentageIcon from './percentageIcon';

const ShareDeals = () => {
  const { deal_id } = useParams();
  const history = useHistory();
  const id = deal_id.split('-', 2);
  const dealId = id[0];
  const [deal, setDeal] = useState(null);
  const [urlredirect, setUrlredirect] = useState('');
  const [mapUrl, setMapUrl] = useState('');
  const [modal, setModal] = useState(false);
  const [streetAdd, setstreetAdd] = useState('');
  const [addLocal, setaddLocal] = useState('');
  const [addRegio, setaddRegio] = useState('');
  const [postalCO, setpostalCO] = useState('');
  const [geoLAT, setgeoLAT] = useState('');
  const [geoLONGI, setgeoLONGI] = useState('');
  const [metaDescri, setmetaDescri] = useState('');
  const [metaTitle, setmetaTitle] = useState('');
  const [loadDealApiCall, setLoadDealApiCall] = useState({
    didCall: false,
    success: false,
    error: false,
  });
  const loadDealData = useCallback(async () => {
    setLoadDealApiCall({ didCall: true, success: false, error: false });
    const result = await callApi(`/businessinfo/dealjson/${deal_id}`, 'POST', {
      id: dealId,
      email: '',
    });
    // console.log(result)

    let tempTitle = result.deal.name + ' at ' + result.profile.bname;
    let add = result.profile.baddress;
    // console.log(add.length)
    if (add.length !== 0) {
      let tempAdd = add.split(',');
      // console.log("tempAdd")

      if (tempAdd[1] !== undefined) {
        tempTitle += ' | ' + tempAdd[1];
      }
      if (tempAdd[3] !== undefined) {
        tempTitle += ' | ' + tempAdd[3];
      }
    }

    tempTitle += ' - Mom n Pop Hub';
    setmetaTitle(tempTitle);

    let tempdes = '';

    if (result.deal.description.length >= 120) {
      tempdes += result.deal.description.slice(0, 120) + '...';
    } else {
      tempdes += result.deal.description;
    }

    tempdes += ' | ' + result.profile.bname;

    if (result.profile.baddress) {
      let tempAdd = add.split(',');
      // console.log("tempAdd")

      if (tempAdd[1] !== undefined) {
        tempdes += ' , ' + tempAdd[1];
      }
      if (tempAdd[3] !== undefined) {
        tempdes += ' , ' + tempAdd[3];
      }
    }
    tempdes += ' | Mom n Pop Hub';
    setmetaDescri(tempdes);

    setDeal({ deal: result.deal, profile: result.profile });

    if (!_empty(result)) {
      const url = 'https://' + result.profile.website;
      // console.log(result.profile.website);
      setUrlredirect(url);

      const mapUr =
        'http://maps.google.com/maps?q=' + result.profile.lat + ',' + result.profile.long;
      // console.log(mapUr);
      setMapUrl(mapUr);

      setLoadDealApiCall({ didCall: false, success: true, error: false });
    } else {
      setLoadDealApiCall({ didCall: false, success: false, error: true });
    }
  }, []);

  const goBackToProfile = (name, id) => {
    var bname = name.split(' ');
    var newName = '';
    for (var i = 0; i < bname.length; i++) {
      newName = newName + '-' + bname[i];
    }
    newName = newName.replace('%', 'percent');
    const ShareUrl = '/businessinfo/profile/' + id + newName;
    history.push(ShareUrl);
  };
  useEffect(() => {
    loadDealData();
  }, [loadDealData]);

  const showModal = modal ? (
    <ShareModal
      businessDisc={deal.deal.description}
      showModal={modal}
      toggleModal={() => setModal(!modal)}
      shareurl={'https://test.momnpophub.combusinessinfo/deal/' + deal_id}
      businessName={deal.deal.name}
      titleheader='Share this deal with your friends and family'
    />
  ) : null;
  return (
    <>
      {loadDealApiCall.didCall && (
        <div className='text-center'>
          <Loader type='Bars' color='#FF992E' />
          <p>Loading profile information...</p>
        </div>
      )}

      {loadDealApiCall.success && (
        <div className='part_block'>
          {showModal}
          <Helmet>
            <title>{metaTitle}</title>
            <meta name='description' content={metaDescri}></meta>
            <link rel='canonical' href={location.pathname} />

            <meta name='keywords' content='Deal details Mom n Pop Hub, Deal Mom n Pop Hub'></meta>
            <meta name='publisher' content='Mom n Pop Hub'></meta>
            <meta property='twitter:title' content={metaTitle} />
            <meta property='twitter:description' content={metaDescri} />
            <meta
              property='twitter:image'
              content={deal.profile.bname == 'online' ? onlineLogo : oflineLogo}
            />
            <meta property='twitter:card' content={metaDescri} />
            <meta property='twitter:site' content='twitter id' />

            <meta property='og:title' content={metaTitle} />
            <meta property='og:description' content={metaDescri} />
            <meta property='og:publisher' content='Mom n Pop Hub' />
            <meta property='og:locale' content='en_US' />
            <meta property='og:type' content='website' />
            <meta
              property='og:image'
              content={deal.profile.bname == 'online' ? onlineLogo : oflineLogo}
            />
            <meta property='og:site_name' content='https://momnpophub.com/' />
            <meta property='og:url' content={location.origin} />
            <meta property='og:url' content={location.href} />
          </Helmet>
          <div className='container_share_deal'>
            <div className='bus_top'>
              <ShareBussinessApp />
            </div>
            <div className='d_body'>
              <div className='d-flex flex-wrap justify-content-between align-items-start border-bottom py-3 mb-2 px-4'>
                <div className='d-flex justify-content-between align-items-start'>
                  <a
                    className='title m-0 align-items-center linked_name_title'
                    onClick={() => {
                      goBackToProfile(deal.profile.bname, deal.deal.userid);
                    }}
                  >
                    {deal.profile.bname}
                  </a>
                  <span className='dealName_breadcrumb'>
                    {' '}
                    {'>'} {deal.deal.name}
                  </span>
                </div>
                <a
                  // onClick={shareProfilePage}
                  className='btn bg-transparent border-0 shadow-none p-0'
                >
                  <i className='icon-share-square' onClick={() => setModal(!modal)} />
                </a>
              </div>

              <div className='row_share_deal d_mid_body'>
                <div className='d_part1'>
                  {deal.profile.bname == 'online' ? (
                    <img
                      src={onlineLogo}
                      width='65px'
                      height='65px'
                      alt='Online Logo Mom n Pop Hub'
                    />
                  ) : (
                    <img
                      src={oflineLogo}
                      width='65px'
                      height='65px'
                      alt='Store Logo Mom n Pop Hub'
                    />
                  )}
                </div>
                <div className='d_part2'>
                  <div className='row'>
                    <div
                      className='row_share_deal'
                      style={{
                        marginBottom: '7px',
                        padding: '0px 30px',
                        flexDirection: 'column',
                      }}
                    >
                      <a className='disc_title'>
                        <h1> {deal.profile.bname}</h1>
                      </a>
                      <div className='div_disc'>
                        <h2>{deal.profile.description}</h2>
                      </div>
                    </div>
                  </div>
                  <div className='row' style={{ marginBottom: '10px', padding: '0px 15px' }}>
                    <div className='col-lg-3 col-md-6 col-sm-12 col-12 d_item'>
                      <div className='d_item_icon'>
                        <DealIcon />
                      </div>
                      <div className='d_item_detail'>
                        <div className='d_detail_txt1'>
                          <h3>{deal.deal.name}</h3>
                        </div>
                        <div className='d_detail_txt2'> {deal.deal.description} </div>
                      </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-12 col-12 d_item'>
                      <div className='d_item_icon'>
                        <PercentageIcon />
                      </div>
                      <div className='d_item_detail'>
                        <div className='d_detail_txt1'>
                          <h3>${deal.deal.originalprice} for</h3>
                        </div>
                        <div className='d_detail_txt2'> ${deal.deal.price} </div>
                      </div>
                    </div>
                  </div>

                  <div className='row' style={{ marginBottom: '10px', padding: '0px 15px' }}>
                    <div className='col-lg-3 col-md-6 col-sm-12 col-12 d_item'>
                      <div className='d_item_icon'>
                        <DateIcon />
                      </div>
                      <div className='d_item_detail'>
                        <div className='d_detail_txt1'>
                          <h3>Pucharse Deal By</h3>
                        </div>

                        <div className='d_detail_txt2'>
                          <Moment format='MMM DD ,YYYY'>{deal.deal.enddate}</Moment>
                        </div>
                      </div>
                    </div>{' '}
                    <div className='col-lg-3 col-md-6 col-sm-12 col-12 d_item'>
                      <div className='d_item_icon'>
                        <DateIcon />
                      </div>
                      <div className='d_item_detail'>
                        <div className='d_detail_txt1'>
                          <h3>Redeem Deal By</h3>
                        </div>
                        <div className='d_detail_txt2'>
                          <Moment format='MMM DD ,YYYY'>{deal.deal.expirydate}</Moment>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row' style={{ marginBottom: '10px', padding: '0px 15px' }}>
                    {deal.profile.baddress !== null && (
                      <>
                        <div className='col-lg-3 col-md-6 col-sm-12 col-12 d_item'>
                          <div className='d_item_icon'>
                            <LocationIcon />
                          </div>
                          <div className='d_item_detail'>
                            <h3>
                              <a href={mapUrl} className='address'>
                                {deal.profile.baddress}{' '}
                              </a>
                            </h3>
                          </div>
                        </div>
                      </>
                    )}
                    {deal.profile.website !== null && (
                      <>
                        <div className='col-lg-3 col-md-6 col-sm-12 col-12 d_item'>
                          <div className='d_item_icon'>
                            <Globeicon />
                          </div>
                          <div className='d_item_detail'>
                            <a href={'https://' + deal.profile.website} className='address'>
                              {deal.profile.website}{' '}
                            </a>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className='d_bottom '>
                <div className='row py-2'>
                  <div className='col-md-6 col-sm-12'>
                    <div className='d_bottom_part'>
                      <div className='d_bottom_part_layout'>
                        <div className='d_bottom_txt1'>Deal available to buy till:</div>
                        <div className='d_bottom_txt2' id='leftTime'>
                          <Moment fromNow>{deal.deal.enddate}</Moment>
                        </div>
                      </div>
                      <div className='d_bottom_part_layout'>
                        <div className='d_bottom_txt3'> ${deal.deal.originalprice}</div>
                        <div className='d_bottom_txt4'> ${deal.deal.price}</div>
                      </div>
                      <button className='d_bottom_btn'>
                        <a href='https://onelink.to/7f52xq' className='urlLink'>
                          Download Mobile App to Buy Deal
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {loadDealApiCall.error && (
        <div className='text-center' style={{ padding: '5%' }}>
          <p style={{ color: 'red' }}>Problem loading profile</p>
          <Button onClick={() => loadDealData()}>Try again</Button>
        </div>
      )}
    </>
  );
};

export default ShareDeals;
