import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import authorIcon from '../../../assets/images/blog/author-icon.svg';
import articleImg from '../../../assets/images/blog/article-default.png';
import './articleDetail.css';
import '../components/Search/search.css';
import '../components/Category/category.css';

import Search from '../components/Search';
import Button from '../components/Button';
import Category from '../components/Category';
import ArticleCard from '../components/ArticleCard';
import { articleDelete, articleUpdate } from '../../../api/blog';
import { Helmet } from 'react-helmet';
import MobileCategory from '../components/Category/MobileCategory';
import Loader from 'react-loader-spinner';
import BreadcrumbsBlog from '../components/Breadcrumbs';

const ArticleDetailsClient = ({
  article,
  lastArticles,
  lastArticlesCategory,
  nextArticle,
  prevArticle,
  filterVisible,
  userEditor,
}) => {
  const history = useHistory();

  const handleSearchClick = (searchVal) => {
    history.push({
      pathname: '/blog',
      searchToBlog: searchVal,
    });
  };
  const handleCategoryClick = (categoryVal) => {
    if (categoryVal.machine_name === 'all-categories') {
      history.push({
        pathname: '/blog',
        categoryToBlog: categoryVal.machine_name,
      });
    }
  };
  const handleSubcategoryClick = (categoryVal, subcategoryVal) => {
    history.push({
      pathname: '/blog',
      categoryToBlog: categoryVal.machine_name,
      subcategoryToBlog: subcategoryVal.machine_name,
    });
  };

  const handleBackClick = () => {
    history.push({
      pathname: '/blog',
    });
  };

  const handleDeleteClick = async () => {
    articleDelete(article._id)
      .then(() => {
        history.push('/blog-admin-panel/');
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const handleUpdateClick = async () => {
    articleUpdate(article._id, !article?.isPublished)
      .then(() => {
        history.push('/blog-admin-panel/');
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const handleEditClick = async () => {
    history.push({
      pathname: `/blog-admin-panel/editor/${article.uniqueAddress}`,
      filterVisibleVal: filterVisible,
    });
  };

  return (
    <>
      {article && (
        <Helmet>
          <title>{article?.seoPage?.titlePage}</title>
          <meta name='title' content={article?.seoPage?.metaTitle}></meta>
          <meta name='description' content={article?.seoPage?.metaDesc}></meta>
          <meta
            name='google-site-verification'
            content='f6s0BDTGsbRUeGF7mr_BWCd9FLEx_S0cGkLHoa8t1_A'
          />
          <meta name='publisher' content='Mom n Pop Hub'></meta>
          <meta property='twitter:title' content={article?.seoPage?.titlePage} />
          <meta property='twitter:description' content={article?.seoPage?.metaDesc || ''} />

          <meta property='og:title' content={article?.seoPage?.titlePage} />
          <meta property='og:description' content={article?.seoPage?.metaDesc || ''} />
          <meta property='og:publisher' content='Mom n Pop Hub' />
          <meta property='og:locale' content='en_US' />
          <meta property='og:type' content='website' />
        </Helmet>
      )}
      <Button text='BACK' className={'articleDetails-button__back'} onClick={handleBackClick}>
        <svg
          width='7'
          height='11'
          viewBox='0 0 7 11'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M0.4375 5.12695C0.173828 5.39062 0.173828 5.83008 0.4375 6.12305L4.42188 10.1074C4.71484 10.3711 5.1543 10.3711 5.41797 10.1074L6.0918 9.43359C6.35547 9.14062 6.35547 8.70117 6.0918 8.4375L3.25 5.5957L6.0918 2.7832C6.35547 2.51953 6.35547 2.08008 6.0918 1.78711L5.41797 1.14258C5.1543 0.849609 4.71484 0.849609 4.42188 1.14258L0.4375 5.12695Z'
            fill='#787878'
          />
        </svg>
      </Button>
      <section className='articleDetails'>
        {!article?.title ? (
          <Loader type='Bars' color='#FF992E' height={30} width={100} timeout={2000} />
        ) : (
          <div className='articleDetails__container'>
            <BreadcrumbsBlog pageName={article?.title} isBlogPage={true} />
            <div className='articleDetails__article'>
              <div className='articleDetails__article-title'>
                <img
                  className='articleDetails__article-image'
                  src={
                    !!article?.imageArticle
                      ? article?.imageArticle
                      : articleImg
                  }
                />
                <div className='articleDetails__article-content'>
                  <h1>{article?.title}</h1>
                  <img
                    className='articleDetails__article-image_mobile'
                    src={article?.imageArticle ? article?.imageArticle : articleImg}
                  />
                  <div className='articleDetails__article-meta'>
                    <div className='articleDetails__article-date'>
                      <span>{article?.createdAt}</span>
                    </div>
                    <div className='articleDetails__article-author'>
                      <img
                        src={authorIcon}
                        alt='Author Icon'
                        className='articleDetails__article-author-icon'
                      />
                      <span>{article?.author}</span>
                    </div>
                    <div className='articleDetails__article-category'>{article?.categoryName}</div>
                  </div>
                </div>
              </div>
              <div className='articleDetails__article-description'>
                <div className='articleDetails__article-body'>
                  {userEditor && (
                    <div className='articleDetails__buttons-edit'>
                      <div>
                        <button onClick={handleEditClick}>EDIT Article</button>
                        <button onClick={handleUpdateClick}>
                          {article?.isPublished ? 'HIDE Article' : 'PUBLISH Article'}
                        </button>
                      </div>
                      <button onClick={handleDeleteClick}>DELETE Article</button>
                    </div>
                  )}
                  <div className='articleDetails__article-text'>
                    {ReactHtmlParser(article?.description)}
                    <div className='articleDetails__article-tag'>
                      <div>{article?.categoryName}</div>
                      <div>{article?.subCategoryName}</div>
                    </div>
                  </div>
                  {!userEditor && (
                    <div className='articleDetails__buttons'>
                      {!!Object.keys(prevArticle).length ? (
                        <Link to={`/blog/${prevArticle.uniqueAddress}`}>
                          <Button text={`Prev.`}>
                            <svg
                              width='11'
                              height='8'
                              viewBox='0 0 11 8'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path d='M0.646446 3.64645C0.451184 3.84171 0.451184 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.97631 4.7308 0.659728 4.53553 0.464466C4.34027 0.269203 4.02369 0.269203 3.82843 0.464465L0.646446 3.64645ZM11 3.5L1 3.5L1 4.5L11 4.5L11 3.5Z' />
                            </svg>
                          </Button>
                        </Link>
                      ) : (
                        <Button text={`Prev.`} isDisabled={!Object.keys(prevArticle).length}>
                          <svg
                            width='11'
                            height='8'
                            viewBox='0 0 11 8'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path d='M0.646446 3.64645C0.451184 3.84171 0.451184 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.97631 4.7308 0.659728 4.53553 0.464466C4.34027 0.269203 4.02369 0.269203 3.82843 0.464465L0.646446 3.64645ZM11 3.5L1 3.5L1 4.5L11 4.5L11 3.5Z' />
                          </svg>
                        </Button>
                      )}
                      {!!Object.keys(nextArticle).length ? (
                        <Link to={`/blog/${nextArticle.uniqueAddress}`}>
                          <Button text={'Next'}>
                            <svg
                              width='11'
                              height='8'
                              viewBox='0 0 11 8'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path d='M10.3536 4.35355C10.5488 4.15829 10.5488 3.84171 10.3536 3.64644L7.17157 0.464465C6.97631 0.269203 6.65973 0.269203 6.46447 0.464465C6.2692 0.659727 6.2692 0.97631 6.46447 1.17157L9.29289 4L6.46447 6.82843C6.2692 7.02369 6.2692 7.34027 6.46447 7.53553C6.65973 7.73079 6.97631 7.73079 7.17157 7.53553L10.3536 4.35355ZM8.74228e-08 4.5L10 4.5L10 3.5L-8.74228e-08 3.5L8.74228e-08 4.5Z' />
                            </svg>
                          </Button>
                        </Link>
                      ) : (
                        <Button text={'Next'} isDisabled={!Object.keys(nextArticle).length}>
                          <svg
                            width='11'
                            height='8'
                            viewBox='0 0 11 8'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path d='M10.3536 4.35355C10.5488 4.15829 10.5488 3.84171 10.3536 3.64644L7.17157 0.464465C6.97631 0.269203 6.65973 0.269203 6.46447 0.464465C6.2692 0.659727 6.2692 0.97631 6.46447 1.17157L9.29289 4L6.46447 6.82843C6.2692 7.02369 6.2692 7.34027 6.46447 7.53553C6.65973 7.73079 6.97631 7.73079 7.17157 7.53553L10.3536 4.35355ZM8.74228e-08 4.5L10 4.5L10 3.5L-8.74228e-08 3.5L8.74228e-08 4.5Z' />
                          </svg>
                        </Button>
                      )}
                    </div>
                  )}
                  {!userEditor && lastArticlesCategory && (
                    <div className='articleDetails__lastarticle'>
                      {lastArticlesCategory.map((article, index) => (
                        <ArticleCard article={article} key={index} />
                      ))}
                    </div>
                  )}
                </div>
                <aside className='articleDetails__article-aside'>
                  <Search onSearch={handleSearchClick}>
                    <Button className={'articleDetails__article-search_button'} text={'Search'} />
                  </Search>
                  <div className='articleDetails__article-aside__categorypost'>
                    {!userEditor && lastArticles && lastArticles.length > 0 && (
                      <div className='articleDetails__recentposts'>
                        <h3>Recent Posts</h3>
                        {lastArticles.map((article, index) => (
                          <ArticleCard article={article} key={index} isVisible={false} />
                        ))}
                      </div>
                    )}
                    {article?.category && (
                      <Category
                        startCategory={article?.category}
                        startSubCategory={article?.subCategory}
                        onCategoryClick={handleCategoryClick}
                        onSubcategoryClick={handleSubcategoryClick}
                        className={'articleDetails__article-aside-category'}
                      />
                    )}
                    {article?.category && (
                      <MobileCategory
                        startCategory={article?.category}
                        startSubCategory={article?.subCategory}
                        onCategoryClick={handleCategoryClick}
                        onSubcategoryClick={handleSubcategoryClick}
                        className={'articleDetails__article-aside-mobilecategory'}
                      />
                    )}
                  </div>
                </aside>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default ArticleDetailsClient;
