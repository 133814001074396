import React from "react";

function withDeepLink(WrappedComponent) {
  return (props) => {
    React.useEffect(() => {
      if (typeof window !== 'undefined') {
        const userAgent = window?.navigator?.userAgent || window?.navigator?.vendor || window?.opera;
        const playStoreURL = 'https://play.google.com/store/apps/details?id=com.momnpophub';
        const appStoreURL = 'https://apps.apple.com/us/app/local-small-shop-mom-n-pop-hub/id1494101666';
        const isMobileBrowser = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent,);

        if (isMobileBrowser) {
          setTimeout(() => {
            if (/Android/i.test(userAgent)) {
              window.location.href = playStoreURL; // Redirect Android users to Play Store
            } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
              window.location.href = appStoreURL; // Redirect iOS users to App Store
            }
          }, 100);
        }
      }
    }, []);

    return <WrappedComponent {...props} />;
  }
}

export default withDeepLink;