import './shareBussinessApp.css';

import React from 'react';

function ShareBussinessApp() {
  const openApp = () => {};
  return (
    <>
      <div className='bus_top_title'>
        Please, download mobile app for viewing business details and buying deals at your nearby
        Momnpop Shop
      </div>
      <button className='bus_top_btn'>
        <a href='https://onelink.to/7f52xq' className='downloadLink'>
          Open App
        </a>
      </button>
    </>
  );
}

export default ShareBussinessApp;
