import React from 'react';

const DealIcon = (props) => (
  <svg width={21} height={14} viewBox='0 0 21 14' fill='none' {...props}>
    <path
      d='M4.875 3.875V10.625H16.125V3.875H4.875ZM18.9375 7.25C18.9375 6.33594 19.6758 5.5625 20.625 5.5625V2.1875C20.625 1.27344 19.8516 0.5 18.9375 0.5H2.0625C1.11328 0.5 0.375 1.27344 0.375 2.1875V5.5625C1.28906 5.5625 2.0625 6.33594 2.0625 7.25C2.0625 8.19922 1.28906 8.9375 0.375 8.9375V12.3125C0.375 13.2617 1.11328 14 2.0625 14H18.9375C19.8516 14 20.625 13.2617 20.625 12.3125V8.9375C19.6758 8.9375 18.9375 8.19922 18.9375 7.25ZM17.25 3.59375V10.9062C17.25 11.3984 16.8633 11.75 16.4062 11.75H4.59375C4.10156 11.75 3.75 11.3984 3.75 10.9062V3.59375C3.75 3.136724.10156 2.75 4.59375 2.75H16.4062C16.8633 2.75 17.25 3.13672 17.25 3.59375Z'
      fill='#D0D0D0'
    />
  </svg>
);

export default DealIcon;
