import './store_desc_list.css';

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory, useParams } from 'react-router-dom';

import { callApi } from '../../../api/API';
import Exclamatory from '../../../assets/images/exclamatory.svg';
import Location from '../../../assets/images/location.svg';
import ONLINE from '../../../assets/images/online-store.svg';
import PHYSICAL from '../../../assets/images/physical-store.svg';
import Button from '../../../components/button/Button';
import SubcategoryMeta from '../../category-metadata/subcategory_meta';
import StoreDescHeader from './store_desc-header';

const StoreDescList = (props) => {
  let history = useHistory();
  const [businessList, setbsinessList] = useState([]);
  const { type, categoryname, subcategoryname } = useParams();

  const loadCats = async () => {
    const catResult = await callApi(
      `/businessinfo/getbusiness/${categoryname}/${subcategoryname}`,
      'GET',
    );
    if (!catResult.length > 0) return 'No contents.';
    setbsinessList(catResult);
  };
  try {
    localStorage.setItem('page', 'store_desc_list');
    localStorage.removeItem('id');
  } catch (e) { }

  useEffect(() => {
    loadCats();
  }, [subcategoryname]);

  let desc;
  let metaDescription = ' ';
  if (businessList.length > 0) {
    const metaDesc = businessList.map((item) => {
      desc += item.shortdescription;
      return desc;
    });
    const metaDescription = metaDesc.join().substring(0, 160);
  }

  let metadata = SubcategoryMeta.find((subcat) =>
    !!subcat.categoryname
      ? subcat.categoryname === categoryname && subcat.catname === subcategoryname
      : subcat.catname === subcategoryname,
  );

  const headersMeta = (
    <Helmet>
      {subcategoryname === 'customs-brokers' ? (
        <meta
          name='description'
          content='Claim local business listing for custom brokers at Mom n Pop Hub. The platform offer free business listing service to help custom brokers reach local customers.'
        />
      ) : null}
      {subcategoryname === 'consultants' ? (
        <meta
          name='description'
          content='Give your consulting business a boost with Mom n Pop Hub, the platform offering free listing service to the consultants. Signup today for free!'
        />
      ) : null}
      {subcategoryname === 'business-consulting' ? (
        <meta
          name='description'
          content='Small business consulting listing service at Mom n Pop Hub for free! If you run a business consulting firm, avail the service with a free signup!'
        />
      ) : null}
      {subcategoryname === 'advertising' ? (
        <meta
          name='description'
          content='List your professional Advertising Services on Mom n Pop Hub to attract and retain more customers. Visit us for free business signup!'
        />
      ) : null}
      {subcategoryname === 'pool-halls' ? (
        <meta
          name='description'
          content='If you own or operate a pool halls center, you may list it for free on Mom n Pop Hub. This will raise awareness of your business. Signup today!'
        />
      ) : null}
      {subcategoryname === 'piano-bars' ? (
        <meta
          name='description'
          content='If you run a Night life Piano Bars then list it on Mom n Pop Hub for free. This will make people more aware of your business .Sign up for free today.'
        />
      ) : null}
      {subcategoryname === 'dance-clubs' ? (
        <meta
          name='description'
          content='Add your astonishing dance club on Mom n Pop Hub for free and attract more potential customers to grow your business. Sign up today!'
        />
      ) : null}
      {subcategoryname === 'screen-printing' ? (
        <meta
          name='description'
          content='List your screen printing business on Mom n Pop Hub for free. This will make people more aware of your business. Signup now!'
        />
      ) : null}
      {subcategoryname === 'furniture-rental-&amp;-repair' ? (
        <meta
          name='description'
          content='Find more customers by submitting your Furniture Rental & Repair Business through Mom n Pop Hub for free. Signup now!'
        />
      ) : null}
      {subcategoryname === 'electronics-repair' ? (
        <meta
          name='description'
          content='If you offer Electronics Repair Services , you can list your business for free on Mom n Pop Hub. This will increase public awareness of your business. Signup now!'
        />
      ) : null}
      {subcategoryname === 'art-installation' ? (
        <meta
          name='description'
          content='Find more customers by promoting your art installation business through Mom n Pop Hub. Visit us today and get your business listed for free!'
        />
      ) : null}
      {subcategoryname === '3d-printing' ? (
        <meta
          name='description'
          content='Grow your 3D Printing business with Mom n Pop Hub. Our free listing website helps small businesses gain more exposure. Signup now!'
        />
      ) : null}
      {subcategoryname === 'travel-agents' ? (
        <meta
          name='description'
          content='Add your travel agent business on Mom n Pop Hub to reach out to more local customers in your area. Visit us today and get your business listed for free!'
        />
      ) : null}
      {subcategoryname === 'passport-&amp;-visa-services' ? (
        <meta
          name='description'
          content='Add your passport & business services on Mom n Pop Hub to make people more aware of your business and services. Visit us for free business signup now!'
        />
      ) : null}
      {subcategoryname === 'weight-loss-centers' ? (
        <meta
          name='description'
          content='Get your weight loss centers listed on Mom n Pop and get more clients! Visit us today and list your business for free.'
        />
      ) : null}
      {subcategoryname === 'rehabilitation-center' ? (
        <meta
          name='description'
          content='List your rehabilitation center business on Mom n Pop Hub. This will make people more aware of your business and services. Visit us for free business signup now!'
        />
      ) : null}
      {subcategoryname === 'medical-centers' ? (
        <meta
          name='description'
          content='If you have a medical center, add your business on Mom n Pop Hub today. A free listing to promote small businesses! Signup today!'
        />
      ) : null}
      {subcategoryname === 'medical-centerscounseling-&amp;-mental-health' ? (
        <meta
          name='description'
          content='Add a FREE listing of your counseling & mental health business on Mom n Pop Hub. Signup today or Claim you business.'
        />
      ) : null}
      {subcategoryname === 'blood-&amp;-plasma-donation-centers' ? (
        <meta
          name='description'
          content='If you own a blood and plasma donation centers, you can add your business here to connect with local audience. Signup today!'
        />
      ) : null}
      {subcategoryname === 'ayurveda' ? (
        <meta
          name='description'
          content='Mom n Pop Hub is a business listing website to help you grow your Ayurveda business. Visit us and signup now!'
        />
      ) : null}
      {subcategoryname === 'home-healthcare' ? (
        <meta
          name='description'
          content='List your Home Healthcare business at Mom n Pop Hub to reach out to more potential audience. Browse our website for free business signup!'
        />
      ) : null}
      {subcategoryname === 'diet-&amp;-nutrition' ? (
        <meta
          name='description'
          content='List your nutrition business on Mom n Pop Hub. We will help promote your business to a local audience that interested in Diet & Nutrition services.'
        />
      ) : null}
      {subcategoryname === 'clinic-&amp;-medical-center' ? (
        <meta
          name='description'
          content='Submit your clinic and medical center business on Mom n Pop Hub. We help small businesses grow and get free exposure. Signup now!'
        />
      ) : null}
      {subcategoryname === 'painters' ? (
        <meta
          name='description'
          content='Find more customers by promoting your professional painter business through Mom n Pop Hub. Visit us today and list your business for free.'
        />
      ) : null}
      {subcategoryname === 'office-&amp;-school-supplies' ? (
        <meta
          name='description'
          content='If you provide office & school supplies services, add your business on Mom n Pop Hub. Our free listing website helps small businesses gain more exposure. Signup now!'
        />
      ) : null}
      {subcategoryname === 'movers' ? (
        <meta
          name='description'
          content='List your movers and packers services on Momnpophub.com. This will help you connect with local customers in your area. Signup now!'
        />
      ) : null}
      {subcategoryname === 'home-cleaners' ? (
        <meta
          name='description'
          content="Grow your home cleaning services business by listing it on Mom N Pop Hub. It's User-friendly and free business listing service site. Signup now!"
        />
      ) : null}
      {subcategoryname === 'furniture' ? (
        <meta
          name='description'
          content='If you have a furniture store, add your store on Mom n Pop Hub today. A free listing to promote small businesses! Signup today!'
        />
      ) : null}
      {subcategoryname === 'bath' ? (
        <meta
          name='description'
          content='Sign up now on Mom n Pop Hub and add your bath stores. We are a free business listing website that helps to grow your business.'
        />
      ) : null}
      {subcategoryname === 'air-conditioning-&amp;-heating' ? (
        <meta
          name='description'
          content='Give your Air Conditioning & Heating business a better boost with listing it on Mom n Pop Hub. This is a no-cost small business listing platform. Sign up today for free to get started!'
        />
      ) : null}
      {subcategoryname === 'wineries-&amp;-distilleries' ? (
        <meta
          name='description'
          content='Easy and free business listing for Wineries & Distilleries stores at Mom n Pop Hub!  Sign up today and discover how we can help your business to grow with good ROI.'
        />
      ) : null}
      {subcategoryname === 'custom-cakes' ? (
        <meta
          name='description'
          content='Free business listing for Custom Cakes dealers or stores at Mom n Pop Hub, the ultimate platform to grow your business. Sign up for free today.'
        />
      ) : null}
      {subcategoryname === 'catering-&amp;-supplies' ? (
        <meta
          name='description'
          content='Give your Catering & Supplies business a better boost with listing it on Mom n Pop Hub. This is a no-cost small business listing platform. Sign up today for free to get started!'
        />
      ) : null}
      {subcategoryname === 'breweries' ? (
        <meta
          name='description'
          content='List your Breweries store business on Mom N Pop Hub. This is a free business listing platform. Visit us today for free signup!'
        />
      ) : null}
      {subcategoryname === 'beverage-store' ? (
        <meta
          name='description'
          content='The free business listing that help you sell your Beverage Store at Mom n Pop Hub! Visit us for free business signup and get the best returns of inverstments.'
        />
      ) : null}
      {subcategoryname === 'bagels' ? (
        <meta
          name='description'
          content='Grow your professional Bagels business with Mom n Pop Hub. Visit us today and list your business for free. Signup Now!'
        />
      ) : null}
      {subcategoryname === 'tax-services' ? (
        <meta
          name='description'
          content='List your Tax Services Business on Mom n Pop Hub for free to attract a more target audience. Visit us today for a free sign-up!'
        />
      ) : null}
      {subcategoryname === 'investing' ? (
        <meta
          name='description'
          content='List your Investing business website for free on Mom n Pop Hub for free to attract a more target audience. Signup today or claim your business.Signup Now!'
        />
      ) : null}
      {subcategoryname === 'financial-advising' ? (
        <meta
          name='description'
          content='Add your Financial Advising Office Now with Mom n Pop Hub for free. This will make people more aware of your business to attract a more target audience. Signup Now!'
        />
      ) : null}
      {subcategoryname === 'debt-relief-services' ? (
        <meta
          name='description'
          content='List your debt relief service on Mom n Pop Hub for free to attract a more target audience. Visit us today for a free sign-up!'
        />
      ) : null}
      {subcategoryname === 'currency-exchange' ? (
        <meta
          name='description'
          content='Add your currency exchange store on Mom n Pop Hub for free to reach out to a more potential target audience. Signup today or claim your business.'
        />
      ) : null}
      {subcategoryname === 'business-financing' ? (
        <meta
          name='description'
          content='Add your business financing office on Mom n Pop Hub for free and reach out to your potential target audience. Visit us today and get your business listed!'
        />
      ) : null}
      {subcategoryname === 'cooking-schools' ? (
        <meta
          name='description'
          content='List your Cooking Schools on Mom n Pop Hub! Our listing platform is free and easy to use. Add your business today!'
        />
      ) : null}
      {subcategoryname === 'college-counseling' ? (
        <meta
          name='description'
          content='List your College Counseling business on Momnpophub. This is a free business listing platform. Visit us today and add your business for free.'
        />
      ) : null}
      {subcategoryname === 'piercing' ? (
        <meta
          name='description'
          content='Submit your piercing business on Mom n Pop Hub and make it easier for customers to find you! Visit us now for free signup!'
        />
      ) : null}
      {subcategoryname === 'nail-salons' ? (
        <meta
          name='description'
          content='List your special nail salon business on Mom n Pop Hub for free to reach out to more potential audiences. No hidden costs for business sign up!'
        />
      ) : null}
      {subcategoryname === 'makeup-artists' ? (
        <meta
          name='description'
          content='Add your gorgeous makeup artist business on Mom n Pop Hub for free to reach out to more potential customers. Visit us to signup now!'
        />
      ) : null}
      {subcategoryname === 'cosmetics-&amp;-beauty-supply' ? (
        <meta
          name='description'
          content='List your Cosmetics & Beauty Supply business today on Momnpophub.com. Our listing platform is free and easy to use. Visit our website for more information!'
        />
      ) : null}
      {subcategoryname === 'used-car-dealers' ? (
        <meta
          name='description'
          content='Free business listing for used car dealers at Mom n Pop Hub, the ultimate platform to grow your business. Sign up for free today.'
        />
      ) : null}
      {subcategoryname === 'towing' ? (
        <meta
          name='description'
          content='Give your towing business a better boost with listing it on Mom n Pop Hub. This is a no-cost small business listing platform. Sign up today for free to get started!'
        />
      ) : null}
      {subcategoryname === 'car-paint' ? (
        <meta
          name='description'
          content='Grow your professional car paint business with Mom n Pop Hub. Visit us today and list your business for free. Signup Now!'
        />
      ) : null}
      {subcategoryname === 'auto-security' ? (
        <meta
          name='description'
          content='The free business listing that help you sell your auto security systems and services at Mom n Pop Hub! Visit us for free business signup!'
        />
      ) : null}
      {subcategoryname === 'auto-detailing' ? (
        <meta
          name='description'
          content="Grow your auto detailing store business by listing it on Mom n Pop Hub. It's User-friendly and free business listing service site. Sign up today for free listing!"
        />
      ) : null}
      {subcategoryname === 'sports-betting' ? (
        <meta
          name='description'
          content='Add your sports betting store on Mom n Pop Hub for free to reach out to more customers. Visit us for free business sign-up!'
        />
      ) : null}
      {subcategoryname === 'opera-&amp;-ballet' ? (
        <meta
          name='description'
          content='List your classy opera & ballet for free on Mom n Pop Hub to attract more local customers. Visit us for business sign-up!'
        />
      ) : null}
      {subcategoryname === 'haunted-houses' ? (
        <meta
          name='description'
          content="Don't wait and list your cool haunted house on Mom n Pop Hub to attract more local target audiences. Visit us and sign-up for free!"
        />
      ) : null}
      {subcategoryname === 'astrologers' ? (
        <meta
          name='description'
          content='If you are an astrologer then add your service on Mom n Pop Hub to reach out to more potential target audience. Visit us for free business sign-up!'
        />
      ) : null}
      {subcategoryname === 'art-museums' ? (
        <meta
          name='description'
          content='Add your art museums on Mom n Pop Hub for free to reach out to attract and retain more customers. Visit us now for free business sign-up!'
        />
      ) : null}
      {subcategoryname === 'scuba-diving' ? (
        <meta
          name='description'
          content='If you are running a small scuba diving business, Mom n Pop Hub is a great place to submit your business and attract new customers. Add your business for free!'
        />
      ) : null}
      {subcategoryname === 'bungee-jumping' ? (
        <meta
          name='description'
          content="Don't wait and list your Bungee Jumping store on Mom n Pop Hub to attract more local customers. Visit our website now and signup for free!"
        />
      ) : null}
      {subcategoryname === 'toys-&amp;-baby-products' ? (
        <meta
          name='description'
          content='Toys & Baby Products promotion online with free business listing service at Mom n Pop Hub! Sell useful products and make big profits from your online business.'
        />
      ) : null}
      {subcategoryname === 'clothing' ? (
        <meta
          name='description'
          content='Expand your brand awareness and take your clothing business to a wider audience with Mom n Pop Hub. List your business and get more leads with us! Signup today!'
        />
      ) : null}
      {subcategoryname === 'toys-&amp;-baby-products' ? (
        <meta
          name='description'
          content='Toys & Baby Products promotion online with free business listing service at Mom n Pop Hub! Sell useful products and make big profits from your online business.'
        />
      ) : null}
      {subcategoryname === 'stationary-&amp;-hobby-supplies' ? (
        <meta
          name='description'
          content='Do online business listing at Mom n Pop Hub and profitably sell stationary and hobby supplies! Signup today to  avail benefits and to list your business for free!'
        />
      ) : null}
      {subcategoryname === 'furniture-&amp;-household-goods' ? (
        <meta
          name='description'
          content='Online Stores for Furniture & Household Goods - Mom n Pop Hub'
        />
      ) : null}
      {subcategoryname === 'diy-graden-&amp;-pets' ? (
        <meta
          name='description'
          content='Expand your DIY, Garden & Pets business with listing it free on Mom n Pop Hub. Visit us today and list your online small business for free!'
        />
      ) : null}
      {subcategoryname === 'automotive-parts' ? (
        <meta
          name='description'
          content='At Mom n Pop Hub, list your online automotive parts business with a free signup. Our free business listing website can help you to get more ROI. Signup Now!'
        />
      ) : null}
      {subcategoryname === 'household-appliances' ? (
        <meta
          name='description'
          content='Enhance your household appliances purchase with free business listing at Mom n Pop Hub! Visit us give your online business a much needed boost!'
        />
      ) : null}
      {subcategoryname === 'cbd-&amp;-prodcts' ? (
        <meta
          name='description'
          content='Successfully sell CBD Products online with free business listing at Mom n Pop Hub! This is the reliable platform exclusive to small businesses! Signup today!'
        />
      ) : null}
      {subcategoryname === 'books-movies-games' ? (
        <meta
          name='description'
          content='Online business listing for Books, Movies and games store at Mom n Pop Hub! Sign up today to gain a strong online presence and earn more profits!'
        />
      ) : null}
      {subcategoryname === 'shoes' ? (
        <meta
          name='description'
          content='Mom n Pop Hub - The Free online directory for your shoes business! The platform offers free business listing service. Visit us for signup today!'
        />
      ) : null}
      {subcategoryname === 'electricians' ? (
        <meta
          name='description'
          content='Quick and Easy business listing service for online electronics store at Mom n Pop Hub! List your business for free with us ! Sign up today to avail benefits.'
        />
      ) : null}
      {subcategoryname === 'sports-&amp;-outdoor' ? (
        <meta
          name='description'
          content='Generate more leads and build customer loyalty by listing your Sports & Outdoor business online at Mom n Pop Hub. Signup today for free!'
        />
      ) : null}
      {subcategoryname === 'other' ? (
        <meta
          name='description'
          content='Get more leads or sales for your business with Mom n Pop Hub. This is a free business listing platform that helps small businesses get the attention they deserve.'
        />
      ) : null}
      {subcategoryname === 'cannabis-clinics' ? (
        <meta
          name='description'
          content='List your cannabis clinics business on Mom n Pop Hub. This is a free business listing platform to promote small businesses. Visit us for free business signup now!'
        />
      ) : null}

      {/* Subcategories Page title */}
      {subcategoryname === 'customs-brokers' ? (
        <title>Add Your Cutoms Brokers Office On Mom n Pop Hub - Free Signup! </title>
      ) : null}
      {subcategoryname === 'consultants' ? (
        <title>Business Listing for Consultants - Mom n Pop Hub - Signup Now!</title>
      ) : null}
      {subcategoryname === 'business-consulting' ? (
        <title>A guide to Business Consulting at Any Age with Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'advertising' ? (
        <title>Add Your Professional Advertising Services on Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'pool-halls' ? (
        <title>Free Signup Now For Add Your Pool Halls Center on Mom n Pop Hu </title>
      ) : null}
      {subcategoryname === 'piano-bars' ? (
        <title>Submit Your Night life Piano Bars On Mom n Pop Hub - Free Signup</title>
      ) : null}
      {subcategoryname === 'dance-clubs' ? (
        <title>Add Your Dance Clubs and Bar On Mom n Pop Hub - Free Signup</title>
      ) : null}
      {subcategoryname === 'screen-printing' ? (
        <title>Best Business Listing Website for Screen Printing - Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'furniture-rental-&amp;-repair' ? (
        <title>Add Your Furniture Rental & Repair Business - Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'electronics-repair' ? (
        <title>Electronics Repair Services Near Me - Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'art-installation' ? (
        <title>List Your Art Installation Business on Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === '3d-printing' ? (
        <title>
          List Your Art Installation Business on Mom n Pop Hub3D Printing Business Listing - Mom n
          Pop Hub - Join Us Now!
        </title>
      ) : null}
      {subcategoryname === 'travel-agents' ? (
        <title>Travel Agents - List Business For Free - Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'passport-&amp;-visa-services' ? (
        <title>Business Listing for Passport & Visa Services - Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'weight-loss-centers' ? (
        <title>List Your Weight Loss Centers For Free on Mom n Pop - Signup Now!</title>
      ) : null}
      {subcategoryname === 'rehabilitation-center' ? (
        <title>Submit Your Rehabilitation Center Listing on Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'medical-centers' ? (
        <title>Add Your Medical Centers on Mom n Pop Hub - Free Business Signup!</title>
      ) : null}
      {subcategoryname === 'medical-centerscounseling-&amp;-mental-health' ? (
        <title>Counseling & Mental Health Business Listing - Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'blood-&amp;-plasma-donation-centers' ? (
        <title>Add Blood & Plasma Donation Centers on Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'ayurveda' ? (
        <title>Grow Your Ayuveda Business with Mom n Pop Hub - Signup Now!</title>
      ) : null}
      {subcategoryname === 'home-healthcare' ? (
        <title>List your Home Healthcare for free - Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'diet-&amp;-nutrition' ? (
        <title>Free Business Listing Website - Diet & Nutrition - Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'clinic-&amp;-medical-center' ? (
        <title>List Clinic & Medical Center Business on Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'painters' ? (
        <title>Free Business Listing for Painters - Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'office-&amp;-school-supplies' ? (
        <title>List Office & School Supplies Services on Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'movers' ? (
        <title>Movers and Packers Near Me - Mom n Pop Hub - Signup Now!</title>
      ) : null}
      {subcategoryname === 'home-cleaners' ? (
        <title>Grow Your Home Cleaners Business with Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'furniture' ? (
        <title>List Your Furniture Store on Mom n Pop Hub For Free</title>
      ) : null}
      {subcategoryname === 'bath' ? (
        <title>Free Business Listing Website for Bath Stores - Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'air-conditioning-&amp;-heating' ? (
        <title>Submit Air Conditioning & Heating Business on Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'wineries-&amp;-distilleries' ? (
        <title>Free Business Listing For Wineries & Distilleries - Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'custom-cakes' ? (
        <title>Promote Your Custom Cakes Business with Us - Signup Now!</title>
      ) : null}
      {subcategoryname === 'catering-&amp;-supplies' ? (
        <title>Grow Your Catering & Supplies Business with Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'breweries' ? (
        <title>Add Your Breweries Business on Mom n Pop Hub - Signup Now!</title>
      ) : null}
      {subcategoryname === 'beverage-store' ? (
        <title>List Your Beverage Store on Mom n Pop Hub For Free</title>
      ) : null}
      {subcategoryname === 'bagels' ? (
        <title>Add Your Bagels Store on Mom n Pop Hub - Signup Now!</title>
      ) : null}
      {subcategoryname === 'tax-services' ? (
        <title>List Your Tax Services Business on Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'investing' ? (
        <title>Investing business website for free listing - Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'financial-advising' ? (
        <title>List your Financial Advising Office Now with Mom n Pop Hub </title>
      ) : null}
      {subcategoryname === 'debt-relief-services' ? (
        <title>List Your Debt Relief Services on Mom n Pop Hub </title>
      ) : null}
      {subcategoryname === 'currency-exchange' ? (
        <title>Free Signup - List Your Currency Exchange Services - Mom n Pop Hub </title>
      ) : null}
      {subcategoryname === 'business-financing' ? (
        <title>Add Your Financing Business Office on Mom n Pop Hub - Signup now! </title>
      ) : null}
      {subcategoryname === 'cooking-schools' ? (
        <title>Free Business Signup for Cooking Schools - Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'college-conseling' ? (
        <title>List your College Counseling business For Free on Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'piercing' ? (
        <title>Free Business Listing Website for Piercing - Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'nail-salons' ? (
        <title>Free Nail Salons Business Listing - Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'makeup-artists' ? (
        <title>Get More Leads by Listing Your Business on Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'cosmetics-&amp;-beauty-supply' ? (
        <title>Add Your Cosmetics & Beauty Supply Business - Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'used-car-dealers' ? (
        <title>List Your Used car dealers Store Near me - Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'towing' ? (
        <title>Small Physical Store List - Towing - Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'car-paint' ? (
        <title>Physical Store for Car Paint - Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'auto-security' ? (
        <title>Auto Security Physical Stores Near me - Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'auto-detailing' ? (
        <title>Auto Detailing Small Physical Store List - Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'sports-betting' ? (
        <title>Add Sports Betting store For free - Mon n Pop Hub</title>
      ) : null}
      {subcategoryname === 'opera-&amp;-ballet' ? (
        <title>List your Opera & Ballet Store With Mom n Pop Hub - Signup now!</title>
      ) : null}
      {subcategoryname === 'haunted-houses' ? (
        <title>Add a Haunted Houses Near You - Mom n Pop hub</title>
      ) : null}
      {subcategoryname === 'astrologers' ? (
        <title>Are you Astrologers? Add your store with Mom n Pop hub</title>
      ) : null}
      {subcategoryname === 'art-museums' ? (
        <title>Free Signup - List Your Art Museums Store With Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'scuba-diving' ? (
        <title>Submit your Scuba Diving with Us - Free Sign Up Today</title>
      ) : null}
      {subcategoryname === 'bungee-jumping' ? (
        <title>List Your Bungee Jumping Physical Store with Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'toys-&amp;-baby-products' ? (
        <title>Toys & Baby Products Online Store List - Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'clothing' ? (
        <title>Best Online Clothing Store List - Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'toys-&amp;-baby-products' ? (
        <title>Toys & Baby Products Online Store List - Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'stationary-&amp;-hobby-supplies' ? (
        <title>Online Store List for Stationary & Hobby Supplies - Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'furniture-&amp;-household-goods' ? (
        <title>Online Stores for Furniture & Household Goods - Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'diy-graden-&amp;-pets' ? (
        <title>DIY, Garden & Pets Websites List - Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'automotive-parts' ? (
        <title>Online Store List for Automotive Parts - Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'household-appliances' ? (
        <title>List of Household Appliances Online Websites - Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'cbd-&amp;-prodcts' ? (
        <title>Online CBD Products Store List - Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'books-movies-games' ? (
        <title>Online Store List for Books, Movies, Games - Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'shoes' ? (
        <title>List of Online Shoes Store - Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'electricians' ? (
        <title>Best Online Electronics Store List - Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'sports-&amp;-outdoor' ? (
        <title>Best Sports & Outdoor Websites - Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'other' ? (
        <title>Other Online Business Websites List - Mom n Pop Hub</title>
      ) : null}
      {subcategoryname === 'cannabis-clinics' ? (
        <title>Business Listing for Cannabis Clinics - Mom n Pop Hub</title>
      ) : null}

      <meta name='publisher' content='Mom n Pop Hub' />
      <meta property='twitter:title' content={categoryname + subcategoryname} />
      <meta property='twitter:description' content={subcategoryname} />
      <meta property='twitter:site' content='twitter id' />
      <meta property='og:title' content={categoryname + subcategoryname} />
      <meta property='og:description' content={subcategoryname} />
      <meta property='og:publisher' content='Mom n Pop Hub' />
      <meta property='og:locale' content='en_US' />
      <meta property='og:type' content='website' />
      <meta property='og:site_name' content='https://momnpophub.com/' />
    </Helmet>
  );

  // function handleClick(url, name, uid) {
  //     localStorage.setItem('id', uid);
  //     let makeurl = url.replaceAll(/\s/g,'');
  //     let listUrl = makeurl+name.replaceAll(/\s/g,'-');
  //     history.push(listUrl.toLowerCase());
  //    //history.push(url);
  //    //Note in use
  // }

  // if(businessList.length ===0){

  //    return (
  //      <>
  //         <div className="content">
  //           <div className="block">
  //           <div className="d-flex flex-column">
  //             <div className="d-flex flex-column"></div>
  //              <Button key="1" name="Business list not available!" link='###' />
  //           </div>
  //           </div>
  //           </div>
  //    </>
  //    )

  // }

  if (businessList.length > 0) {
    return (
      <>
        <Helmet>
          <title>{metadata.metatitle}</title>
          <meta name='description' content={metadata.metadesc} />
          <meta name='publisher' content='Mom n Pop Hub' />
          <meta property='twitter:title' content={categoryname + subcategoryname} />
          <meta property='twitter:description' content={subcategoryname} />
          <meta property='twitter:site' content='twitter id' />
          <meta property='og:title' content={categoryname + subcategoryname} />
          <meta property='og:description' content={subcategoryname} />
          <meta property='og:publisher' content='Mom n Pop Hub' />
          <meta property='og:locale' content='en_US' />
          <meta property='og:type' content='website' />
          <meta property='og:site_name' content={process.env.RAZZLE_RUNTIME_FRONTBASE_URL} />
        </Helmet>
        <StoreDescHeader
          meta={metadata}
          categoryname={categoryname}
          subcategoryname={subcategoryname}
        />
        <div className='content'>
          <div className='block'>
            <div className='d-flex flex-column'>
              <div className='d-flex flex-column'>
                {businessList.map((item, index) => {
                  let addr = item.baddress ? item.baddress.split(',') : '';
                  let tempcity = addr[1] ? addr[1].trim().replaceAll(/\s/g, '-') : '';
                  let temp_state = addr[2] ? addr[2].split(' ') : '';
                  let tempState = temp_state[1];

                  let arrayAddress = tempState + '/' + tempcity;

                  let businessType = '';
                  let shareUrl = '';
                  if (item.btype === 'offline') {
                    businessType = '/business-category/physical-store';
                    shareUrl =
                      businessType +
                      '/' +
                      item.category +
                      '/' +
                      item.subcategory +
                      '/' +
                      arrayAddress +
                      '/';
                  } else {
                    businessType = '/business-category/online-store';
                    shareUrl = businessType + '/' + item.category + '/' + item.subcategory + '/';
                  }
                  let makeurl = shareUrl.replaceAll(/\s/g, '');
                  let listUrl = makeurl + item.bname.replaceAll(/\s/g, '-');
                  let linktoItem = listUrl.toLowerCase();

                  return (
                    <React.Fragment key={index}>
                      {/* <a  onClick={() => handleClick(shareUrl, item.bname, item.userid)}> */}
                      <Link to={linktoItem} style={{ textDecoration: 'none' }}>
                        <div
                          className='d-flex flex-row align-items-center'
                          style={{
                            borderBottom: 'solid 1px',
                            borderBlockColor: '#a1a1a1',
                            borderWidth: '0.01em',
                          }}
                        >
                          <div>
                            {item.btype === 'offline' ? (
                              <img
                                src={PHYSICAL}
                                className='store_image'
                                width='140'
                                height='140'
                                alt='...'
                              />
                            ) : (
                              <img
                                src={ONLINE}
                                className='store_image'
                                width='140'
                                height='140'
                                alt='...'
                              />
                            )}
                          </div>
                          <div>
                            <h1 className='title'>{item.bname}</h1>
                            <div className='d-flex flex-column store_desc'>
                              {item.claimed === '0' && (
                                <div className='d-flex flex-row align-items-center exclamatory'>
                                  <img src={Exclamatory} width='12' height='12' />
                                  <p>Unclaimed</p>
                                </div>
                              )}
                              <div className='d-flex flex-row align-items-center location'>
                                <img src={Location} width='12' height='12' />
                                <p>{item.baddress}</p>
                              </div>
                              {/* {item.availableDeals > 0 && (
                        <div className="deal">
                          <p>
                            <span className="black_text">
                              {item.availableDeals}
                            </span>{" "}
                            deal available
                          </p>
                        </div>
                      )} */}
                            </div>
                          </div>
                        </div>
                        {/* </a> */}
                      </Link>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        {/* {headersMeta} */}
        <Helmet>
          <title>{metadata?.metatitle}</title>
          <meta name='description' content={metadata?.metadesc} />
          <meta name='publisher' content='Mom n Pop Hub' />
          <meta property='twitter:title' content={categoryname + subcategoryname} />
          <meta property='twitter:description' content={subcategoryname} />
          <meta property='twitter:site' content='twitter id' />
          <meta property='og:title' content={categoryname + subcategoryname} />
          <meta property='og:description' content={subcategoryname} />
          <meta property='og:publisher' content='Mom n Pop Hub' />
          <meta property='og:locale' content='en_US' />
          <meta property='og:type' content='website' />
          <meta property='og:site_name' content={process.env.RAZZLE_RUNTIME_FRONTBASE_URL} />
        </Helmet>
        <StoreDescHeader
          meta={metadata}
          categoryname={categoryname}
          subcategoryname={subcategoryname}
          type='No Listing'
        />
        <div className='content'>
          <div className='block'>
            <div className='d-flex flex-column'>
              <div className='d-flex flex-column'></div>
              <Button key='1' name='Business list not available!' link='###' />
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default StoreDescList;
