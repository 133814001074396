import React, { useEffect, useState } from 'react';

import categoryData from './categorydata';
import './category.css';

const Category = ({
  onCategoryClick,
  onSubcategoryClick,
  className,
  startCategory,
  startSubCategory,
}) => {

  const [activeCategory, setActiveCategory] = useState([]);
  const [activeSubcategory, setActiveSubcategory] = useState([]);
  const [doubleClick, setDoubleClick] = useState(false);

  useEffect(() => {
    const startCategoryValue =
      categoryData.find((item) => item.machine_name === startCategory) || categoryData[0];
    setActiveCategory(startCategoryValue);

    const startSubCategoryValue =
      startCategoryValue.subcategory.find((item) => item.machine_name === startSubCategory) || [];
    setActiveSubcategory(startSubCategoryValue);

    if(startSubCategoryValue) {
      setDoubleClick(!doubleClick);
    }
  }, []);

  const handleCategoryClick = (category) => {
    if (category.name !== activeCategory.name) {
      setActiveCategory(category);
      setActiveSubcategory([]);
      onCategoryClick(category);
      setDoubleClick(true);
    } else setDoubleClick(!doubleClick);
  };

  const handleSubcategoryClick = (category, subcategory) => {
    setActiveSubcategory(subcategory);
    onSubcategoryClick(category, subcategory);
  };

  return (
    <div className={className}>
      <ul className='sidebar-categories-list'>
        {categoryData.map((category) => (
          <li key={category.machine_name}>
            <span
              onClick={() => handleCategoryClick(category)}
              className={activeCategory.name === category.name ? 'sidebar-categories_active' : ''}
            >
              {category.name}
            </span>
            {activeCategory.name === category.name &&
              doubleClick &&
              category.subcategory.length > 0 && (
                <ul>
                  {category.subcategory.map((subcategory) => (
                    <li
                      key={subcategory.machine_name}
                      onClick={() => handleSubcategoryClick(category, subcategory)}
                      className={
                        activeSubcategory === subcategory ? 'sidebar-categories_active' : ''
                      }
                    >
                      {subcategory.name}
                    </li>
                  ))}
                </ul>
              )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Category;
