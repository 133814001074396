import 'firebase/analytics';

import firebase from 'firebase/app';
import ReactGA from 'react-ga4';

export const firebaseConfig = {
  apiKey: 'AIzaSyDqh-KbjRRhjiP0wK3CNDPbgR5hIx8bBHc',
  authDomain: 'momnpophub-291517.firebaseapp.com',
  databaseURL: 'https://momnpophub-291517.firebaseio.com',
  projectId: 'momnpophub-291517',
  storageBucket: 'momnpophub-291517.appspot.com',
  messagingSenderId: '653880721807',
  appId: '1:653880721807:web:454a40ba5b91fe25e7cbfa',
  measurementId: 'G-LNTFNXSS3X',
};
// console.log('navigator.cookieEnabled: ' + navigator.cookieEnabled);
let firebaseAnalytics;
firebase.initializeApp(firebaseConfig);
if (typeof window !== 'undefined') {
  firebaseAnalytics = firebase.analytics();
}

// ReactGA.initialize("G-LNTFNXSS3X")
export { firebaseAnalytics, ReactGA };
