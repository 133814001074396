import './articleDetail.css';
import '../components/Search/search.css';
import '../components/Category/category.css';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import categoryData from '../components/Category/categorydata';

import { fetchArticles, fetchArticlesForSubCategory, fetchArticlesArticleId, fetchArticlesUniqueAddress } from '../../../api/blog';

import ArticleDetailsClient from './client';
import usePageData from '../../../../common/hooks/usePageData';

const ArticleDetails = (props) => {
  const pageData = usePageData(props);

  const { pathname } = useLocation();
  const { uniqueAddress } = useParams();
  const location = useLocation();

  const [article, setArticle] = useState(pageData?.data ? pageData?.data[0] : []);
  const [lastArticles, setLastArticles] = useState(null);
  const [lastArticlesCategory, setLastArticlesCategory] = useState(null);
  const [nextArticle, setNextArticle] = useState({});
  const [prevArticle, setPrevArticle] = useState({});
  const [filterVisible, setFilterVisible] = useState(true);
  const [userEditor, setUserEditor] = useState(false);
  const [params, setParams] = useState(false);

  const fetchData = async () => {
    // if (params) {
      const articleFetch = await fetchArticlesUniqueAddress(uniqueAddress, filterVisible);
      const categoryName = categoryData.find(
        (category) => category.machine_name === articleFetch[0]?.category,
      );
      const subcategoryName = categoryName?.subcategory.find(
        (subcategory) => subcategory.machine_name === articleFetch[0].subCategory,
      );
      articleFetch[0] = { ...articleFetch[0], categoryName: categoryName?.name };
      articleFetch[0] = { ...articleFetch[0], subCategoryName: subcategoryName?.name };
      setArticle(articleFetch[0]);
    // }
  };

  const fetchLastArticles = async () => {
    const lastArticleFetch = await fetchArticles({ page: 1, count: 4, filterVisible });
    setLastArticles(lastArticleFetch);
  };

  const fetchArticleDetails = async () => {
    if (article) {
      const lastArticleCategoryFetch = await fetchArticlesForSubCategory({
        page: 1,
        category: article.category,
        subCategory: article.subCategory,
        count: 2,
      });

      setLastArticlesCategory(lastArticleCategoryFetch);

      const nextArticleVal = await fetchArticlesArticleId(article.articleId + 1);
      if (nextArticleVal) setNextArticle({ ...nextArticleVal[0] });

      const prevArticleVal = await fetchArticlesArticleId(article.articleId - 1);
      if (prevArticleVal) setPrevArticle({ ...prevArticleVal[0] });
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [pathname]);

  useEffect(() => {
    setParams(false);
    const searchParams = new URLSearchParams(location);
    const userEditorParam = searchParams.get('userEditorParam');
    const filterVisibleParams = searchParams.get('filterVisibleParams');

    if (userEditorParam !== null && filterVisibleParams !== null) {
      setUserEditor(userEditorParam === 'true');
      setFilterVisible(filterVisibleParams === 'true');
      setParams(true);
    } else {
      setParams(true);
    }
  }, [location]);

  useEffect(() => {
    fetchData();
  }, [params, uniqueAddress, filterVisible]);

  useEffect(() => {
    fetchLastArticles();
  }, [filterVisible]);

  useEffect(() => {
    fetchArticleDetails();
  }, [article]);

  return (
    <>
      <ArticleDetailsClient
        article={article}
        lastArticles={lastArticles}
        lastArticlesCategory={lastArticlesCategory}
        nextArticle={nextArticle}
        prevArticle={prevArticle}
        filterVisible={filterVisible}
        userEditor={userEditor}
      />
    </>
  );
};

export default ArticleDetails;