import './store_header.css';

import React from 'react';

//import BusinessCategories from "../../../data/business_categories";

const StoreHeader = (props) => {
  console.log('Props ---> ' + props.type);
  if (props.type === 'physical-store') {
    return <Physical type={props.type} />;
  }
  return <Online type={props.type} />;
};

const Physical = (props) => {
  return (
    <div className='container'>
      <div className='block'>
        <div className='store-header_header'>
          <h1 className='store-header_h_text'>{props.type} Business Categories</h1>
        </div>
        <div className='store-header_description'>
          <p className='store-header_desc_text'>
            We have <span className='store-header_desc_highlighted'>14 categories</span> of physical
            store businesses we list on Mom n Pop Hub. Please click on any of the categories to see
            the sub-categories
          </p>
        </div>
      </div>
    </div>
  );
};

const Online = (props) => {
  return (
    <div className='container'>
      <div className='block'>
        <div className='store-header_header'>
          <h1 className='store-header_h_text'>{props.type} Business Categories</h1>
        </div>
        <div className='store-header_description'>
          <p className='store-header_desc_text'>
            We have <span className='store-header_desc_highlighted'>5 categories</span> of online
            store businesses we list on Mom n Pop Hub. Please click on any of the categories to see
            the sub-categories
          </p>
        </div>
      </div>
    </div>
  );
};

export default StoreHeader;
