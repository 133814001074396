import './about_signup.css';

import React, { useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useWebPSupportCheck } from 'react-use-webp-support-check';

import AboutSearch from './about_search';

const AboutSignup = (props) => {
  const supportsWebP = useWebPSupportCheck();
  const sign_up_btn = useRef();
  const history = useHistory();

  useEffect(() => {
    sign_up_btn.current.addEventListener('contextmenu', (event) => {
      event.preventDefault();
    });
  }, []);

  // const toFreeSignUp = () => {
  //     window.scrollTo({ top: 0, behavior: "smooth" });
  //     if(props.where != 'Bus')
  //         history.push("/free-business-invitation");
  // }

  return (
    <>
      <div className='part_block'>
        <div className='row custom_container'>
          <div className='about_signup_left'>
            <div className='about_signup_title'>
              <h1>The only platform exclusive to small business</h1>
            </div>
            <div className='about_signup_text'>
              Join <strong> 100's of U.S. based small businesses </strong> who have{' '}
              <strong> joined Mom n Pop Hub </strong> and are benefiting with increased visibility
              via our mobile app.
            </div>
            {/* <button onClick={toFreeSignUp} className='about_signup_btn'> Free Sign Up</button> */}
            <Link to='/free-business-invitation'>
              <button ref={sign_up_btn} className='about_signup_btn'>
                {' '}
                Free Business Signup
              </button>
            </Link>
            <AboutSearch
              setSelectedProfileId={props.setSelectedProfileId}
              setSelectedDealId={props.setSelectedDealId}
              history={history}
            />

            <div className='about_signup_bottom download_icons_buttons'>
              <a href='https://play.google.com/store/apps/details?id=com.momnpophub'>
                <img src='./playstore.png' className='googlePlayStore' />
              </a>
              <a href='https://apps.apple.com/us/app/mom-n-pop-hub/id1494101666'>
                <img src='./applestore.png' className='applePlayStore ml-4' />
              </a>
            </div>

            {/* <div className='app_platform'>
                <Link to='https://play.google.com/store/apps/details?id=com.momnpophub'>
                  <img src='./playstore.png' />
                </Link>
                <Link to='https://apps.apple.com/us/app/mom-n-pop-hub/id1494101666'>
                  <img src='./applestore.png' />
                </Link>
              </div> */}
          </div>
          <div className='about_signup_right'>
            <div
              className={supportsWebP ? 'about_signup_img webp' : 'about_signup_img no-webp'}
            ></div>
          </div>
          <div className='fixedButton'>
            <div className='btnTxt'>
              <a href='https://onelink.to/7f52xq' target='_blank' style={{ color: 'white' }}>
                {' '}
                Mobile App
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutSignup;
