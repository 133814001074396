import './Button.css';

import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

const Button = ({ link, name, large = false, type }) => {
  const { path, url } = useRouteMatch();

  return (
    <>
      {link === '###' ? (
        <div className={large ? 'card-body go_to_shop_btn large_btn' : 'card-body go_to_shop_btn'}>
          <div className='shop_btn'>
            {name}
            <div className='quarter-circle-bottom-right'></div>
          </div>
        </div>
      ) : (
        <Link to={url + '/' + link} style={{ textDecoration: 'none' }}>
          <div
            className={large ? 'card-body go_to_shop_btn large_btn' : 'card-body go_to_shop_btn'}
          >
            <div className='shop_btn'>
              {name}
              <div className='quarter-circle-bottom-right'></div>
            </div>
          </div>
        </Link>
      )}
    </>
  );
};

export default Button;
