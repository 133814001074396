import './shareBussiness.css';

import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import ShareBussinessApp from './share-bussiness-app/shareBussinessApp';
import ShareBussinessDeals from './share-bussiness-deals/ShareBussinessDeals';
import ShareBussinessProfile from './share-bussiness-profile/ShareBussinessProfile';
import usePageData from '../../../common/hooks/usePageData';
import { callApi } from '../../api/API';

function shareBusiness(props) {
  const pageData = usePageData(props);
  const [clientPageData, setClientPageData] = useState(null);

  const [apiStatus, setApiStatus] = useState({
    loading: false,
    success: true,
    error: false,
  });

  const profileData = useMemo(() => {
    const finalPageData = clientPageData || pageData || {};

    if (finalPageData?.profile?.[0]) {
      const profile = finalPageData?.profile?.[0];

      if (profile.baddress) {
        let tempAdd = profile.baddress.split(',');
        profile.addressStreet = tempAdd[0];
        profile.addressLocal = tempAdd[1];
        profile.addressCountry = tempAdd[3];

        let temp_state = tempAdd[2] ? tempAdd[2].split(' ') : '';

        profile.addressPostal = temp_state[2];
        profile.addressRegion = temp_state[1];
      }

      if (profile?.description?.length < 140) {
        let tempdes = profile.bname + ', ' + profile.addressLocal + profile.addressCountry + " - " + profile.shortdescription;

        // if (profile.baddress) {
        //   let tempAdd = profile.baddress.split(',');
        //   console.log(tempAdd,"tempAdd")

        //   if (tempAdd[1] !== undefined) {
        //     tempdes += tempAdd[1] + ', ';
        //   }

        //   if (tempAdd[3] !== undefined) {
        //     tempdes += tempAdd[3] + '- ';
        //   }
        // }
        // tempdes += profile.description;

        profile.description = tempdes;
      }

      if (profile?.shortdescription && profile?.shortdescription?.length >= 160) {
        profile.metaShortDesc = profile.description.slice(0, 160) + '...';
      } else if (profile.shortdescription !== undefined) {
        profile.metaShortDesc = profile.shortdescription;
      }

      if (profile.lat) {
        profile.latitude = profile.lat;
        profile.longitude = profile.long;

        profile.mapUrl = `http://maps.google.com/maps?q=${profile.lat},${profile.long}`;
      }

      let tempMetatitle = profile.bname;

      if (profile.baddress) {
        let tempAdd = profile.baddress.split(',');

        if (tempAdd[1] !== undefined) {
          tempMetatitle += ` | ${tempAdd[1]}`;
        }

        if (tempAdd[3] !== undefined) {
          tempMetatitle += ` | ${tempAdd[3]}`;
        }
      }

      tempMetatitle += ' - Mom n Pop Hub';

      profile.metaTitle = tempMetatitle;
      profile.url = 'https://' + profile.website;

      let sharelink = '';
      if (profile.btype === 'offline') {
        sharelink = 'business-category/physical-store/';
      } else {
        sharelink = 'business-category/online-store/';
      }
      if (profile.category) {
        sharelink += profile.category + '/';
      }
      if (profile.subcategory) {
        sharelink += profile.subcategory + '/';
      }

      profile.shareLink = sharelink;

      finalPageData.profile[0] = profile;
    }

    if (finalPageData?.deals) {
      const deals = finalPageData?.deals;
      let dealsData = deals.map((deal) => {
        if (deal._id && deal.name) {
          let dealName = deal.name.split(' ');
          let newName = '';
          for (let i = 0; i < dealName.length; i++) {
            newName = newName + '-' + dealName[i];
          }
          newName = newName.replace('%', 'percent');
          deal.url = '/businessinfo/deal/' + deal._id + newName;
        }
        return deal;
      });

      finalPageData.deals = dealsData;
    }

    if (finalPageData) return finalPageData;

    return {
      deals: [],
      profile: [],
    };
  }, [pageData, clientPageData]);

  useEffect(() => {
    (async () => {
      if (pageData !== null && !pageData?.profile) {
        setApiStatus({ loading: true, success: false, error: false });

        const [ bId ] = title?.split('-');

        let response = null;
        if (window.location.pathname.startsWith('/businessinfo')) {
          response = await callApi(`/businessinfo/business-profile/${bId}`, 'GET');
        } else {
          response = await callApi(`/businessinfo/profilejsonBname/${title}`, 'GET');
        }

        if (response?.profile?.[0]) {
          setClientPageData(response);
          setApiStatus({ loading: false, success: true, error: false });
        } else {
          setApiStatus({ loading: false, success: false, error: true });
        }
      } else {
        setApiStatus({ loading: false, success: true, error: false });
      }
    })();
  }, [pageData]);

  let titleString;
  let title = '';
  try {
    let pathcheck = location.pathname.split('/');
    titleString = pathcheck[pathcheck.length - 1];

    title = titleString.replaceAll(/-/g, ' ').replaceAll(/_/g, '-');
  } catch (e) {}

  const { bussinesProfile, bussinesDeals } = useMemo(
    () => ({
      bussinesProfile: profileData?.profile?.[0] || null,
      bussinesDeals: profileData?.deals || [],
    }),
    [profileData],
  );

  return (
    <>
      <Helmet>
        <title>{bussinesProfile?.metaTitle}</title>
        <meta name='description' content={bussinesProfile?.metaShortDesc || ''}></meta>

        <meta name='publisher' content='Mom n Pop Hub'></meta>
        <meta property='twitter:title' content={bussinesProfile?.metaTitle} />
        <meta property='twitter:description' content={bussinesProfile?.metaShortDesc || ''} />

        <meta property='og:title' content={bussinesProfile?.metaTitle} />
        <meta property='og:description' content={bussinesProfile?.description || ''} />
        <meta property='og:publisher' content='Mom n Pop Hub' />
        <meta property='og:locale' content='en_US' />
        <meta property='og:type' content='website' />
      </Helmet>

      <div className='share_bussiness_container'>
        <div className='row'>
          <div className='col-xl-12 mb-4'>
            <div className='bus_top'>
              <ShareBussinessApp />
            </div>
          </div>
          <div className='col-xl-12  mb-4'>
            <div className='bus_mid'>
              <ShareBussinessProfile
                key={bussinesProfile?._id}
                profile={bussinesProfile}
                apiStatus={apiStatus}
              />
            </div>
          </div>
          <div className='col-xl-12  mb-4'>
            <div className='bus_mid'>
              <ShareBussinessDeals deals={bussinesDeals} apiStatus={apiStatus} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default shareBusiness;
