import React from 'react';

const PercentageIcon = (props) => (
  <svg width={19} height={19} viewBox='0 0 19 19' fill='none' {...props}>
    <circle cx={9.42857} cy={9.42857} r={9.42857} fill='#D0D0D0' />
    <path
      d='M6.94113 8.51212C5.64156 8.51212 4.58398 7.45455 4.58398 6.15498C4.58398 4.85542 5.64156 3.79785 6.94113 3.79785C8.2407 3.79785 9.29827 4.85542 9.29827 6.15498C9.29827 7.45455 8.2407 8.51212 6.94113 8.51212ZM6.94113 5.36927C6.50741 5.36927 6.15541 5.72127 6.15541 6.15498C6.15541 6.5887 6.50741 6.9407 6.94113 6.9407C7.37484 6.9407 7.72684 6.5887 7.72684 6.15498C7.72684 5.72127 7.37484 5.36927 6.94113 5.36927Z'
      fill='#FAFAFA'
    />
    <path
      d='M11.655 14.7978C10.3554 14.7978 9.29785 13.7402 9.29785 12.4406C9.29785 11.1411 10.3554  10.0835 11.655 10.0835C12.9546 10.0835 14.0121 11.1411 14.0121 12.4406C14.0121 13.7402 12.9546  14.7978 11.655 14.7978ZM11.655 11.6549C11.2229 11.6549 10.8693 12.0085 10.8693 12.4406C10.8693 12.8728 11.2229 13.2263 11.655 13.2263C12.0871 13.2263 12.4407 12.8728 12.4407 12.4406C12.4407  12.0085 12.0871 11.6549 11.655 11.6549Z'
      fill='#FAFAFA'
    />
    <path
      d='M5.36963 14.7979C5.19206 14.7979 5.01448 14.7382 4.86677 14.6156C4.53363 14.3375 4.48806  13.8425 4.7662 13.5094L12.6233 4.08083C12.9031 3.74611 13.3981 3.70526 13.7296 3.98026C14.0628 4.2584 14.1068 4.7534 13.8286 5.08811L5.97148 14.5166C5.81748 14.7005 5.59434 14.7979 5.36963 14.7979Z'
      fill='#FAFAFA'
    />
  </svg>
);

export default PercentageIcon;
