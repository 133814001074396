export const _empty = (obj) => Object.keys(obj).length === 0 && obj.constructor === Object;

export const ImgWithFallback = ({ src, fallback, type = 'image/webp', ...delegated }) => {
  return (
    <picture>
      <source srcSet={src} type={type} />
      <img src={fallback} {...delegated} />
    </picture>
  );
};

export const fixUrl = (url) => {
  if (!url) return null;

  if (!/^(https?:)?\/\//i.test(url)) {
    url = `https://${url}`;
  }

  return url;
}
