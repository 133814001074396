import './Checkbox-container.css';

import React from 'react';

const CheckboxSignupContainer = ({ labetText, checked = false, change }) => {
  return (
    <label className='checkbox-label d-flex'>
      <div className='position-relative'>
        <input
          type='checkbox'
          className='position-absolute'
          checked={checked}
          onChange={() => change()}
        />
        <span className='d-flex align-items-center justify-content-center custom-checkbox'></span>
      </div>{' '}
      {labetText}{' '}
    </label>
  );
};

export default CheckboxSignupContainer;
