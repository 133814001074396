import React, { useContext, useEffect, useState, useCallback } from 'react';

import axios from 'axios';
import Loader from 'react-loader-spinner';
import { useForm } from 'react-hook-form';
import UserContext from '../../../contexts/userContext';
import DefaultImage from '../../../assets/images/default.png';
import LocationAutoComplete from '../../../components/location-auto-complete';
import { Button, Dropdown, Form } from 'react-bootstrap';
import { callApi } from '../../../api/API';
import { _empty } from '../../../utils/utils';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Cookies from 'js-cookie';

const BusinessProfileView = ({ subscriptionPurchased }) => {

  const [profile, setProfile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [ btnLoading,setBtnLoading ] = useState(false);;

  const [urlRedirect, setUrlRedirect] = useState('');
  const { userData, setUserData } = useContext(UserContext);

  const [loadProfleApiCall, setLoadProfileApiCall] = useState({
    didCall: false,
    success: false,
    error: false,
  });

  const history = useHistory();

  const { register, handleSubmit, errors, ...form } = useForm();

  const loadProfileData = useCallback(async () => {
    setLoadProfileApiCall({ didCall: true, success: false, error: false });
   
    const users = await callApi(`/users/${userData.id}`, 'GET', null, 'Bearer ' + userData.token);
    
    if (!_empty(users) && users?.length) {
    
      setProfile({ user: users[0], bussiness: users[1][0] });

      const url = 'https://' + users[1][0].website;

      setUrlRedirect(url);

      setLoadProfileApiCall({ didCall: false, success: true, error: false });
    } else {
      setLoadProfileApiCall({ didCall: false, success: false, error: true });
    }
  }, [userData]);

  useEffect(() => {
    loadProfileData();
  }, [loadProfileData]);




  const shareProfilePage = () => {


    /*
    var bname = profile.bussiness.bname.split(" ");
    var newName = "";
    for (var i = 0; i < bname.length; i++) {
      newName = newName + "-" + bname[i];
    }
    newName = newName.replace("%", "percent");
    const ShareUrl =
      "/businessinfo/profile/" + profile.bussiness.userid + newName;
    history.push(ShareUrl);
    ****/

    var newName = '';

    newName = profile.bussiness.bname.replaceAll(/\s/g, '-');

    let address = '';
    let city = '';
    let state = '';
    let shareUrl = '';

    if (profile.bussiness.baddress) 
    {
      let tempAdd = profile.bussiness.baddress.split(',');
      city = tempAdd[1].trim().replaceAll(/\s/g, '-');
      
      let temp_state = tempAdd[2] ? tempAdd[2].split(' ') : '';
      state = temp_state[1];
    }

    if (state.length > 0 && city.length > 0) {
      address = state + '/' + city + '/';
    }

    if (address !== 'undefined') {

      shareUrl = '/' + newName;

      if (profile.bussiness.category) {
        shareUrl = '/' + profile.bussiness.category + '/' + address + newName;
      }

      if (profile.bussiness.subcategory) {
        shareUrl =
          '/' +
          profile.bussiness.category +
          '/' +
          profile.bussiness.subcategory +
          '/' +
          address +
          newName;
      } else {
        shareUrl = '/' + address + newName;
      }

    } else {

      shareUrl = '/' + newName;

      if (profile.bussiness.category) {
        shareUrl = '/' + profile.bussiness.category + '/' + newName;
      } else if (profile.bussiness.subcategory) {
        shareUrl =
          '/' + profile.bussiness.category + '/' + profile.bussiness.subcategory + '/' + newName;
      } else {
        shareUrl = '/' + address + newName;
      }
    }

    let store = '';
    if (profile.bussiness.btype === 'offline') {
      store = '/business-category/physical-store';
    } else {
      store = '/business-category/online-store';
    }

    let shareUrlNoWhiteSpaces = shareUrl.replaceAll(/\s/g, '-');
    //const ShareUrl =
    //"/businessinfo/profile/" + profile.bussiness.userid + newName;

    Cookies.remove('authtoken');
    Cookies.remove('uname');

    history.push(store + shareUrlNoWhiteSpaces.toLowerCase());

    setUserData({ isLogin: false });

    window.location.reload();

    //history.push(location.origin+store+shareUrlNoWhiteSpaces.toLowerCase());
  };

  const purchaseSubscription = () => {
    setBtnLoading(true)
    const token = Cookies.get("authtoken");

    let apiRoute = "/users/purchase-subscription";

    if (subscriptionPurchased) {
      apiRoute = "/stripe/create-portal-session";
    }

    callApi(apiRoute, "POST", undefined, `Bearer ${token}`)
      .then((data) => {
        console.log(data.url);
        if (data?.url) window.location.href = data.url;
      })
      .catch((err) => {
        console.log(err?.response?.data);
      }).finally(()=>setBtnLoading(false))
  };

  return (
    <>
      {loadProfleApiCall.didCall && (
        <div className="text-center">
          <Loader type="Bars" color="#FF992E" />
          <p>Loading profile information...</p>
        </div>
      )}
      
      {loadProfleApiCall.success && (
        <div className="business-profile-page col-12 pb-2">
          {profile && (
            <>
              <Helmet>
                <title>Manage Your Business Profile & Deals information - Mom n Pop Hub</title>
                <meta
                  name="description"
                  content="Manage Your Business Profile & Deals information - Mom n Pop Hub"
                ></meta>

                <meta name="publisher" content="Mom n Pop Hub"></meta>
                <link
                  rel="canonical"
                  href={
                    location.origin + `/users/${userData.id}` +
                    `-` +
                    profile.bussiness.bname.replace(/ /g, '-').replace(/[^\w-]+/g, '')
                  }
                />
                <meta
                  property="twitter:title"
                  content="Manage Your Business Profile & Deals information - Mom n Pop Hub"
                />
                <meta
                  property="twitter:description"
                  content="Manage Your Business Profile & Deals information - Mom n Pop Hub"
                />
                <meta
                  property="twitter:image"
                  content={
                    typeof profile.bussiness.logo !== 'undefined'
                      ? 'https://momnpophub.com:3000/users/getlogo/' + profile.bussiness.logo
                      : DefaultImage
                  }
                />
                <meta
                  property="twitter:card"
                  content="Manage Your Business Profile & Deals information - Mom n Pop Hub"
                />
                <meta property="twitter:site" content="twitter id" />

                <meta
                  property="og:title"
                  content="Manage Your Business Profile & Deals information - Mom n Pop Hub"
                />
                <meta
                  property="og:description"
                  content="Manage Your Business Profile & Deals information - Mom n Pop Hub"
                />
                <meta property="og:publisher" content="Mom n Pop Hub" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta
                  property="og:image"
                  content={
                    typeof profile.bussiness.logo !== 'undefined'
                      ? 'https://momnpophub.com:3000/users/getlogo/' + profile.bussiness.logo
                      : DefaultImage
                  }
                />
                <meta property="og:site_name" content="https://momnpophub.com/" />
                <meta property="og:url" content="/bussinesssdfsfsdfds" />
                <meta property="og:url" content={location.href} />
              </Helmet>

              <div className="d-flex flex-wrap justify-content-between align-items-start border-bottom py-3 mb-2">
                <div className="d-flex">
                  <a
                    href=""
                    className="business-profile-image rounded-circle d-block overflow-hidden shadow"
                  >
                    <img
                      src={
                        typeof profile.bussiness.logo !== 'undefined'
                          ? 'https://momnpophub.com:3000/users/getlogo/' + profile.bussiness.logo
                          : DefaultImage
                      }
                      alt="Default Image Mom n Pop Hub"
                      className="w-100 h-100 img-fluid"
                    />
                  </a>
                  <div className="d-flex flex-column">
                    <a href="" className="business-profile-name text-decoration-none">
                      {profile.bussiness.bname}
                    </a>
                    <a href="" className="business-profile-url text-decoration-none">
                      {profile.user.email}
                    </a>
                  </div>
                </div>
                x
              </div>
              <div className="d-flex justify-content-between align-items-start">
                <p className="d-flex">
                  <i className="icon-address-card business-profile-icon" />{' '}
                  {profile.bussiness.description}
                </p>
                <a
                  onClick={shareProfilePage}
                  // onClick={() => history.push("/share-business")}
                  type="buttn"
                  className="btn bg-transparent border-0 shadow-none p-0"
                >
                  <i className="icon-share-square" />
                </a>
              </div>
              <div className="d-flex flex-wrap">
                <div className="d-flex col px-0">
                  <i className="icon-phone business-profile-icon" />
                  <a
                    href={`tel:${profile.bussiness.phonenumber}`}
                    className="text-decoration-none tel"
                  >
                    {profile.bussiness.phonenumber}
                  </a>
                </div>
                <div className="d-flex col">
                  <i className="icon-user business-profile-icon" />
                  <p>{profile.bussiness.fname}</p>
                </div>
                <div className="col-sm-6 px-0">
                  {profile.bussiness.baddress && (
                    <a href="" className="text-decoration-none d-flex address">
                      <i className="icon-map-marker-alt business-profile-icon" />
                      <address>{profile.bussiness.baddress}</address>
                    </a>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-between flex-wrap align-items-baseline">
                <div className="d-flex col-sm-6">
                  <div className="row">
                    <i className="icon-globe  business-profile-icon" />
                    <a href={urlRedirect} className="orange-link">
                      {profile.bussiness.website}
                    </a>
                  </div>
                </div>
                {/* Removed the Location option */}
                {/* <Dropdown className="dropdown-container col-sm-6 px-5">
                  {userData && userData.btype === "offline" && (
                    <div className="row">
                      <Dropdown.Toggle
                        onClick={() => setShowModal(!showModal)}
                        id="dropdown-basic"
                        className="bg-transparent border-0 p-0 shadow-none"
                      >
                        <span className="orange-link">Add Location</span>
                      </Dropdown.Toggle>
                    </div>
                  )}
                  {showModal && (
                    <Form>
                      <Form.Group>
                        <LocationAutoComplete
                          name="newLocation"
                          form={form}
                          errors={errors}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Button variant="success">Save</Button>
                      </Form.Group>
                    </Form>
                  )}
                </Dropdown> */}
              </div>
            </>
          )}
          {/* {subscriptionPurchased &&
            typeof subscriptionPurchased !== "undefined" && ( */}
              <div>
              {!btnLoading?<Button onClick={purchaseSubscription}>
                  {subscriptionPurchased
                    ? "Manage Membership"
                    : "Purchase Membership"}
                </Button>:
          <Loader type="Bars" color="#FF992E"  height={32}/>}
                

              </div>
            {/* )} */}
        </div>
      )}
      {loadProfleApiCall.error && (
        <div className="text-center" style={{ padding: '5%' }}>
          <p style={{ color: 'red' }}>Problem loading profile</p>
          <Button onClick={() => loadProfileData()}>Try again</Button>
        </div>
      )}
    </>
  );
};

export default BusinessProfileView;
