const data = {
  category: [
    {
      name: 'Shopify Store',
      machine_name: 'shopify-store',
      subcategory: [
        { name: 'Automotive Parts', machine_name: 'automotive-parts' },
        { name: 'CBD & Products', machine_name: 'cbd-&-prodcts' },
        { name: 'Clothing', machine_name: 'clothing' },
        { name: 'Shoes', machine_name: 'shoes' },
        { name: 'Electricians', machine_name: 'electricians' },
        { name: 'Books, Movies, Games', machine_name: 'books-movies-games' },
        {
          name: 'Cosmetics & Body Care',
          machine_name: 'cosmetics-&-body-care',
        },
        { name: 'Bags & Accessories', machine_name: 'bags-&-accessories' },
        { name: 'Foods & drinks', machine_name: 'foods-&-drinks' },
        { name: 'Household appliances', machine_name: 'household-appliances' },
        {
          name: 'Furniture & Household goods',
          machine_name: 'furniture-&-household-goods',
        },
        { name: 'Sports & Outdoor', machine_name: 'sports-&-outdoor' },
        { name: 'Toys & Baby Products', machine_name: 'toys-&-baby-products' },
        {
          name: 'Stationary & Hobby Supplies',
          machine_name: 'stationary-&-hobby-supplies',
        },
        { name: 'DIY, Garden & Pets', machine_name: 'diy-graden-&-pets' },
        { name: 'Other', machine_name: 'other' },
      ],
    },
    {
      name: 'Woocommerce',
      machine_name: 'woocommerce',
      subcategory: [
        { name: 'Automotive Parts', machine_name: 'automotive-parts' },
        { name: 'CBD & Products', machine_name: 'cbd-&-prodcts' },
        { name: 'Clothing', machine_name: 'clothing' },
        { name: 'Shoes', machine_name: 'shoes' },
        { name: 'Electricians', machine_name: 'electricians' },
        { name: 'Books, Movies, Games', machine_name: 'books-movies-games' },
        {
          name: 'Cosmetics & Body Care',
          machine_name: 'cosmetics-&-body-care',
        },
        { name: 'Bags & Accessories', machine_name: 'bags-&-accessories' },
        { name: 'Foods & drinks', machine_name: 'foods-&-drinks' },
        { name: 'Household appliances', machine_name: 'household-appliances' },
        {
          name: 'Furniture & Household goods',
          machine_name: 'furniture-&-household-goods',
        },
        { name: 'Sports & Outdoor', machine_name: 'sports-&-outdoor' },
        { name: 'Toys & Baby Products', machine_name: 'toys-&-baby-products' },
        {
          name: 'Stationary & Hobby Supplies',
          machine_name: 'stationary-&-hobby-supplies',
        },
        { name: 'DIY, Garden & Pets', machine_name: 'diy-graden-&-pets' },
        { name: 'Other', machine_name: 'other' },
      ],
    },

    {
      name: 'Wix',
      machine_name: 'wix',
      subcategory: [
        { name: 'Automotive Parts', machine_name: 'automotive-parts' },
        { name: 'CBD & Products', machine_name: 'cbd-&-prodcts' },
        { name: 'Clothing', machine_name: 'clothing' },
        { name: 'Shoes', machine_name: 'shoes' },
        { name: 'Electricians', machine_name: 'electricians' },
        { name: 'Books, Movies, Games', machine_name: 'books-movies-games' },
        {
          name: 'Cosmetics & Body Care',
          machine_name: 'cosmetics-&-body-care',
        },
        { name: 'Bags & Accessories', machine_name: 'bags-&-accessories' },
        { name: 'Foods & drinks', machine_name: 'foods-&-drinks' },
        { name: 'Household appliances', machine_name: 'household-appliances' },
        {
          name: 'Furniture & Household goods',
          machine_name: 'furniture-&-household-goods',
        },
        { name: 'Sports & Outdoor', machine_name: 'sports-&-outdoor' },
        { name: 'Toys & Baby Products', machine_name: 'toys-&-baby-products' },
        {
          name: 'Stationary & Hobby Supplies',
          machine_name: 'stationary-&-hobby-supplies',
        },
        { name: 'DIY, Garden & Pets', machine_name: 'diy-graden-&-pets' },
        { name: 'Other', machine_name: 'other' },
      ],
    },
    {
      name: 'Bigcommerce',
      machine_name: 'bigcommerce',
      subcategory: [
        { name: 'Automotive Parts', machine_name: 'automotive-parts' },
        { name: 'CBD & Products', machine_name: 'cbd-&-prodcts' },
        { name: 'Clothing', machine_name: 'clothing' },
        { name: 'Shoes', machine_name: 'shoes' },
        { name: 'Electricians', machine_name: 'electricians' },
        { name: 'Books, Movies, Games', machine_name: 'books-movies-games' },
        {
          name: 'Cosmetics & Body Care',
          machine_name: 'cosmetics-&-body-care',
        },
        { name: 'Bags & Accessories', machine_name: 'bags-&-accessories' },
        { name: 'Foods & drinks', machine_name: 'foods-&-drinks' },
        { name: 'Household appliances', machine_name: 'household-appliances' },
        {
          name: 'Furniture & Household goods',
          machine_name: 'furniture-&-household-goods',
        },
        { name: 'Sports & Outdoor', machine_name: 'sports-&-outdoor' },
        { name: 'Toys & Baby Products', machine_name: 'toys-&-baby-products' },
        {
          name: 'Stationary & Hobby Supplies',
          machine_name: 'stationary-&-hobby-supplies',
        },
        { name: 'DIY, Garden & Pets', machine_name: 'diy-graden-&-pets' },
        { name: 'Other', machine_name: 'other' },
      ],
    },
    {
      name: 'Independent website',
      machine_name: 'independent-website',
      subcategory: [
        { name: 'Automotive Parts', machine_name: 'automotive-parts' },
        { name: 'CBD & Products', machine_name: 'cbd-&-prodcts' },
        { name: 'Clothing', machine_name: 'clothing' },
        { name: 'Shoes', machine_name: 'shoes' },
        { name: 'Electricians', machine_name: 'electricians' },
        { name: 'Books, Movies, Games', machine_name: 'books-movies-games' },
        {
          name: 'Cosmetics & Body Care',
          machine_name: 'cosmetics-&-body-care',
        },
        { name: 'Bags & Accessories', machine_name: 'bags-&-accessories' },
        { name: 'Foods & drinks', machine_name: 'foods-&-drinks' },
        { name: 'Household appliances', machine_name: 'household-appliances' },
        {
          name: 'Furniture & Household goods',
          machine_name: 'furniture-&-household-goods',
        },
        { name: 'Sports & Outdoor', machine_name: 'sports-&-outdoor' },
        { name: 'Toys & Baby Products', machine_name: 'toys-&-baby-products' },
        {
          name: 'Stationary & Hobby Supplies',
          machine_name: 'stationary-&-hobby-supplies',
        },
        { name: 'DIY, Garden & Pets', machine_name: 'diy-graden-&-pets' },
        { name: 'Other', machine_name: 'other' },
      ],
    },
  ],
};

export default data;
