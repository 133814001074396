import './bus_signup.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import Cookies from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import UserContext from '../../../contexts/userContext';
import categorydata from '../components/categorydata';
import onlinecategorydata from '../components/onlinecategorydata';
import SignUpForm from './SignUpForm';

const stripePromise = loadStripe('pk_test_Tl3b26zVscyP1BCyrNsNpffn00i6Hi70ir');

const BusSignup = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { useData, setUserData } = useContext(UserContext);
  const { handleSubmit, register, errors, watch, ...form } = useForm();
  const [check, onCheck] = useState(false);
  const history = useHistory();
  const [robot, setRobot] = useState({ isRobot: true, showRobotErr: false });
  const [coordinates, setCoordinates] = useState({});

  const [showLoad, setShowLoad] = useState({ showLoad: false });
  const [showError, setShowError] = useState({ message: null });
  const [showBaddressError, setBaddressError] = useState(false);
  const [description, setDescription] = useState('');
  const [data, setData] = useState(categorydata);
  const [count, setCount] = useState(0);

  const handleSubmitForm = (payload) => {
    const { confirmPassword, ...rest } = payload;
    rest['type'] = 1;
    rest['claimed'] = 1;
    rest['lat'] = coordinates.lat;
    rest['long'] = coordinates.long;
    rest['description'] = description;
    if (check) {
      //KACKEY--------
      rest['btype'] = 'online';
    } /* else {
            rest["btype"] = "offline";
        }*/
    if (!check && !rest.baddress) {
      return setBaddressError(true);
    }
    if (rest.baddress && !check) {
      rest['btype'] = 'offline';
    } // rest['baddress'] = 'business address';
    setShowLoad({
      showLoad: true,
    });
    //KACKEY--------
    axios
      .post(process.env.RAZZLE_RUNTIME_APP_BASEURL + '/users/create', rest)
      .then((response) => {
        setUserData({
          isLogin: true,
          uname: response.data.udata.email,
          token: response.data.token,
          type: response.data.udata.type,
          id: response.data.udata.id,
          btype: response.data.udata.btype,
        });
        Cookies.set('authtoken', response.data.token);
        Cookies.set('uname', response.data.udata.email);
        Cookies.set('id', response.data.udata.id);
        Cookies.set('uname', response.data.udata.email);
        Cookies.set('btype', response.data.udata.btype);
        history.push('/business-home');
      })
      .catch((err) => {
        // console.log(err);
        if (err.response.data) {
          setShowError(err.response.data);
        } else {
          setShowError({ message: 'Something went wrong' });
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
      });
  };

  const handleForm = async (data) => {
    console.log(data);
    const stripeResponse = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        name: `${data?.fname} ${data?.lname}`,
        email: data?.email,
      },
    });
    console.log({ stripeResponse });
    return stripeResponse;
  };

  const validateNotRobot = () => {
    if (!check && !form.getValues('baddress')) {
      setBaddressError(true);
    }
    // if (robot.isRobot) {
    //     setRobot({ ...robot, showRobotErr: true });
    //     return;
    // }
  };

  useEffect(
    () => {
      if (check || watch('baddress')) {
        setBaddressError(false);
        setData(onlinecategorydata);
      }
    },
    [check],
    watch('baddress'),
  );

  useEffect(
    () => {
      if (!check || watch('baddress')) {
        setData(categorydata);
      }
    },
    [check],
    watch('baddress'),
  );

  useEffect(() => {
    register({ name: 'baddress', required: true });
  }, []);

  const onInputChange = (event) => {
    setDescription(event.target.value);
  };
  const shortDescChange = (event) => {
    setCount(event.target.value.length);
  };

  return (
    <>
      <Helmet>
        <title>List Your Business For Free Online | Free Business Signup</title>
        <meta
          name='description'
          content='List Your Business Online For Free at momnpophub.com and increase visibility for your business with a local listing service. For more info visit us or Signup Today.'
        ></meta>
      </Helmet>

      <SignUpForm />
    </>
  );
};

const BusSignupWrapper = () => (
  <Elements stripe={stripePromise}>
    <BusSignup />
  </Elements>
);

export default BusSignupWrapper;
