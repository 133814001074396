import React from 'react';

import bannerImg from '../../../../assets/images/blog/blog-post-proposal.png';

const SendArticle = ({ children }) => {
  return (
    <div className='blog__banner-container'>
      <div className='blog__banner-content'>
        <div className='blog__banner-title'>
          <h2>Submit Your Blog Post Proposal</h2>
          <p className='blog__banner-description'>
            Thank you for your interest in contributing to our website's blog! If you have an
            interesting article you'd like to publish, please fill out the form below. Our editorial
            team will review your submission and reach out to you if your article meets our
            criteria.
          </p>
          <div className='blog__banner-container_button'>{children}</div>
        </div>
        <img src={bannerImg} alt='Banner Image' className='blog__banner-image' />
      </div>
    </div>
  );
};

export default SendArticle;
