import './Rewards.css';

import React, { useState } from 'react';
import RewardData from './reward-data/RewardData';

const Rewards = () => {

    const [totalDataLen, setTotalLen] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const pageSize = 5;

    return (

        <div className="flex-grow-1  d-flex flex-column overflow-auto">
            <header className="d-flex justify-content-between justify-content-center secttion-container__header position-relative">
                <h2 className="title m-0 d-flex align-items-center">Rewards</h2>
            </header>
            <RewardData
                setTotalLen={setTotalLen}
                currentPage={currentPage}
                pageSize={pageSize}
            />
        </div>
    );
};

export default Rewards;
