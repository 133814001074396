import './breadcrumb.css';

import React, { useEffect } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';

const Breadcrumb = () => {
  const { url } = useRouteMatch();
  const match = useParams();
  const steps = [];

  if (url.includes('online-store')) {
    steps.push({
      id: new Date(),
      name: 'ONLINE STORE',
      url: '/business-category',
    });
  } else {
    steps.push({
      id: new Date(),
      name: 'PHYSICAL STORE',
      url: '/business-category',
    });
  }

  for (const key in match) {
    //const name = String(match[key]).replaceAll("-", " ").toUpperCase();
    const name = String(match[key]).toUpperCase();
    const url = `${steps[steps.length - 1].url}/${match[key]}`;
    steps.push({
      id: new Date(),
      name,
      url,
    });
  }

  console.log(steps);
  return (
    <div className='content'>
      <div className='block'>
        <nav aria-label='breadcrumb'>
          <ol className='breadcrumb'>
            <li className='breadcrumb-item'>
              <a className='breadcrumb_link_item' href='/business-category'>
                HOME
              </a>
            </li>
            {steps.map((item, index) => {
              if (index != 0) {
                if (index + 1 !== steps.length) {
                  return (
                    <li className='breadcrumb-item' key={index}>
                      <a className='breadcrumb_link_item' href={`${item.url}`}>
                        {item.name}
                      </a>
                    </li>
                  );
                }
                return (
                  <li className='breadcrumb-item active' aria-current='page' key={index}>
                    <span className='bradcrumb_last_item'>{item.name}</span>
                  </li>
                );
              }
            })}
          </ol>
        </nav>
      </div>
    </div>
  );
};
export default Breadcrumb;
