import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  fetchArticles,
  fetchArticlesForCategory,
  fetchArticlesForSubCategory,
  fetchArticlesForSearch,
} from '../../api/blog';
import BlogClient from './client';
import usePageData from '../../../common/hooks/usePageData';

const Blog = ({ userEditor = false, pageData }) => {
  const serverArticle = usePageData(pageData);

  const [articles, setArticles] = useState( serverArticle?.data || []);
  const [fetchArticleLoading, setFetchArticleLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [category, setCategory] = useState(null);
  const [subCategory, setSubCategory] = useState(null);
  const [search, setSearch] = useState('none');
  const [filterVisible, setFilterVisible] = useState(true);
  const [routToBlog, setRoutToBlog] = useState(false);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location);
    const searchToBlog = searchParams.get('searchToBlog');
    const categoryToBlog = searchParams.get('categoryToBlog');
    const subcategoryToBlog = searchParams.get('subcategoryToBlog');

    if (searchToBlog) {
      setSearch(searchToBlog);
      setRoutToBlog(true);
    } else if (subcategoryToBlog) {
      setSubCategory({
        category: categoryToBlog,
        subCategory: subcategoryToBlog,
      });
      setCategory(categoryToBlog);
      setRoutToBlog(true);
    } else if (categoryToBlog) {
      setCategory(categoryToBlog);
      setSubCategory(null);
      setRoutToBlog(true);
    } else {
      setCategory('all-categories');
      setRoutToBlog(true);
    }
  }, [location]);

  useEffect(() => {
      const fetchArticlesData = async () => {
        if (
          routToBlog &&
          search === 'none' &&
          page &&
          category === 'all-categories' &&
          !subCategory
        ) {
          setHasMore(false);
          setFetchArticleLoading(true);
          try {
            const articles = await fetchArticles({ page, filterVisible });
            if (filterVisible || !filterVisible) {
              setArticles([]);
            }
            hideButtonMore(articles);
          } catch (error) {
          }
        }
      };

      fetchArticlesData();
  }, [page, search, category, subCategory, routToBlog, filterVisible]);

  useEffect(async () => {
    if (routToBlog && category !== 'all-categories' && !subCategory) {
      setHasMore(false);
      setFetchArticleLoading(true);
      const categoryArticle = await fetchArticlesForCategory(page, category, filterVisible);
      hideButtonMore(categoryArticle);
    }
  }, [routToBlog, page, category, filterVisible, subCategory]);

  useEffect(async () => {
    if (routToBlog && page && subCategory) {
      setFetchArticleLoading(true);
      const subCategoryArticle = await fetchArticlesForSubCategory({
        page,
        category: subCategory.category,
        subCategory: subCategory.subCategory,
        filterVisible,
      });
      hideButtonMore(subCategoryArticle);
    }
  }, [routToBlog, page, subCategory, filterVisible]);

  useEffect(async () => {
    if (search !== 'none' && page) {
      setHasMore(false);
      setFetchArticleLoading(true);
      const searchArticles = await fetchArticlesForSearch(search, page, filterVisible);
      hideButtonMore(searchArticles);
    }
  }, [search, page, filterVisible]);

  const hideButtonMore = (articles) => {
    if (articles.length < 6) {
      setArticles((prevArticles) => [...prevArticles, ...articles]);
      setFetchArticleLoading(false);
      setHasMore(false);
    } else {
      setArticles((prevArticles) => [...prevArticles, ...articles]);
      setFetchArticleLoading(false);
    }
  };

  const onFetchMoreArticles = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handleCategoryClick = (categoryVal) => {
    setCategory(categoryVal.machine_name);
    setSubCategory(null);
    setPage(1);
    setArticles([]);
  };

  const handleSubcategoryClick = (categoryVal, subcategoryVal) => {
    setSubCategory({
      category: categoryVal.machine_name,
      subCategory: subcategoryVal.machine_name,
    });
    setPage(1);
    setArticles([]);
  };

  const handleSearchClick = (searchVal) => {
    const noSpace = searchVal.trim();
    if (!noSpace.length) {
      setCategory('all-categories');
      setSearch('none');
      setPage(1);
    } else {
      setSearch(searchVal);
      setPage(1);
      setArticles([]);
    }
  };

  const toggleVisibility = () => {
    setFilterVisible(!filterVisible);
  };

  return (
    <div>
      <BlogClient
        userEditor={userEditor}
        articles={articles}
        fetchArticleLoading={fetchArticleLoading}
        hasMore={hasMore}
        filterVisible={filterVisible}
        category={category}
        subCategory={subCategory}
        onFetchMoreArticles={onFetchMoreArticles}
        handleCategoryClick={handleCategoryClick}
        handleSubcategoryClick={handleSubcategoryClick}
        handleSearchClick={handleSearchClick}
        toggleVisibility={toggleVisibility}
      />
    </div>
  );
};

export default Blog;
