import './about_userf.css';

import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useWebPSupportCheck } from 'react-use-webp-support-check';

const AboutUserf = (props) => {
  const supportsWebP = useWebPSupportCheck();
  const history = useHistory();

  // const toFreeSignUp = () => {
  //     window.scrollTo({ top: 0, behavior: "smooth" });
  //     if(props.where != 'Bus')
  //         history.push("/free-business-invitation");
  // }
  return (
    <>
      <div className='part_block'>
        <div className='row custom_container'>
          <div className='about_userf_left'>
            <div className='about_userf_title'>
              <h2>User Friendly Website for your Small Business</h2>
            </div>
            <div className='about_userf_text'>
              Once you List Your Business For Free with us, our simple user friendly web based
              platform helps you to send deals to customers on the mobile apps within a few clicks.
            </div>
            {/* <button onClick={toFreeSignUp}  className='about_userf_btn'> Free Signup </button>                 */}
            <Link to='/free-business-invitation'>
              <button className='about_signup_btn'> Free Business Signup</button>
            </Link>
          </div>
          <div className='about_userf_right'>
            <div
              className={supportsWebP ? 'about_userf_img webp' : 'about_userf_img no-webp'}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUserf;
