export const formattedData = (arr) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return arr.map((item) => {
    const formattedDate = new Date(item.createdAt).toLocaleDateString('en-Gb', options);
    return {
      ...item,
      createdAt: formattedDate,
    };
  });
}
