import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import Cookies from 'js-cookie';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import Loader from 'react-loader-spinner';
import { Link, useHistory } from 'react-router-dom';

import { callApi } from '../../api/API';
import UserContext from '../../contexts/userContext';
import { firebaseAnalytics } from './../../root/firebaseConfig';

// import { firebaseAnalytics } from "./../../root/firebaseConfig";
// ReactGA.initialize("G-9X3L2Q6PX8")
const LoginPage = ({ logout, setWhere }) => {
  useEffect(() => {
    setWhere('Log');
  });

  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();
  const [emailSent, setEmailSent] = useState(false);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [showLoad, setShowLoad] = useState({ showLoad: false });
  const [showError, setShowError] = useState({ message: false });
  const { userData, setUserData } = useContext(UserContext);
  if (userData.isLogin) {
    history.push('/business-home');
  }
  const handleSubmitForm = async (payload) => {
    setShowLoad({
      showLoad: true,
    });
    try {
      const login = await callApi('/users/login', 'POST', payload);
      if (login) {
        const { udata, token } = login;
        const { email, id, type, btype, key } = udata;

        Cookies.set('authtoken', token);
        Cookies.set('id', id);
        Cookies.set('uname', email);
        Cookies.set('key', key);
        Cookies.set('btype', btype);

        firebaseAnalytics.logEvent('login', { user_id: id });
        // ReactGA("event", "login", {
        //   user_id : id
        // })
        history.push('/business-home');
        setUserData({
          isLogin: true,
          uname: email,
          token: token,
          type: type,
          id: id,
          btype: btype,
          key: key,
        });
      } else {
        setShowError({ message: 'User' });
      }
    } catch (error) {
      setShowError({ message: 'Something Wrong' });
    }
  };

  return (
    <>
      <Helmet>
        <title>Login Your Business - Mom n Pop Hub</title>
        <meta
          name='description'
          content='Mom n Pop Hub is a platform exclusive to small businesses. Login to your account to update business details like contact information, add, edit customers to CRM, add deals. '
        ></meta>
        <meta name='keywords' content='Login,Sign In, Mom n Pop Hub'></meta>
        <meta name='publisher' content='Mom n Pop Hub'></meta>

        <link rel='canonical' href='/login' />

        <meta property='twitter:title' content='Login Your Business - Mom n Pop Hub' />
        <meta
          property='twitter:description'
          content='Mom n Pop Hub is a platform exclusive to small businesses. Login to your account to update business details like contact information, add, edit customers to CRM, add deals. '
        />
        <meta
          property='twitter:image'
          content='imhttps://momnpophub.com/static/media/momnpophub-logo.1f15802f.svgage'
        />
        <meta property='twitter:card' content='Login On Mom n Pop Hub Hub for FREE' />
        <meta property='twitter:site' content='twitter id' />

        <meta property='og:title' content='Login Your Business - Mom n Pop Hub' />
        <meta
          property='og:description'
          content='Mom n Pop Hub is a platform exclusive to small businesses. Login to your account to update business details like contact information, add, edit customers to CRM, add deals. '
        />
        <meta property='og:publisher' content='Mom n Pop Hub' />
        <meta property='og:locale' content='en_US' />
        <meta property='og:type' content='website' />
        <meta
          property='og:image'
          content='https://momnpophub.com/static/media/momnpophub-logo.1f15802f.svg'
        />
        <meta property='og:site_name' content='https://momnpophub.com/' />
        <meta property='og:url' content='/login' />
        <meta property='og:url' content='https://momnpophub.com/login' />
      </Helmet>
      <div className='col-sm-4 mx-auto text-center form-group'>
        <Form className='login-form' onSubmit={handleSubmit(handleSubmitForm)}>
          {showLoad.showLoad ? (
            <Loader
              type='Bars'
              color='#FF992E'
              height={30}
              width={100}
              timeout={2000} //3 secs
            />
          ) : null}
          {showError.message ? <p style={{ color: 'red' }}>Wrong Username/Password</p> : null}
          {/*<h2 className="text-center modal-title d-flex align-items-center justify-content-center">
                            Authenticate on <img src={logo} alt=""/></h2>*/}
          <Form.Group>
            <Form.Label className='modal-label'>Email address</Form.Label>
            <Form.Control
              name='email'
              ref={register({ required: true, pattern: /\S+@\S+\.\S+/ })}
              type='email'
              className={`input ${errors.email ? 'is-invalid' : ''} shadow-none`}
              placeholder='Email Address *'
            />
            {errors.email && errors.email.type === 'required' && (
              <p style={{ color: 'red' }}>Email is required</p>
            )}
            {errors.email && errors.email.type === 'pattern' && (
              <p style={{ color: 'red' }}>Invalid email</p>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label className='modal-label'>Password</Form.Label>
            <Form.Control
              name='password'
              ref={register({ required: true })}
              type='password'
              className={`input ${errors.password ? 'is-invalid' : ''} shadow-none`}
              placeholder='Password'
            />
            {errors.password && <p style={{ color: 'red' }}>Password is required</p>}
          </Form.Group>
          <Button variant='btn' className='red-btn w-100' type='submit'>
            Log in
          </Button>
          <br />
          <br />
          <Link to='/recover'>Forgot your password?</Link>
        </Form>
      </div>
    </>
  );
};

export default LoginPage;
