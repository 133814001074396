import { useEffect, useState } from 'react';

const usePageData = ({ staticContext } = {}) => {
  const [pageData, setPageData] = useState(staticContext?.pageData || null);

  useEffect(() => {
    try {
      const pageDataElement = document.getElementById('page-data');
      if (pageDataElement) {
        const pageDataJson = JSON.parse(pageDataElement.textContent || '{}');
        setPageData(pageDataJson);

        pageDataElement.remove();
      } else {
        setPageData({});
      }
    } catch (e) {
      setPageData({});
    }
  }, []);

  return pageData;
};

export default usePageData;
