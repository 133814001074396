import React, { useEffect, useState } from 'react';
import Button from './components/Button';
import Category from './components/Category';
import Search from './components/Search';
import ArticleCard from './components/ArticleCard/';
import SendArticle from './components/Banner';
import MobileCategory from './components/Category/MobileCategory';
import Loader from 'react-loader-spinner';
import BreadcrumbsBlog from './components/Breadcrumbs';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import './blog.css';
import './components/Search/search.css';
import './components/Category/category.css';
import './components/ArticleCard/articleCard.css';
import './components/Banner/banner.css';;

const BlogClient = ({
  articles,
  fetchArticleLoading,
  hasMore,
  filterVisible,
  category,
  subCategory,
  userEditor,
  onFetchMoreArticles,
  handleCategoryClick,
  handleSubcategoryClick,
  handleSearchClick,
  toggleVisibility,
}) => {

  return (
    <>
      <Helmet>
        <title>Small Business Blog</title>
        <meta
          name='title'
          content='News, Tips and trends about small businesses at Mom n Pop Hub.'
        ></meta>
        <meta
          name='description'
          content='Unlock the door to exposure! Submit your blog post at Mom n Pop Hub—your gateway to sharing unique stories and insights. Join our community now!'
        ></meta>
        <meta
          name='keywords'
          content='Small business articles, Business development articles, Business growth tips, Small Business, Mom n Pop Shops, Ecommerce'
        ></meta>
      </Helmet>
      <section className='blog'>
        <div className='blog__container'>
          <BreadcrumbsBlog />
          <h1 className='blog__title'>{userEditor ? 'Admin Panel Blog' : 'BLOG'}</h1>
          {!userEditor && (
            <p className='blog__description'>
              Read how businesses can benefit from our listing services and business directories.
              Explore the latest here.
            </p>
          )}

          <div className='blog__content'>
            <div className='blog__article_container'>
              <div className='blog__article_content'>
                {articles.map((article, index) => (
                  <ArticleCard
                    article={article}
                    key={index}
                    userEditor={userEditor}
                    filterVisible={filterVisible}
                  />
                ))}
              </div>
              {fetchArticleLoading ? (
                <Loader
                  type='Bars'
                  color='#FF992E'
                  height={30}
                  width={100}
                  timeout={2000} //3 secs
                />
              ) : (
                !articles.length && !hasMore && <h3>No article found</h3>
              )}
              {hasMore && (
                <Button
                  className={'blog__article_button'}
                  text={'More'}
                  onClick={onFetchMoreArticles}
                />
              )}
            </div>

            <aside className='blog__sidebar'>
              {userEditor && (
                <div className='blog__sidebar-switch-container'>
                  <span className='switch-text'>
                    {filterVisible ? 'Publish Article' : 'Hide Article'}
                  </span>
                  <label className='blog__sidebar-switch'>
                    <input type='checkbox' checked={filterVisible} onChange={toggleVisibility} />
                    <span className='blog__sidebar-slider blog__sidebar-round'></span>
                  </label>
                </div>
              )}
              <Search onSearch={handleSearchClick}>
                <Button className={'blog__sidebar-search_button'} text={'Search'} />
              </Search>
              {category && (
                <Category
                  startCategory={category}
                  startSubCategory={subCategory?.subCategory}
                  onCategoryClick={handleCategoryClick}
                  onSubcategoryClick={handleSubcategoryClick}
                  className={'blog__sidebar-category'}
                />
              )}
              {category && (
                <MobileCategory
                  startCategory={category}
                  startSubCategory={subCategory?.subCategory}
                  onCategoryClick={handleCategoryClick}
                  onSubcategoryClick={handleSubcategoryClick}
                  className={'blog__sidebar-mobilecategory'}
                />
              )}
            </aside>
          </div>
        </div>
        {!userEditor && (
          <SendArticle>
            <Link to='/blog/submit-blog'>
              <Button className={'blog__banner-button'} text={'Submit'} />
            </Link>
          </SendArticle>
        )}
      </section>
    </>
  );
};

export default BlogClient;
