import React, { useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';

const ReactQuillWrapper = ({ onChange, userEditor = '' }) => {
  const ReactQuill = typeof window === 'object' ? require('react-quill') : () => false;

  const modules = {
    toolbar: [
      [{ header: '2' }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['blockquote'],
      ['image', 'video'],
      ['clean'],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'script',
    'indent',
    'direction',
    'align',
    'blockquote',
    'link',
    'image',
    'video',
  ];

  const [quillText, setQuillText] = useState(userEditor);

  useEffect(() => {
    onChange(quillText);
  }, [quillText]);

  const handleChange = (value) => {
    setQuillText(value);
  };

  return (
    <ReactQuill
      className='form-section__quill'
      modules={modules}
      formats={formats}
      onChange={handleChange}
      value={quillText}
    />
  );
};

export default ReactQuillWrapper;
