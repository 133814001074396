import React from 'react';
import { Link } from 'react-router-dom';
import './Breadcrumbs.css'

const BreadcrumbsBlog = ({ pageName, isBlogPage = false }) => {
  return (
    <div className="breadcrumbs-blog">
      <div className="breadcrumbs-blog_item">
        <Link className='active' to="/">HOME <img src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAQCAYAAAArij59AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACkSURBVHgBdZHRDYIwEIb/u+g7IziKPoExxhFYiRF0A4yBmPjCCI7gBk5gz0K5ULhySdPm+uX7eykQlTTHStpCpMkr7XEMwEk97MSltgiLkjb/+naGHw50fnYMQ/BtdO9txCwGZTJiGcNIElNMGohiVgwu02Ma2PIlgHJfMYQRfdbVAPIo+sudXx86vd7WsAnz9/r0G1TvqDbATO//wRpUT+i09QfesjiAewWJhwAAAABJRU5ErkJggg=='/>

        </Link>
        <Link className={`${isBlogPage ? 'active' : ''}`} to="/blog">BLOG {isBlogPage && (<img
          src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAQCAYAAAArij59AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACkSURBVHgBdZHRDYIwEIb/u+g7IziKPoExxhFYiRF0A4yBmPjCCI7gBk5gz0K5ULhySdPm+uX7eykQlTTHStpCpMkr7XEMwEk97MSltgiLkjb/+naGHw50fnYMQ/BtdO9txCwGZTJiGcNIElNMGohiVgwu02Ma2PIlgHJfMYQRfdbVAPIo+sudXx86vd7WsAnz9/r0G1TvqDbATO//wRpUT+i09QfesjiAewWJhwAAAABJRU5ErkJggg=='/>)}</Link>
        {isBlogPage && (<span>{pageName}</span>)}
      </div>
    </div>
  );
};

export default BreadcrumbsBlog;
