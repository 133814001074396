import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { useHistory } from 'react-router-dom';

import AboutContact from './components/about_contact';
import AboutSignup from './components/about_signup';
import AboutUserf from './components/about_userf';
import AboutVideo from './components/about_video';
import Animation1 from './components/animation_1';
import Animation2 from './components/animation_2';

const About = (props) => {
  useEffect(() => {
    props.setWhere('Home');
  }, []);

  let history = useHistory();

  return (
    <>
      <Helmet>
        <title>Mom n Pop Hub - The Only Platform Exclusive to Small Business</title>
        <meta
          name='description'
          content='Mom n Pop Hub is a mobile business directory where you can advertise services provided by your business for free. Mom n Pop Hub is exclusive to small businesses. '
        ></meta>
        <meta name='keywords' content='About Mom n Pop Hub, Mom n Pop Hub'></meta>
        <meta name='robots' content='index, follow' />
        <link rel='canonical' href={process.env.RAZZLE_RUNTIME_FRONTBASE_URL} />
        <meta name='publisher' content='Mom n Pop Hub'></meta>
        <meta
          property='twitter:title'
          content='Mom n Pop Hub - The Only Platform Exclusive to Small Business'
        />
        <meta
          property='twitter:description'
          content='Mom n Pop Hub is a mobile business directory where you can advertise services provided by your business for free. Mom n Pop Hub is exclusive to small businesses.'
        />
        <meta
          property='twitter:image'
          content='https://momnpophub.com/static/media/momnpophub-logo.1f15802f.svg'
        />
        <meta
          property='twitter:card'
          content='Mom n Pop Hub is a mobile business directory where you can advertise services provided by your business for free. Mom n Pop Hub is exclusive to small businesses.'
        />
        <meta property='twitter:site' content='twitter id' />
        <meta
          property='og:title'
          content='Mom n Pop Hub - The Only Platform Exclusive to Small Business'
        />
        <meta
          property='og:description'
          content='Mom n Pop Hub is a mobile business directory where you can advertise services provided by your business for free. Mom n Pop Hub is exclusive to small businesses.'
        />
        <meta property='og:publisher' content='Mom n Pop Hub' />
        <meta property='og:locale' content='en_US' />
        <meta property='og:type' content='website' />
        <meta
          property='og:image'
          content='https://momnpophub.com/static/media/momnpophub-logo.1f15802f.svg'
        />
        <meta property='og:site_name' content='https://momnpophub.com/' />
        <meta property='og:url' content='/' />
        <meta property='og:url' content='https://momnpophub.com/' />
      </Helmet>
      <AboutSignup
        where={props.where}
        setSelectedProfileId={props.setSelectedProfileId}
        setSelectedDealId={props.setSelectedDealId}
        history={history}
      />
      {/* <AboutSearch setSelectedProfileId={props.setSelectedProfileId} setSelectedDealId={props.setSelectedDealId} history={history} /> */}
      <Animation1 where={props.where} />
      <Animation2 where={props.where} />
      <AboutUserf where={props.where} />
      <LazyLoadComponent>
        <AboutVideo />
      </LazyLoadComponent>
      <AboutContact />
    </>
  );
};

export default About;
