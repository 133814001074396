import React from 'react';
import SignUp from './components/sign-up';
import AboutUsBook from './components/about-us-book';
import DownloadApp from './components/download-app';

const ContactUs = () => {
  return (
    <>
      <SignUp />
      <DownloadApp />
      <AboutUsBook />
    </>
  );
};

export default ContactUs;
