import React from 'react';

const Button = ({ className, text, onClick, isDisabled, children}) => {
  return (
    <button type='button' className={className} onClick={onClick} disabled={isDisabled}>
      {children} {text}
    </button>
  );
};

export default Button;
