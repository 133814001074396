import './physical_store_subcategory.css';

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useParams } from 'react-router-dom';

import Breadcrumb from '../../../components/breadcrumbs/breadcrumb';
import Button from '../../../components/button/Button';
import ContactUs from '../../../components/contact-us';
import categorydata from '../../../pages/business/components/categorydata';
import onlinecategorydata from '../../../pages/business/components/onlinecategorydata';
import StoreSubHeader from './store_sub_header';

//import './Customer-business-app.css';
const PhysicalStoreSubcategory = (props) => {
  const location = useLocation();
  console.log('getting here..');
  const { type } = useParams();
  let { subcategory } = useParams();
  // let subcatDashed = subcategory;
  // subcategory = subcatDashed.replace(/-/g, ' ');
  let data;
  for (let i = 0; i < categorydata.category.length; i++) {
    //console.log('Data -> ' + categorydata.category[i].name);
    if (categorydata.category[i].machine_name === subcategory) {
      data = categorydata;
      break;
    }
  }

  console.log(location.pathname);

  for (let i = 0; i < onlinecategorydata.category.length; i++) {
    if (onlinecategorydata.category[i].machine_name === subcategory) {
      data = onlinecategorydata;
      break;
    }
  }

  useEffect(() => {
    //setSubCats(subcats);
    //props.setWhere('CustomerApp');
    //  subcats;
  }, []);

  const _onButtonClick = () => {
    setCategoryList(true);
  };
  //const {subcategory } = useParams();
  const arrlist = [];
  try {
    //const arrlist = [];
    data.category.map((subcat) => {
      let itm = '';
      if (subcat.machine_name === subcategory) {
        const sbc = subcat.subcategory.map((scat) => {
          itm = { name: scat.name, machine_name: scat.machine_name };
          //arrlist.push(scat.machine_name);
          arrlist.push(itm);
        });
      }
    });
  } catch (e) {}

  console.log('Subcategory-----> ' + subcategory);
  return (
    <>
      <Helmet>
        {/* <title>List your {subcategory} business with us |  Mom N Pop Hub</title> */}

        {subcategory === 'auto-services' ? (
          <meta
            name='description'
            content='List your Auto Services Physical business with Mom N Pop Hub - The ultimate one-stop resource for Free local listings in your area. Signup Today for Free listing'
          ></meta>
        ) : null}

        {subcategory === 'active-life' ? (
          <meta
            name='description'
            content='List your Active Life physical store on our website today. This will help you attract and retain more customers. Visit us for free business signup Now!'
          ></meta>
        ) : null}

        {subcategory === 'arts-&-entertainment' ? (
          <meta
            name='description'
            content='List your small Arts & Entertainment business at Mom n Pop Hub to promote awareness and to reach out to more potential clients. Visit us for free business signup.'
          ></meta>
        ) : null}
        {subcategory === 'beauty-&-spa' ? (
          <meta
            name='description'
            content='List the most out of Beauty Salon and Spa at Mom n Pop Hub, a platform helping you run your beauty Spa business successfully! Signup today or Claim you business.'
          ></meta>
        ) : null}
        {subcategory === 'education' ? (
          <meta
            name='description'
            content='Education business listing is easy and smooth with Mom n Pop Hub. User-friendly and free of charge small business listing service. Sign up today for free'
          ></meta>
        ) : null}
        {subcategory === 'health-&-fitness' ? (
          <meta
            name='description'
            content='At Mom n Pop Hub list your health and fitness small business for free to expand your business reach and make it more profitable. List your business for free.'
          ></meta>
        ) : null}

        {subcategory === 'health-&-medical' ? (
          <meta
            name='description'
            content="Check the wide range of health & medical Physical store list with Mom n Pop Hub, It's the best resource to list your small business. Signup today For free."
          ></meta>
        ) : null}
        {subcategory === 'financial-services' ? (
          <meta
            name='description'
            content='Browse your financial services store with Mom n Pop Hub. The best small business listing service to get new clients and market share. Signup today for free'
          ></meta>
        ) : null}

        {subcategory === 'food-&-drinks' ? (
          <meta
            name='description'
            content='The only platform exclusive to small business, List and run a successful Food & Drinks businesses with Mom n Pop Hub in your local area. Signup today for FREE'
          ></meta>
        ) : null}

        {subcategory === 'for-home' ? (
          <meta
            name='description'
            content='Establish a presence for home based business with Mom n Pop Hub. Visit us today and list your small business store for free to boost your sake. Signup now.'
          ></meta>
        ) : null}
        {subcategory === 'hotel-&-travels' ? (
          <meta
            name='description'
            content='Explore and browse the best small Hotel & Travels business listing websites with Mom n Pop Hub. A free listing to promote small businesses! Signup today'
          ></meta>
        ) : null}

        {subcategory === 'local-services' ? (
          <meta
            name='description'
            content='If looking for the most-effective business listing services in your area, Mom n Pop Hub can help to list your services for free. For more information sign up today'
          ></meta>
        ) : null}
        {subcategory === 'night-life' ? (
          <meta
            name='description'
            content='List your night life business with the top free business directory that can help you to get more ROI. Visit us today and get your business listed for free '
          ></meta>
        ) : null}
        {subcategory === 'professional-services' ? (
          <meta
            name='description'
            content='Small Business listing for professional business services with Mom n Pop Hub for more revenue and success. Visit us today and list your business for free'
          ></meta>
        ) : null}

        {/* META TITLE FOR PHYSICAL CATEGOREIS        */}
        {subcategory === 'auto-services' ? (
          <title>List your Auto Services business with us | Mom N Pop Hub</title>
        ) : null}

        {subcategory === 'active-life' ? (
          <title>List of Active Life Local Business websites | Mom N Pop Hub</title>
        ) : null}

        {subcategory === 'arts-&-entertainment' ? (
          <title>List Small Arts & Entertainment Website List | Mom N Pop Hub</title>
        ) : null}
        {subcategory === 'beauty-&-spa' ? (
          <title>List your Beauty & Spa Website Locally | Mom n Pop Hub</title>
        ) : null}
        {subcategory === 'education' ? (
          <title>List online Education website Near You | Mom n Pop Hub</title>
        ) : null}
        {subcategory === 'health-&-fitness' ? (
          <title>Small Health & Fitness Business List For Free | Mom n Pop Hub</title>
        ) : null}

        {subcategory === 'health-&-medical' ? (
          <title>Check Wide Range of Health & Medical lists | Mom n Pop Hub</title>
        ) : null}
        {subcategory === 'financial-services' ? (
          <title>List Your Financial Services Business Locally |Mom n Pop Hub</title>
        ) : null}

        {subcategory === 'food-&-drinks' ? (
          <title>Listing For Food & Drinks | Food & Drinks Buiness | Mom n Pop Hub</title>
        ) : null}

        {subcategory === 'for-home' ? (
          <title> List For Home Businesses | Add Your For Home | Mom n Pop Hub</title>
        ) : null}
        {subcategory === 'hotel-&-travels' ? (
          <title>List Your Hotel & Travels Business in Local Area | Mom n Pop Hub</title>
        ) : null}

        {subcategory === 'local-services' ? (
          <title>Looking to list Local Services in your areas |Mom n Pop Hub</title>
        ) : null}
        {subcategory === 'night-life' ? (
          <title>List Your Business for Free - Night Life |Mom n Pop Hub</title>
        ) : null}
        {subcategory === 'professional-services' ? (
          <title>List Your Small Professional Services Business With | Mom n Pop Hub</title>
        ) : null}

        {subcategory === 'self-defense-classes' ? (
          <title>Self-defense Classes Near Me - Mom n Pop Hub</title>
        ) : null}

        {/* DESCRIPTION META FOR ONLINE CATEGOREIS */}

        {subcategory === 'shopify-store' ? (
          <meta
            name='description'
            content='List your eCommerce Online Shopify Store on Mom N Pop Hub to promote your business and to reach out to more potential clients. Signup Now!'
          ></meta>
        ) : null}
        {subcategory === 'woocommerce' ? (
          <meta
            name='description'
            content='If you want to take your business online, we will help you. Simply, list your Woocommerce Small Business on Mom N Pop Hub and gain more exposure.'
          ></meta>
        ) : null}
        {subcategory === 'wix' ? (
          <meta
            name='description'
            content='List your small wix ecommerce websites on Mom N Pop Hub and get your listing approved instantly. Signup today to list your business in local areas.'
          ></meta>
        ) : null}
        {subcategory === 'bigcommerce' ? (
          <meta
            name='description'
            content='Local Business Listing for your Bigcommerce Business at Mom N Pop Hub! To grow your small business locally, signup today for Free listing!'
          ></meta>
        ) : null}
        {subcategory === 'independent-website' ? (
          <meta
            name='description'
            content='List your independent website online with Mom N Pop Hub.  This is the easiest way to improve your business online. Signup today for free!'
          ></meta>
        ) : null}

        {subcategory === 'self-defense-classes' ? (
          <meta
            name='description'
            content='Add your self-defense classes on Mom n Pop Hub and attract  more potential local audience for free. Visit us today for free business signup.'
          ></meta>
        ) : null}

        {/* META TITLE FOR CATEGOREIS */}
        {subcategory === 'shopify-store' ? (
          <title>eCommerce Online Shopify Store Website List - Mon N Pop Hub</title>
        ) : null}
        {subcategory === 'woocommerce' ? (
          <title>Add Small Business Woocommerce Websites - Mom n Pop Hub</title>
        ) : null}
        {subcategory === 'wix' ? (
          <title>List Online Wix eCommerce Websites For Free - Mom n Pop Hub</title>
        ) : null}
        {subcategory === 'bigcommerce' ? (
          <title>Browse Bigcommerce Online Websites Lists - Mom n pop Hub</title>
        ) : null}
        {subcategory === 'independent-website' ? (
          <title>Online Independent Website lists - Signup Today - Mom n pop Hub</title>
        ) : null}
      </Helmet>
      {/* <title>Online Deals - Get the Great Deals ongoing by small business near to your home
        </title> */}

      <meta name='keywords' content='Customer Mom n Pop Hub, Business Mom n Pop Hub'></meta>
      <meta name='publisher' content='Mom n Pop Hub'></meta>
      <link rel='canonical' href='/customer-business-app' />
      <meta name='publisher' content='Mom n Pop Hub'></meta>
      {/* <meta name="description" content="Download the Mom n Pop Hub Mobile app to find ongoing great deals by small businesses near to your home"></meta> */}

      <meta
        property='twitter:title'
        content='Online Deals - Get the Great Deals ongoing by small business near to your home'
      />
      <meta
        property='twitter:description'
        content='Download the Mom n Pop Hub Mobile app to find ongoing great deals by small businesses near to your home'
      />
      <meta
        property='twitter:image'
        content='https://momnpophub.com/static/media/momnpophub-logo.1f15802f.svg'
      />
      <meta
        property='twitter:card'
        content="We have single app for both small business owners AND the customers who buy from small businesses. Download the app from app store to advertise your small business and its's deal for FREE. If you're a customer, use the same app redeem deal at a small business nearby or nationally for FREE."
      />
      <meta property='twitter:site' content='twitter id' />

      <meta
        property='og:title'
        content='Online Deals - Get the Great Deals ongoing by small business near to your home'
      />
      <meta
        property='og:description'
        content='Download the Mom n Pop Hub Mobile app to find ongoing great deals by small businesses near to your home'
      />
      <meta property='og:publisher' content='Mom n Pop Hub' />
      <meta property='og:locale' content='en_US' />
      <meta property='og:type' content='website' />
      <meta
        property='og:image'
        content='https://momnpophub.com/static/media/momnpophub-logo.1f15802f.svg'
      />
      <meta property='og:site_name' content='https://momnpophub.com/' />
      <meta property='og:url' content='/customer-business-app' />
      <meta property='og:url' content='https://momnpophub.com/customer-business-app' />
      <script type='application/ld+json'>
        {JSON.stringify({
          '@type': 'SoftwareApplication',
          name: 'Mom n Pop Hub',
          operatingSystem: ['ANDROID', 'IOS'],
          applicationCategory: 'BusinessApplication',
          aggregateRating: {
            '@type': 'AggregateRating',
            ratingValue: '4.6',
            ratingCount: '8864',
          },
          '@context': 'https://schema.org',
        })}
      </script>

      {/* </Helmet> */}

      <Breadcrumb />
      <StoreSubHeader category_name={subcategory} />
      <div className='container'>
        <div className='block'>
          <div className='d-flex flex-wrap justify-content-between col-12 pt-5'>
            {arrlist.length > 0
              ? arrlist.map((item, index) => {
                  return (
                    <div className='col-lg-5 col-md-5 col-sm-12 p-0 pb-4'>
                      {/* <Button key={index} name={item.replaceAll(/-/g,' ')} link={'/' + subcategory + '/' + item} /> */}
                      {/* <Button key={index} name={item.replaceAll(/-/g, ' ')} link={ item} /> */}
                      <Button key={index} name={item.name} link={item.machine_name} />
                      {/* <div className='item'><Link to={'/'+subcategory+'/'+item}>{item}</Link></div> */}
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>

      <ContactUs />
    </>
  );
};

export default PhysicalStoreSubcategory;
