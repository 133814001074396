import React, { useEffect, useState, useRef, useMemo } from "react";
import "./DeleteUser.css";
import { Button } from 'react-bootstrap';
import axios from "axios";
import toast from "react-hot-toast";
import Loader from 'react-loader-spinner';

const OtpInput = ({ length = 6, userId, setOtpSent, resendOtp, emailId }) => {
  const inputRefs = useRef([]);
  const [otp, setOtp] = useState(new Array(length).fill(""));
  const [error, setError] = useState(false);
  const [callingApi, setCallingApi] = useState(false);
  const [otpValue, setOtpValue] = useState();
  const [timeLeft, setTimeLeft] = useState(20 * 60); // 20 minutes timer in seconds

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(prevTime => {
        if (prevTime <= 0) {
          clearInterval(timer);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  const handleChange = (index, e) => {
    const value = e.target.value.trim();
    if (isNaN(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value.substring(value.length - 1);
    setOtp(newOtp);

    const combinedOtp = newOtp.join("");
    if (combinedOtp.length === length) {
      setOtpValue(combinedOtp);
    }

    if (value && index < length - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleClick = (index) => {
    inputRefs.current[index].setSelectionRange(1, 1);

    if (index > 0 && !otp[index - 1]) {
      inputRefs.current[otp.indexOf("")].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && !otp[index] && index > 0 && inputRefs.current[index - 1]) {
      inputRefs.current[index - 1].focus();
    }

    if (e.key === "Tab") {
      e.preventDefault();
    }
  };

  const onOtpSubmit = async () => {
    setCallingApi(true);
    try {
      const result = await axios.post(process.env.RAZZLE_RUNTIME_APP_BASEURL + '/email/verify-otp', {
        otp: otpValue,
        id: userId
      });
      toast.success(`${result?.data?.message}`);
      setCallingApi(false);
      setOtpSent(false);
    } catch (err) {
      toast.error(`${err?.response?.data?.message}`);
      setCallingApi(false);
    }
  };

  const verifyOtp = () => {
    const isFilled = otp.every(value => value !== "");
    setError(!isFilled);
    if (isFilled) {
      onOtpSubmit();
    }
  };
  const isTimeLeft = useMemo(() => timeLeft > 0, [timeLeft])
  const handleResendOtp = async () => {
    if (isTimeLeft) return

    setOtp(new Array(length).fill(""));
    const payload = {
      email: emailId
    }
    await resendOtp(payload); 
    setTimeLeft(20 * 60); 

  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <div>
      {callingApi && (
        <>
          <Loader color='#FF992E' />
        </>
      )}
      <div>
        {otp.map((value, index) => (
          <input
            key={index}
            type="text"
            ref={(input) => (inputRefs.current[index] = input)}
            value={value}
            onChange={(e) => handleChange(index, e)}
            onClick={() => handleClick(index)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            className="otpInput"
          />
        ))}
        {error && <p style={{ color: 'red', marginBottom: '2.5rem', marginTop: '-1.3rem' }}> Please fill in all the fields. </p>}
      </div>
      <div className={`${isTimeLeft ? "disable-resend" : ""} resend-btn`} onClick={handleResendOtp}>
        Resend Otp
      </div>
      <div>
        <Button onClick={verifyOtp} variant='submit' className='red-btn' type='submit'>
          Verify OTP
        </Button>
      </div>
      {isTimeLeft && <div className="timer">
        OTP Expires in: {formatTime(timeLeft)}
      </div>}
    </div>
  );
}

export default OtpInput;
