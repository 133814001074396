import React from 'react';

const GlobeIcon = (props) => (
  <svg width={18} height={18} viewBox='0 0 18 18' fill='none' {...props}>
    <path
      d='M11.8125 5.875C11.3203 2.74609 10.0898 0.53125 8.71875 0.53125C7.3125 0.53125 6.11719 2.74609 5.58984 5.875H11.8125ZM5.34375 9.25C5.34375 10.0586 5.37891 10.7969 5.44922 11.5H11.9531C12.0234 10.7969 12.0586 10.0586 12.0586 9.25C12.0586 8.47656 12.0234 7.73828 11.9531 7H5.44922C5.37891 7.73828 5.34375 8.47656 5.34375 9.25ZM16.7344 5.875C15.75 3.51953 13.7109 1.65625 11.1797 0.917969C12.0586 2.11328 12.6211 3.90625 12.9375 5.875H16.7344ZM6.22266 0.917969C3.69141 1.65625 1.65234 3.51953 0.667969 5.875H4.46484C4.78125 3.90625 5.34375 2.11328 6.22266 0.917969ZM17.1211 7H13.0781C13.1484 7.73828 13.2188 8.51172 13.2188 9.25C13.2188 10.0234 13.1484 10.7617 13.0781 11.5H17.1211C17.2969 10.7969 17.4023 10.0586 17.4023 9.25C17.4023 8.47656 17.2969 7.73828 17.1211 7ZM4.21875 9.25C4.21875 8.51172 4.25391 7.73828 4.32422 7H0.28125C0.105469 7.73828 0 8.47656 0 9.25C0 10.0586 0.105469 10.7969 0.28125 11.5H4.32422C4.25391 10.7617 4.21875 10.0234 4.21875 9.25ZM5.58984 12.625C6.11719 15.7891 7.3125 17.9688 8.71875 17.9688C10.0898 17.9688 11.3203 15.7891 11.8125 12.625H5.58984ZM11.1797 17.6172C13.7109 16.8789 15.75 15.0156 16.7344 12.625H12.9375C12.6562 14.6289 12.0586 16.4219 11.1797 17.6172ZM0.667969 12.625C1.65234 15.0156 3.69141 16.8789 6.22266 17.6172C5.34375 16.4219 4.78125 14.6289 4.46484 12.625H0.667969Z'
      fill='#D0D0D0'
    />
  </svg>
);

export default GlobeIcon;
