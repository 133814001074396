import React from 'react';
import { Link } from 'react-router-dom';

import './ShareBussinessDeals.css';

const ShareBussinessDeals = ({ deals }) => {
  let title = '';
  try {
    let urlpath = location.pathname.split('/');
    let urlarray = urlpath[urlpath.length - 1];
    title = urlarray.replaceAll(/-/g, ' ');
  } catch (e) {}

  return (
    <>
      <div className='bus_bottom_header'>{deals?.length} deals </div>{' '}
      <div className='bus_bottom_body'>
        <div className='row'>
          {deals?.map((deal) => {
            return (
              <div className='col-lg-3 col-md-4 col-sm-6 col-12 bus_detail_item'>
                <div className='bus_detail_item_icon'>
                  <img
                    src='https://api.momnpophub.com/image/percent.png'
                    width='65px'
                    height='65px'
                    alt='Discount Mom n Pop Hub'
                  />
                </div>
                <div className='bus_detail_item_detail'>
                  <Link to={deal?.url} style={{ textDecoration: 'none' }} className='LinkClass'>
                    <div className='bus_detail_txt1'>
                      <h2>{deal?.name}</h2>
                    </div>
                    <div className='bus_detail_txt2'> {deal?.description} </div>{' '}
                    <div className='bus_detail_txt3'>
                      <b> ${deal?.originalprice} </b> products for <b> ${deal?.price}</b>
                    </div>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ShareBussinessDeals;
