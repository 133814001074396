import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import AboutSearch from '../about/components/about_search';
import Animation1 from '../about/components/animation_1';
import Animation2 from '../about/components/animation_2';
import BusContact from './components/bus_contact.js';
import BusSignup from './components/bus_signup.js';

const Business = (props) => {
  let history = useHistory();
  useEffect(() => {
    props.setWhere('Bus');

    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <Helmet>
        <title>Free Business Signup - Mom n Pop Hub </title>

        <meta
          name='keywords'
          content='Register Business with Mom n Pop Hub, How Mom n Pop Hub works, Find Business, Mom n Pop Hub'
        ></meta>
        <meta name='publisher' content='Mom n Pop'></meta>
        <meta name="robots" content="index, follow" />
        <link rel='canonical' href='/free-business-invitation' />
        <meta name='publisher' content='Mom n Pop'></meta>
        <meta
          name='description'
          content='Simply fill the signup form on Mom n Pop Hub to list your small business on our mobile app platform. Our signup is geared towards brick and mortar as well e-commerce small businesses.'
        ></meta>

        <meta property='twitter:title' content='Free Business Signup - Mom n Pop Hub' />
        <meta
          property='twitter:description'
          content='Simply fill the signup form on Mom n Pop Hub to list your small business on our mobile app platform. Our signup is geared towards brick and mortar as well e-commerce small businesses.'
        />
        <meta
          property='twitter:image'
          content='https://momnpophub.com/static/media/momnpophub-logo.1f15802f.svg'
        />
        <meta property='twitter:card' content='List your business On Mom n Pop Hub for FREE' />
        <meta property='twitter:site' content='twitter id' />

        <meta property='og:title' content='Free Business Signup - Mom n Pop Hub' />
        <meta
          property='og:description'
          content='Simply fill the signup form on Mom n Pop Hub to list your small business on our mobile app platform. Our signup is geared towards brick and mortar as well e-commerce small businesses.'
        />
        <meta property='og:publisher' content='Mom n Pop Hub' />
        <meta property='og:locale' content='en_US' />
        <meta property='og:type' content='website' />
        <meta
          property='og:image'
          content='https://momnpophub.com/static/media/momnpophub-logo.1f15802f.svg'
        />
        <meta property='og:site_name' content='https://momnpophub.com/' />
        <meta property='og:url' content='/free-business-invitation' />
        <meta property='og:url' content='https://momnpophub.com/' />
      </Helmet>
      <BusSignup />
      <div className='col-md-4 mx-auto py-5 '>
        <AboutSearch
          history={history}
          setSelectedProfileId={props.setSelectedProfileId}
          setSelectedDealId={props.setSelectedDealId}
        />
      </div>
      <Animation1 />
      <Animation2 />
      <BusContact />
    </>
  );
};

export default Business;
