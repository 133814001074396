const categoryData = [
  {
    name: 'All categories',
    machine_name: 'all-categories',
    subcategory: [],
  },
  {
    name: 'Mom n Pop Hub',
    machine_name: 'mom-n-pop-hub',
    subcategory: [
      {
        name: 'Technology',
        machine_name: 'technology'
    },
      {
        name: 'Top Trends',
        machine_name: 'top-trends'
      },
      {
        name: 'Growth Tips',
        machine_name: 'growth-tips'
      },
      {
        name: 'Mom n Pop Hub Features',
        machine_name: 'mom-n-pop-hub-features'
      },
      {
        name: 'Small Business Leaders',
        machine_name: 'small-business-leaders'
      }
    ],
  },
  {
    name: 'Auto Services',
    machine_name: 'auto-services',
    subcategory: [
      {
        name: 'Auto Repair',
        machine_name: 'auto-repair',
      },
      {
        name: 'Auto Detailing',
        machine_name: 'auto-detailing',
      },
      {
        name: 'Body Shops',
        machine_name: 'body-shops',
      },
      {
        name: 'Car Wash',
        machine_name: 'car-wash',
      },
      {
        name: 'Car Dealers',
        machine_name: 'car-dealers',
      },
      {
        name: 'Oil Change',
        machine_name: 'oil-change',
      },
      {
        name: 'Parking',
        machine_name: 'parking',
      },
      {
        name: 'Towing',
        machine_name: 'towing',
      },
      {
        name: 'Car Paint',
        machine_name: 'car-paint',
      },
      {
        name: 'Auto Security',
        machine_name: 'auto-security',
      },
      {
        name: 'Gas Stations',
        machine_name: 'gas-stations',
      },
      {
        name: 'Registration Services',
        machine_name: 'registration-services',
      },
      {
        name: 'Used Car Dealers',
        machine_name: 'used-car-dealers',
      },
      {
        name: 'Motorcycle Dealers',
        machine_name: 'motorcycle-dealers',
      },
      {
        name: 'Motorcycle Repair',
        machine_name: 'motorcycle-repair',
      },
    ],
  },
  {
    name: 'Active Life',
    machine_name: 'active-life',
    subcategory: [
      {
        name: 'ATV Rentals',
        machine_name: 'atv-rentals',
      },
      {
        name: 'Airsoft',
        machine_name: 'airsoft',
      },
      {
        name: 'Badminton',
        machine_name: 'badminton',
      },
      {
        name: 'Basketball Courts',
        machine_name: 'basketball-courts',
      },
      {
        name: 'Bike Rentals',
        machine_name: 'bike-rentals',
      },
      {
        name: 'Bowling',
        machine_name: 'bowling',
      },
      {
        name: 'Bungee Jumping',
        machine_name: 'bungee-jumping',
      },
      {
        name: 'Climbing',
        machine_name: 'climbing',
      },
      {
        name: 'Free Diving',
        machine_name: 'free-diving',
      },
      {
        name: 'Scuba Diving',
        machine_name: 'scuba-diving',
      },
      {
        name: 'Fitness & Instruction',
        machine_name: 'fitness-&-instruction',
      },
      {
        name: 'Cardio Classes',
        machine_name: 'cardio-classes',
      },
      {
        name: 'Dance Studios',
        machine_name: 'dance-studios',
      },
      {
        name: 'Gyms',
        machine_name: 'gyms',
      },
      {
        name: 'Karate',
        machine_name: 'karate',
      },
      {
        name: 'Self-defense Classes',
        machine_name: 'self-defense-classes',
      },
      {
        name: 'Yoga',
        machine_name: 'yoga',
      },
      {
        name: 'Gymnastics',
        machine_name: 'gymnastics',
      },
      {
        name: 'Hiking',
        machine_name: 'hiking',
      },
      {
        name: 'Paintball',
        machine_name: 'paintball',
      },
      {
        name: 'Skydiving',
        machine_name: 'skydiving',
      },
      {
        name: 'Swimming Pools',
        machine_name: 'swimming-pools',
      },
    ],
  },

  {
    name: 'Arts & Entertainment',
    machine_name: 'arts-&-entertainment',
    subcategory: [
      {
        name: 'Arcades',
        machine_name: 'arcades',
      },
      {
        name: 'Art Galleries',
        machine_name: 'art-galleries',
      },
      {
        name: 'Cinema',
        machine_name: 'cinema',
      },
      {
        name: 'Drive-In Theater',
        machine_name: 'drive-in-theater',
      },
      {
        name: 'Haunted Houses',
        machine_name: 'haunted-houses',
      },
      {
        name: 'Art Museums',
        machine_name: 'art-museums',
      },
      {
        name: 'Opera & Ballet',
        machine_name: 'opera-&-ballet',
      },
      {
        name: 'Social Clubs',
        machine_name: 'social-clubs',
      },
      {
        name: 'Sports Betting',
        machine_name: 'sports-betting',
      },
      {
        name: 'Astrologers',
        machine_name: 'astrologers',
      },
      {
        name: 'Virtual Reality Centers',
        machine_name: 'virtual-reality-centers',
      },
    ],
  },
  {
    name: 'Beauty & Spa',
    machine_name: 'beauty-&-spa',
    subcategory: [
      {
        name: 'Barbers',
        machine_name: 'barbers',
      },
      {
        name: 'Cosmetics & Beauty Supply',
        machine_name: 'cosmetics-&-beauty-supply',
      },
      {
        name: 'Hair Extensions',
        machine_name: 'hair-extensions',
      },
      {
        name: 'Hair Loss Centers',
        machine_name: 'hair-loss-centers',
      },
      {
        name: 'Hair Removal',
        machine_name: 'hair-removal',
      },
      {
        name: 'Waxing',
        machine_name: 'waxing',
      },
      {
        name: 'Hair Stylists',
        machine_name: 'hair-stylists',
      },

      {
        name: 'Hot Springs',
        machine_name: 'hot-springs',
      },
      {
        name: 'Makeup Artists',
        machine_name: 'makeup-artists',
      },
      {
        name: 'Massage',
        machine_name: 'massage',
      },
      {
        name: 'Nail Salons',
        machine_name: 'nail-salons',
      },
      {
        name: 'Piercing',
        machine_name: 'piercing',
      },
      {
        name: 'Skin Care',
        machine_name: 'skin-care',
      },
      {
        name: 'Tattoo',
        machine_name: 'tattoo',
      },
      {
        name: 'Teeth Whitening',
        machine_name: 'teeth-whitening',
      },
    ],
  },
  {
    name: 'Education',
    machine_name: 'education',
    subcategory: [
      {
        name: 'Adult Education',
        machine_name: 'adult-education',
      },
      {
        name: 'Art Classes',
        machine_name: 'art-classes',
      },
      {
        name: 'College Counseling',
        machine_name: 'college-counseling',
      },
      {
        name: 'Colleges & Universities',
        machine_name: 'colleges-&-universities',
      },
      {
        name: 'Elementary Schools',
        machine_name: 'elementary-schools',
      },
      {
        name: 'Middle Schools & High Schools',
        machine_name: 'middle-schools-&-high-schools',
      },
      {
        name: 'Private Tutors',
        machine_name: 'private-tutors',
      },
      {
        name: 'Special Education',
        machine_name: 'special-education',
      },
      {
        name: 'Test Preparation',
        machine_name: 'test-preparation',
      },
      {
        name: 'Tutoring Centers',
        machine_name: 'tutoring-centers',
      },
      {
        name: 'Driving Schools',
        machine_name: 'driving-schools',
      },
      {
        name: 'Firearm Training',
        machine_name: 'firearm-training',
      },
      {
        name: 'Cooking Schools',
        machine_name: 'cooking-schools',
      },
    ],
  },
  {
    name: 'Health & Fitness',
    machine_name: 'health-&-fitness',
    subcategory: [
      {
        name: 'Home Healthcare',
        machine_name: 'home-healthcare',
      },
      {
        name: 'Dental',
        machine_name: 'dental',
      },
      {
        name: 'Gyms',
        machine_name: 'gyms',
      },
      {
        name: 'Weight Loss',
        machine_name: 'weight-loss',
      },
      {
        name: 'Natural Medicine',
        machine_name: 'natural-medicine',
      },
      {
        name: 'Diet & Nutrition',
        machine_name: 'diet-&-nutrition',
      },
      {
        name: 'Clinic & Medical Center',
        machine_name: 'clinic-&-medical-center',
      },
      {
        name: 'Nurse',
        machine_name: 'nurse',
      },
    ],
  },
  {
    name: 'Health & Medical',
    machine_name: 'health-&-medical',
    subcategory: [
      {
        name: 'Ayurveda',
        machine_name: 'ayurveda',
      },
      {
        name: 'Blood & Plasma Donation Centers',
        machine_name: 'blood-&-plasma-donation-centers',
      },
      {
        name: 'Cannabis Clinics',
        machine_name: 'cannabis-clinics',
      },
      {
        name: 'Counseling & Mental Health',
        machine_name: 'counseling-&-mental-health',
      },
      {
        name: 'Dentists',
        machine_name: 'dentists',
      },
      {
        name: 'Diagnostic Services',
        machine_name: 'diagnostic-services',
      },
      {
        name: 'Doctors',
        machine_name: 'doctors',
      },
      {
        name: 'Emergency Rooms',
        machine_name: 'emergency-rooms',
      },
      {
        name: 'Hearing Aid Providers',
        machine_name: 'hearing-aid-providers',
      },
      {
        name: 'Herbal Shops',
        machine_name: 'herbal-shops',
      },
      {
        name: 'Hospitals',
        machine_name: 'hospitals',
      },
      {
        name: 'Medical Centers',
        machine_name: 'medical-centers',
      },
      {
        name: 'Medical Spas',
        machine_name: 'medical-spas',
      },
      {
        name: 'Memory Care',
        machine_name: 'memory-care',
      },
      {
        name: 'Nutritionists',
        machine_name: 'nutritionists',
      },
      {
        name: 'Oxygen Bars',
        machine_name: 'oxygen-bars',
      },
      {
        name: 'Rehabilitation Center',
        machine_name: 'rehabilitation-center',
      },
      {
        name: 'Ultrasound Imaging Centers',
        machine_name: 'ultrasound-imaging-centers',
      },
      {
        name: 'Weight Loss Centers',
        machine_name: 'weight-loss-centers',
      },
      {
        name: 'Animal Hospital',
        machine_name: 'animal-hospital',
      },
    ],
  },
  {
    name: 'Financial Services',
    machine_name: 'financial-services',
    subcategory: [
      {
        name: 'Banks & Credit Unions',
        machine_name: 'banks-&-credit-unions',
      },
      {
        name: 'Business Financing',
        machine_name: 'business-financing',
      },
      {
        name: 'Currency Exchange',
        machine_name: 'currency-exchange',
      },
      {
        name: 'Debt Relief Services',
        machine_name: 'debt-relief-services',
      },
      {
        name: 'Financial Advising',
        machine_name: 'financial-advising',
      },
      {
        name: 'Installment Loans',
        machine_name: 'installment-loans',
      },
      {
        name: 'Insurance',
        machine_name: 'insurance',
      },
      {
        name: 'Investing',
        machine_name: 'investing',
      },
      {
        name: 'Mortgage Lenders',
        machine_name: 'mortgage-lenders',
      },
      {
        name: 'Tax Services',
        machine_name: 'tax-services',
      },
    ],
  },
  {
    name: 'Food & Drinks',
    machine_name: 'food-&-drinks',
    subcategory: [
      {
        name: 'Restaurants',
        machine_name: 'restaurants',
      },
      {
        name: 'Desserts',
        machine_name: 'desserts',
      },
      {
        name: 'Breweries',
        machine_name: 'breweries',
      },
      {
        name: 'Wineries & Distilleries',
        machine_name: 'wineries-&-distilleries',
      },
      {
        name: 'Cafes & Treats',
        machine_name: 'cafes-&-treats',
      },
      {
        name: 'Groceries & Markets',
        machine_name: 'groceries-&-markets',
      },
      {
        name: 'Catering & Supplies',
        machine_name: 'catering-&-supplies',
      },
      {
        name: 'Bagels',
        machine_name: 'bagels',
      },
      {
        name: 'Beverage Store',
        machine_name: 'beverage-store',
      },
      {
        name: 'Coffee & Tea',
        machine_name: 'coffee-&-tea',
      },
      {
        name: 'Convenience Stores',
        machine_name: 'convenience-stores',
      },
      {
        name: 'Custom Cakes',
        machine_name: 'custom-cakes',
      },
      {
        name: 'Food Trucks',
        machine_name: 'food-trucks',
      },
      {
        name: 'Water Stores',
        machine_name: 'water-stores',
      },
      {
        name: 'Herbs & Spices',
        machine_name: 'herbs-&-spices',
      },
      {
        name: 'Meat Shops',
        machine_name: 'meat-shops',
      },
    ],
  },
  {
    name: 'For Home',
    machine_name: 'for-home',
    subcategory: [
      {
        name: 'Air Conditioning & Heating',
        machine_name: 'air-conditioning-&-heating',
      },
      {
        name: 'Contractors',
        machine_name: 'contractors',
      },
      {
        name: 'Electricians',
        machine_name: 'electricians',
      },
      {
        name: 'Home Cleaners',
        machine_name: 'home-cleaners',
      },
      {
        name: 'Plumbers',
        machine_name: 'plumbers',
      },
      {
        name: 'Locksmiths',
        machine_name: 'locksmiths',
      },
      {
        name: 'Movers',
        machine_name: 'movers',
      },
      {
        name: 'Painters',
        machine_name: 'painters',
      },
      {
        name: 'Art',
        machine_name: 'art',
      },
      {
        name: 'Bath',
        machine_name: 'bath',
      },
      {
        name: 'Outdoor Décor',
        machine_name: 'outdoor-decor',
      },
      {
        name: 'Furniture',
        machine_name: 'furniture',
      },
      {
        name: 'Home Appliances',
        machine_name: 'home-appliances',
      },
      {
        name: 'Home Decor',
        machine_name: 'home-decor',
      },
      {
        name: 'Kitchen & Dining',
        machine_name: 'kitchen-&-dining',
      },
      {
        name: 'Luggage',
        machine_name: 'luggage',
      },
      {
        name: 'Mattresses & Accessories',
        machine_name: 'mattresses-&-accessories',
      },
      {
        name: 'Office & School Supplies',
        machine_name: 'office-&-school-supplies',
      },
      {
        name: 'Electronics Repair',
        machine_name: 'electronics-repair',
      },
    ],
  },
  {
    name: 'Hotel & Travels',
    machine_name: 'hotel-&-travels',
    subcategory: [
      {
        name: 'Hotels',
        machine_name: 'hotels',
      },
      {
        name: 'Motels',
        machine_name: 'motels',
      },
      {
        name: 'Bus Tours',
        machine_name: 'bus-tours',
      },
      {
        name: 'Travel Services',
        machine_name: 'travel-services',
      },
      {
        name: 'Travel Agents',
        machine_name: 'travel-agents',
      },
      {
        name: 'Passport & Visa Services',
        machine_name: 'passport-&-visa-services',
      },
      {
        name: 'Bed & Breakfast',
        machine_name: 'bed-&-breakfast',
      },
    ],
  },
  {
    name: 'Local Services',
    machine_name: 'local-services',
    subcategory: [
      {
        name: '3D Printing',
        machine_name: '3d-printing',
      },
      {
        name: 'Appliances & Repair',
        machine_name: 'appliances-&_repair',
      },
      {
        name: 'Art Installation',
        machine_name: 'art-installation',
      },
      {
        name: 'Bike Repair/Maintenance',
        machine_name: 'bike-repair-maintenance',
      },
      {
        name: 'Bus Rental',
        machine_name: 'bus-rental',
      },
      {
        name: 'Calligraphy',
        machine_name: 'calligraphy',
      },
      {
        name: 'Clock Repair',
        machine_name: 'clock-repair',
      },
      {
        name: 'Couriers & Delivery Services',
        machine_name: 'couriers-&-delivery-services',
      },
      {
        name: 'Donation Center',
        machine_name: 'donation-center',
      },
      {
        name: 'Electronics Repair',
        machine_name: 'electronics-repair',
      },
      {
        name: 'Funeral Services & Cemeteries',
        machine_name: 'funeral-services-&-cemeteries',
      },
      {
        name: 'Furniture Rental & Repair',
        machine_name: 'furniture-rental-&-repair',
      },
      {
        name: 'Musical Instrument Services',
        machine_name: 'musical-instrument-services',
      },
      {
        name: 'IT Services & Computer Repair',
        machine_name: 'it-services-&-computer-repair',
      },
      {
        name: 'Laundry Services',
        machine_name: 'laundry-services',
      },
      {
        name: 'Screen Printing',
        machine_name: 'screen-printing',
      },
      {
        name: 'Pest Control',
        machine_name: 'pest-control',
      },
      {
        name: 'Printing Services',
        machine_name: 'printing-services',
      },
      {
        name: 'Watch Repair',
        machine_name: 'watch-repair',
      },
      {
        name: 'Water Delivery',
        machine_name: 'water-delivery',
      },
      {
        name: 'Wildlife Control',
        machine_name: 'wildlife-control',
      },
    ],
  },
  {
    name: 'Night Life',
    machine_name: 'night-life',
    subcategory: [
      {
        name: 'Adult Entertainment',
        machine_name: 'adult-entertainment',
      },
      {
        name: 'Bars',
        machine_name: 'bars',
      },
      {
        name: 'Beer Gardens',
        machine_name: 'beer-gardens',
      },
      {
        name: 'Club Crawl',
        machine_name: 'club-crawl',
      },
      {
        name: 'Comedy Clubs',
        machine_name: 'comedy-clubs',
      },
      {
        name: 'Country Dance Halls',
        machine_name: 'country-dance-halls',
      },
      {
        name: 'Dance Clubs',
        machine_name: 'dance-clubs',
      },
      {
        name: 'Music Venues',
        machine_name: 'music-venues',
      },
      {
        name: 'Piano Bars',
        machine_name: 'piano-bars',
      },
      {
        name: 'Pool Halls',
        machine_name: 'pool-halls',
      },
    ],
  },
  {
    name: 'Professional Services',
    machine_name: 'professional-services',
    subcategory: [
      {
        name: 'Accountants',
        machine_name: 'accountants',
      },
      {
        name: 'Advertising',
        machine_name: 'advertising',
      },
      {
        name: 'Architects',
        machine_name: 'architects',
      },
      {
        name: 'Consultants',
        machine_name: 'consultants',
      },
      {
        name: 'Billing Services',
        machine_name: 'billing-services',
      },
      {
        name: 'Business Consulting',
        machine_name: 'business-consulting',
      },
      {
        name: 'Career Counseling',
        machine_name: 'career-counseling',
      },
      {
        name: 'Customs Brokers',
        machine_name: 'customs-brokers',
      },
      {
        name: 'Graphic Design',
        machine_name: 'graphic-design',
      },
      {
        name: 'Internet Service Providers',
        machine_name: 'internet-service-providers',
      },
      {
        name: 'Lawyers',
        machine_name: 'lawyers',
      },
      {
        name: 'Marketing',
        machine_name: 'marketing',
      },
      {
        name: 'Video/Film Production',
        machine_name: 'video/film-production',
      },
      {
        name: 'Translation Services',
        machine_name: 'translation-services',
      },
      {
        name: 'Wholesalers',
        machine_name: 'wholesalers',
      },
      {
        name: 'Patent Law',
        machine_name: 'patent-law',
      },
      {
        name: 'Product Design',
        machine_name: 'product-design',
      },
      {
        name: 'Private Investigation',
        machine_name: 'private-investigation',
      },
      {
        name: 'Web Design',
        machine_name: 'web-design',
      },
      {
        name: 'Security Services',
        machine_name: 'security-services',
      },
      {
        name: 'Software Development',
        machine_name: 'software-development',
      },
    ],
  },
];

export default categoryData;
