import React, { useEffect, useState } from 'react';
import articleImg from '../../../../assets/images/blog/article-default.png';
import authorIcon from '../../../../assets/images/blog/author-icon.svg';
import dateIcon from '../../../../assets/images/blog/data-icon.svg';
import { Helmet } from 'react-helmet';
import './articleCard.css';
import { useHistory } from 'react-router-dom';

const ArticleCard = ({ article, isVisible = true, userEditor = false, filterVisible }) => {
  const history = useHistory();

  const handleArticleClick = () => {
    if (userEditor) {
      history.push({
        pathname: `/blog-admin-panel/article/${article.uniqueAddress}`,
        userEditorParam: userEditor,
        filterVisibleParams: filterVisible,
      });
    } else history.push(`/blog/${article.uniqueAddress}`);
  };

  return (
    <>
      <article>
        <div className='blog__article' onClick={handleArticleClick}>
          <img
            src={!!article?.imageArticle ? article.imageArticle : articleImg}
            alt='Default Placeholder Image'
            className='blog__article-image'
          />
          {isVisible && <h2 className='blog__article-title'>{article?.title}</h2>}
          {isVisible && (
            <div className='blog__article-meta'>
              <div className='blog__article-author'>
                <img src={authorIcon} alt='Author Icon' className='blog__article-author-icon' />
                <span>{article?.author}</span>
              </div>
              <div className='blog__article-date'>
                <img src={dateIcon} alt='Calendar Icon' className='blog__article-date-icon' />
                <span>{article?.createdAt}</span>
              </div>
            </div>
          )}
          {isVisible && <p className='blog__article-description'>{article.smallDescription}</p>}
          {!isVisible && (
            <div className='blog__article-resentpost'>
              <h2 className='blog__article-title'>{article.title}</h2>
              <p className='blog__article-smallDescription'>{article.smallDescription}</p>
            </div>
          )}
        </div>
      </article>
    </>
  );
};

export default ArticleCard;
