import { callApi } from './API';
import Cookies from 'js-cookie';
import { formattedData } from '../utils/formattedData';
const API_ROOT = process.env.RAZZLE_RUNTIME_APP_BASEURL;

export const createArticle = async (data) => {
  try {
    const endpoint = `/articles`;
    const response = await fetch(API_ROOT + endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    return response;
  } catch (error) {
    console.error('Error fetching articles:', error);
    return [];
  }
};

export const fetchArticles = async ({ page, count = 6, filterVisible = true }) => {
  try {
    const cacheParam = Math.random();
    const endpoint = `/articles/filter?visible=${filterVisible}&count=${count}&page=${page}&cache=${cacheParam}`;
    const response = await callApi(endpoint, 'GET');
    if (!response.data) {
      return [];
    }
    return formattedData(response.data);
  } catch (error) {
    console.error('Error fetching articles:', error);
    return [];
  }
};

export const fetchArticlesUniqueAddress = async (uniqueAddress, filterVisible = true) => {
  try {
    const cacheParam = Math.random();
    const endpoint = `/articles/filter?visible=${filterVisible}&uniqueAddress=${uniqueAddress}&cache=${cacheParam}`;
    const response = await callApi(endpoint, 'GET');
    if (!response.data) {
      return [];
    }
    return formattedData(response.data);
  } catch (error) {
    console.error('Error fetching articles:', error);
    return [];
  }
};

export const fetchArticlesArticleId = async (id) => {
  try {
    const cacheParam = Math.random();
    const endpoint = `/articles/filter?visible=true&articleId=${id}&cache=${cacheParam}`;
    const response = await callApi(endpoint, 'GET');
    if (!response.data) {
      return [];
    }
    return formattedData(response.data);
  } catch (error) {
    console.error('Error fetching articles:', error);
    return [];
  }
};

export const fetchArticlesForCategory = async (page, category, filterVisible = true) => {
  try {
    const cacheParam = Math.random();
    const endpoint = `/articles/filter?visible=${filterVisible}&category=${category}&count=6&page=${page}&cache=${cacheParam}`;
    const response = await callApi(endpoint, 'GET');
    if (!response.data) {
      return [];
    }
    return formattedData(response.data);
  } catch (error) {
    console.error('Error fetching articles:', error);
    return [];
  }
};

export const fetchArticlesForSubCategory = async ({
  page,
  category,
  subCategory,
  count = 6,
  filterVisible = true,
}) => {
  try {
    const cacheParam = Math.random();
    const endpoint = `/articles/filter?visible=${filterVisible}&category=${category}&subcategory=${subCategory}&count=${count}&page=${page}&cache=${cacheParam}`;
    const response = await callApi(endpoint, 'GET');
    if (!response.data) {
      return [];
    }
    return formattedData(response.data);
  } catch (error) {
    console.error('Error fetching articles:', error);
    return [];
  }
};

export const fetchArticlesForSearch = async (search, page, filterVisible = true) => {
  try {
    const cacheParam = Math.random();
    const endpoint = `/articles/filter?visible=${filterVisible}&search=${search}&count=6&page=${page}&cache=${cacheParam}`;
    const response = await callApi(endpoint, 'GET');
    if (!response.data) {
      return [];
    }
    return formattedData(response.data);
  } catch (error) {
    console.error('Error fetching articles:', error);
    return [];
  }
};

export const articleDelete = async (id) => {
  try {
    const token = Cookies.get('authtoken');
    const response = callApi(`/articles/${id}`, 'DELETE', {}, `Bearer ${token}`);
    return response;
  } catch (error) {
    console.error('Error fetching articles:', error);
    return [];
  }
};

export const articleUpdate = async (id, publish ) => {
  try {
    const token = Cookies.get('authtoken');
    const data = {
      id: id,
      isPublished: publish,
    };
    const response = await callApi(`/articles/`, 'PUT', data, `Bearer ${token}`);
    return response;
  } catch (error) {
    console.error('Error fetching articles:', error);
    return [];
  }
};

export const articleUpdateBody = async (article, id, publish) => {
  try {
    const token = Cookies.get('authtoken');
    const data = {
      id: id,
      isPublished: publish,
      ...article,
    };
    const response = await callApi(`/articles/`, 'PUT', data, `Bearer ${token}`);
    return response;
  } catch (error) {
    console.error('Error fetching articles:', error);
    return [];
  }
};

export const userInfo = async (id) => {
  try {
    const endpoint = `/users/${id}`;
    const response = await callApi(endpoint, 'GET');
    return response[0];
  } catch (error) {
    console.error('Error user:', error);
    return [];
  }
};
