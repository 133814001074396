import './category.css';

import React from 'react';

import ONLINE from '../../../assets/images/online-store.svg';
import PHYSICAL from '../../../assets/images/physical-store.svg';
import Button from '../../../components/button/Button';
import CategoryHeader from './category_header';

//import "./businesses_list.css";

const CategoryList = (props) => {
  const businessesList = [
    {
      name: 'PHYSICAL STORE',
      //url: "physical-store",
      url: 'stores',
      type: 'physical-store',
      img: PHYSICAL,
    },
    {
      name: 'ONLINE STORE',
      url: 'stores',
      type: 'online-store',
      img: ONLINE,
    },
  ];
  return (
    <>
      {/* <Helmet> */}
      <title>Choose the relevant categories to list your business with Mom n Pop Hub</title>
      <meta
        name='description'
        content='Physical -----store and online store, we have 2 types of businesses categories to list your small business with Mom n Pop Hub. Signup today and will list your business for free.'
      ></meta>
      {/* </Helmet> */}
      <CategoryHeader />

      <div className='container'>
        <div className='block mb-4'>
          <div className='list d-flex flex-wrap justify-content-around'>
            {businessesList.map((item) => {
              return (
                <div className='card col-sm-8 col-md-6 col-lg-5'>
                  <img src={item.img} className='card-img-top' alt='...' />
                  {/* <Button name={item.name} link={item.url+"/"+item.type} /> */}
                  <Button name={item.name} link={item.type} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryList;
