import './category_header.css';

import React from 'react';

const CategoryHeader = (props) => {
  return (
    <div className='container'>
      <div className='block'>
        <div className='header'>
          <h1 className='category_h_text'>Business Categories</h1>
        </div>
        <div className='category_description'>
          <p className='category_desc_text'>
            We have 2 types of small businesses we list on Mom n Pop Hub -
            <span className='category_desc_highlighted'> Physical store</span> (aka Brick and mortar
            stores) and <span className='category_desc_highlighted'>Online only stores</span>.
            Please click on either of the icons below to see the categories and relevant
            subcategories.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CategoryHeader;
