import React from 'react';

const LocationIcon = (props) => (
  <svg width={14} height={19} viewBox='0 0 14 19' fill='none' {...props}>
    <path
      d='M6.04688 17.8984C6.36328 18.3906 7.10156 18.3906 7.41797 17.8984C12.5508 10.5156 13.5 9.74219 13.5 7C13.5 3.27344 10.4766 0.25 6.75 0.25C2.98828 0.25 0 3.27344 0 7C0 9.74219 0.914062 10.5156 6.04688 17.8984ZM6.75 9.8125C5.16797 9.8125 3.9375 8.58203 3.9375 7C3.9375  5.45312 5.16797 4.1875 6.75 4.1875C8.29688 4.1875 9.5625 5.45312 9.5625 7C9.5625 8.58203  8.29688 9.8125 6.75 9.8125Z'
      fill='#D0D0D0'
    />
  </svg>
);

export default LocationIcon;
