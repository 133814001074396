import './store_desc_header.css';

import React from 'react';

const StoreDescHeader = ({ meta, categoryname, subcategoryname, type = '' }) => {
  let catnameHeading = '';
  try {
    catnameHeading = meta.catname.replaceAll(/-/g, ' ');
  } catch (e) {}
  return (
    <>
      {/* <Helmet>
        <title>{meta.metatitle}</title>
        <meta name="description" content={meta.metadesc} />
        <meta name="publisher" content="Mom n Pop Hub" />
        <meta property="twitter:title" content={categoryname + subcategoryname} />
        <meta property="twitter:description" content={subcategoryname} />
        <meta property="twitter:site" content="twitter id" />
        <meta property="og:title" content={categoryname + subcategoryname} />
        <meta property="og:description" content={subcategoryname} />
        <meta property="og:publisher" content="Mom n Pop Hub" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="https://momnpophub.com/" />
      </Helmet> */}

      <div className='content'>
        <div className='block'>
          <div className='header'>
            <h1 className='h_text'>{catnameHeading}</h1>
          </div>
          <div className='description'>
            <p className='desc_text'>{meta?.titledesc}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default StoreDescHeader;
