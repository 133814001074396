import React from 'react';
import CustomerLogsTable from './table/CustomerLogsTable';

function CustomerLogs() {
  return (
    <div className='flex-grow-1  d-flex flex-column'>
      <header className='d-flex justify-content-between justify-content-center secttion-container__header position-relative'>
        <h2 className='title m-0 d-flex align-items-center'>Customer Logs</h2>
      </header>
      <CustomerLogsTable />
    </div>
  );
}

export default CustomerLogs;
