import React, { useEffect, useState } from 'react';
import categoryData from './categorydata';

const MobileCategory = ({
  onCategoryClick,
  onSubcategoryClick,
  className,
  startCategory,
  startSubCategory,
  filter = false,
  userEditor = false,
}) => {
  const [activeCategory, setActiveCategory] = useState(null);
  const [activeSubcategory, setActiveSubcategory] = useState(null);

  useEffect(() => {
    const startCategoryValue =
      categoryData.find((item) => item.machine_name === startCategory) || categoryData[0];
    setActiveCategory(startCategoryValue);

    const startSubCategoryValue =
      startCategoryValue.subcategory.find(
        (item) => item.machine_name === startSubCategory) || [];
    setActiveSubcategory(startSubCategoryValue);
  }, []);

  useEffect(() => {
    if (!!userEditor) {
      const userCategory = categoryData.find(
        (category) => category.machine_name === userEditor.category,
      );
      setActiveCategory(userCategory);
      setActiveSubcategory(
        userCategory?.subcategory.find(
          (subcategory) => subcategory.machine_name === userEditor.subCategory,
        ),
      );
    }
  }, [userEditor]);

  const updatedCategoryData = filter
    ? categoryData.filter((category) => category.machine_name !== 'all-categories')
    : categoryData;

  const handleMobileSubcategoryClick = (subcategory) => {
    if (subcategory) {
      const selectedSubcategory = activeCategory.subcategory.find(
        ({ name }) => name === subcategory,
      );
      setActiveSubcategory(selectedSubcategory);
      onSubcategoryClick(activeCategory, selectedSubcategory);
    }
  };

  const handleMobileCategoryClick = (category) => {
    if (category) {
      const selectedCategory = updatedCategoryData.find(({ name }) => name === category);
      setActiveCategory(selectedCategory);
      setActiveSubcategory(null);
      onCategoryClick(selectedCategory);
    }
  };

  return (
    <div className={`${className} sidebar-mobile-categories_container`}>
      <select
        className='sidebar-mobilecategories-select'
        onChange={(e) => handleMobileCategoryClick(e.target.value)}
        value={activeCategory ? activeCategory.name : ''}
      >
        <option value=''>Choose category*</option>
        {updatedCategoryData.map((category) => (
          <option key={category.machine_name} value={category.name}>
            {category.name}
          </option>
        ))}
      </select>

      <select
        className='sidebar-mobilecategories-select'
        onChange={(e) => handleMobileSubcategoryClick(e.target.value)}
        value={activeSubcategory ? activeSubcategory.name : ''}
      >
        <option value='' style={{ paddingLeft: '16px' }}>
          Choose subcategory*
        </option>
        {activeCategory &&
          activeCategory.subcategory.map((subcategory) => (
            <option
              style={{ paddingLeft: '16px' }}
              key={subcategory.machine_name}
              value={subcategory.name}
            >
              {subcategory.name}
            </option>
          ))}
      </select>
    </div>
  );
};

export default MobileCategory;
