import './store_sub_header.css';

import React from 'react';

import Button from '../../../components/button/Button';

const StoreSubHeader = (props) => {
  let catname = '';
  try {
    catname = props.category_name.replaceAll(/-/g, ' ');
  } catch (e) {}

  return (
    <div className='container'>
      <div className='block'>
        <div className='d-flex flex-wrap text_block'>
          <div className='col-md-4 col-lg-3 col-sm-6 col-xs-7'>
            <Button name={catname} link='###' large={true} />
          </div>
          <div className='col-md-8 col-lg-9 col-sm-12 col-xs-12'>
            <div className='header'>
              <h1 className='h_text'>{catname}</h1>
            </div>
            <div className='description'>
              {/* <span className="desc_highlighted">Independent</span> has 15 of
                sub-categories. Please click your desired sub-category to see
                business listings in that sub-category. */}
              {props.category_name === 'auto-services' ? <Autoservices /> : null}
              {props.category_name === 'active-life' ? <Activelife /> : null}
              {props.category_name === 'arts-&-entertainment' ? <Artsandentertainment /> : null}
              {props.category_name === 'beauty-&-spa' ? <Beautyandspa /> : null}
              {props.category_name === 'education' ? <Education /> : null}
              {props.category_name === 'health-&-fitness' ? <Healthandfitness /> : null}
              {props.category_name === 'health-&-medical' ? <Healthandmedical /> : null}
              {props.category_name === 'financial-services' ? <Financialservices /> : null}
              {props.category_name === 'food-&-drinks' ? <Foodanddrinks /> : null}
              {props.category_name === 'for-home' ? <Forhome /> : null}
              {props.category_name === 'hotel-&-travels' ? <Hotelandtravels /> : null}
              {props.category_name === 'local-services' ? <Localservices /> : null}
              {props.category_name === 'night-life' ? <Nightlife /> : null}
              {props.category_name === 'professional-services' ? <Professionalservices /> : null}
              {props.category_name === 'shopify-store' ? <Shopify /> : null}
              {props.category_name === 'woocommerce' ? <Woocommerce /> : null}
              {props.category_name === 'wix' ? <Wix /> : null}
              {props.category_name === 'bigcommerce' ? <Bigcommerce /> : null}
              {props.category_name === 'independent-website' ? <Independentwebsite /> : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StoreSubHeader;

const Autoservices = (props) => {
  return (
    <p className='desc_text'>
      {' '}
      We list the given below Auto Services of physical store business on Mom n Pop Hub. If you are
      a business owner who sells products or services to customers through a physical store, list
      your business with us. Please click on any of the categories to see the sub-categories.{' '}
    </p>
  );
};

const Activelife = (props) => {
  return (
    <p className='desc_text'>
      {' '}
      Browse the list below and find the relevant businesses information about the Active life. We
      have 14 of sub-categories listed. Please click your desired sub-category to see business
      listings in that sub-category.{' '}
    </p>
  );
};

const Artsandentertainment = (props) => {
  return (
    <p className='desc_text'>
      Register to a free business directory - Mom n Pop Hub to get instant customers! For your arts
      & entertainment business, spend less on your business with our free listing service. Click
      below to check out the sub-categories for business listings.
    </p>
  );
};

const Beautyandspa = (props) => {
  return (
    <p className='desc_text'>
      Make your beauty& spa business more profitable and visible with Mom n Pop Hub. For your local
      stores, our platform offers the best opportunity to reach potential customers easily. Browse
      the list below to find out about the sub-categories of business listings to increase
      visibility.
    </p>
  );
};
const Education = (props) => {
  return (
    <p className='desc_text'>
      For the small education businesses, Mom n Pop Hub offers the free listing service to help
      promote your business. Take a look at the listed categories and sub categories to increase
      your small business’s market presence.
    </p>
  );
};
const Healthandfitness = () => {
  return (
    <p className='desc_text'>
      Advertising and marketing a health & fitness business is easy with Mom n Pop Hub. Our
      exclusive small business platform offer free listing services to help businesses enhance their
      reach. Click on any of the below categories to check the sub-categories.
    </p>
  );
};

const Healthandmedical = () => {
  return (
    <p className='desc_text'>
      For health & medical services, get a free local business listing with Mom n Pop Hub. Register
      with us to attract more customers to your business. Please click on any of the categories to
      see the sub-classes.
    </p>
  );
};

const Financialservices = () => {
  return (
    <p className='desc_text'>
      Get your financial services listed on Mom n Pop Hub to improve your business presence and
      visibility. We offer free of cost business listing service too small businesses. Choose a
      category for your business profile to list your business with us.
    </p>
  );
};

const Foodanddrinks = () => {
  return (
    <p className='desc_text'>
      If you run a small business for food& Drinks, list it on Mom n Pop Hub to promote your
      business. Our services offer you valuable exposure that can grow your business at no cost at
      all. Click below on the mail category to view the list.
    </p>
  );
};
const Forhome = () => {
  return (
    <p className='desc_text'>
      If you run a home-based business, list it on Mom n Pop Hub to gain valuable exposure at no
      cost. Below is a list for home based business categories to choose from.
    </p>
  );
};
const Hotelandtravels = () => {
  return (
    <p className='desc_text'>
      Make the most of your hotel &travels business with listing it on Mom n Pop Hub. Our services
      allow you to expand your small business in a minimum amount of time. Check out the list of
      business categories and sub-categories.
    </p>
  );
};
const Localservices = () => {
  return (
    <p className='desc_text'>
      Free business listing at Mom n Pop Hub help you reach local customers and grow your business.
      If you are a local services provider, click below to check out the categories and
      sub-categories for business listings.
    </p>
  );
};
const Nightlife = () => {
  return (
    <p className='desc_text'>
      For Nigh Life services, Mom n Pop Hub offers the free business listing service. To make your
      business more profitable, choose a primary category from the list that describes your business
      as a whole.
    </p>
  );
};

const Professionalservices = () => {
  return (
    <p className='desc_text'>
      Free Business listing service at Mom n Pop Hub for marketing professional services. If you are
      a professional services provider, take a look at the comprehensive list of business categories
      for listing your business.
    </p>
  );
};

//////////////////////// ONLINE CATEGOREIS ///////////////////
const Shopify = () => {
  return (
    <p className='desc_text'>
      Are you a small business owner that run a online shopify business in USA. Visit Mom n Pop hub,
      and Signup today for free, We have 15 sub-categories available, click on the desired
      sub-category to list your business.
    </p>
  );
};
const Woocommerce = () => {
  return (
    <p className='desc_text'>
      We list the given below categories of Woocommerce online business on Mom n Pop Hub. Signup and
      list your woocommerce business for Free. We have 15 sub-categories available, click on the
      desired sub-category to see business listings.{' '}
    </p>
  );
};
const Wix = () => {
  return (
    <p className='desc_text'>
      A free business directory USA. Signup today for free, if you are the owner of a wix website
      and running an online eCommerce business. We have different categories available, click on the
      desired categories to see business listings.
    </p>
  );
};
const Bigcommerce = () => {
  return (
    <p className='desc_text'>
      Running a Big-commerce business? List your business on Mom n Pop Hub to take your small
      business to the next level. A free business directory USA. Amongst the 15 sub-categories
      available, click on the desired sub-category to see business listings.
    </p>
  );
};
const Independentwebsite = () => {
  return (
    <p className='desc_text'>
      To take your small business to the next level through an independent website, list your
      business on Mom n Pop Hub, Free Business Directory USA. Amongst the 15 sub-categories
      available, click on the desired sub-category to see business listings.
    </p>
  );
};
