import './about_contact.css';

import React from 'react';

const AboutContact = () => {
  return (
    <>
      <div className='part_block'>
        <div className='row custom_container'>
          <div className='about_contact_title'>
            <h2>Contact Us</h2>
          </div>
          <div className='about_contact_text'>
            Please feel free to send your questions to{' '}
            <a
              href="mailTo: contact@momnpophub.com.?Subject=I'd like to contact you."
              className='about_contact_link'
              style={{ color: '#FF7B25' }}
            >
              contact@momnpophub.com
            </a>
            . We love helping small businesses
          </div>
          <div className='about_contact_bottom download_icons_buttons'>
            {/* <InstallButton type='google'/>
                    <InstallButton type='app'/> */}
            <a href='https://play.google.com/store/apps/details?id=com.momnpophub'>
              <img src='./playstore.png' className='googlePlayStore' />
            </a>
            <a href='https://apps.apple.com/us/app/mom-n-pop-hub/id1494101666'>
              <img src='./applestore.png' className='applePlayStore ml-4' />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutContact;
