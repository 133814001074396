import './sign-up.css';

import React from 'react';

const SignUp = () => {
  return (
    <div className='container'>
      <div className='block'>
        <div className='d-flex flex-column align-items-center signup'>
          <h1 className='mb-5 signup_header'>
            List your small business on Mom n Pop Hub for free.
          </h1>
          <button className='signup_btn'>
            <a href='/free-business-invitation' className='signup_btn_link'>
              Signup for FREE
            </a>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
