import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import ReactQuillWrapper from '../components/ReactQuillWrapper';
import './articleForm.css';
import Button from '../components/Button';
import { Helmet } from 'react-helmet';
import imgSuccess from '../../../assets/images/blog/img-success.png';
import imgError from '../../../assets/images/blog/img-error.png';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { articleUpdateBody, createArticle } from '../../../api/blog';
import MobileCategory from '../components/Category/MobileCategory';
import ReCAPTCHA from 'react-google-recaptcha';
import { Form } from 'react-bootstrap';
import BreadcrumbsBlog from '../components/Breadcrumbs';

const ArticleForm = ({ userEditor = false }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [pathname]);

  const [quillValue, setQuillValue] = useState(userEditor?.description || '');
  const [firstName, setFirstName] = useState(userEditor?.author?.split(' ')[0] || '');
  const [lastName, setLastName] = useState(userEditor?.author?.split(' ').slice(1).join(' ') || '');
  const [titlePage, setTitlePage] = useState(userEditor?.seoPage?.titlePage || 'SEO title page');
  const [metaTitle, setMetaTitle] = useState(userEditor?.seoPage?.metaTitle || 'SEO meta title');
  const [metaDesc, setMetaDesc] = useState(userEditor?.seoPage?.metaDesc || 'SEO mate desc');
  const [email, setEmail] = useState(userEditor?.email_author || '');
  const [title, setTitle] = useState(userEditor?.title || '');
  const [smallDescription, setSmallDescription] = useState(userEditor?.smallDescription || '');
  const [privacyAccepted, setPrivacyAccepted] = useState(!!userEditor || false);
  const [categoryArticle, setCategoryArticle] = useState(userEditor?.category || '');
  const [subcategoryArticle, setSubcategoryArticle] = useState(userEditor?.subCategory || '');
  const [formErrors, setFormErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    title: false,
    quillValue: false,
    smallDescription: false,
    titlePage: false,
    metaTitle: false,
    metaDesc: false,
  });
  const showSuccessPopup = useRef(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [popupType, setPopupType] = useState('');
  const fileRef = useRef(userEditor?.imageArticle || '');
  const [robot, setRobot] = useState({ isRobot: true, showRobotErr: false });
  const [uploadedImage, setUploadedImage] = useState(userEditor?.imageArticle || null);

  const validateNotRobot = () => {
    if (robot.isRobot) {
      setRobot({ ...robot, showRobotErr: true });
      return;
    }
  };

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'firstName':
        setFirstName(value);
        break;
      case 'lastName':
        setLastName(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'title':
        setTitle(value);
        break;
      case 'smallDescription':
        setSmallDescription(value);
        break;
      case 'titlePage':
        setTitlePage(value);
        break;
      case 'metaTitle':
        setMetaTitle(value);
        break;
      case 'metaDesc':
        setMetaDesc(value);
        break;
      default:
        break;
    }
  }, []);

  const handlePrivacyAcceptedChange = useCallback((e) => {
    setPrivacyAccepted(e.target.checked);
  }, []);

  const handleQuillChange = useCallback((value) => {
    setQuillValue(value);
  }, []);

  const handleFileChange = useCallback((e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > 1000000) {
        alert('File size is too large. Please select a file less than 1MB.');
      } else if (!['image/jpg', 'image/jpeg', 'image/png'].includes(selectedFile.type)) {
        alert('Invalid file type. Please select a JPEG or PNG file.');
      } else {
        const reader = new FileReader();
        reader.onloadend = () => {
          fileRef.current = reader.result;
          setUploadedImage(reader.result);
        };
        reader.readAsDataURL(selectedFile);
      }
    }
  }, []);

  const isFormValid = useMemo(
    () =>
      Boolean(
        firstName &&
        lastName &&
        email &&
        title &&
        privacyAccepted &&
        smallDescription &&
        categoryArticle.length > 0 &&
        subcategoryArticle.length > 0 &&
        titlePage.length > 0 &&
        metaTitle.length > 0 &&
        metaDesc.length > 0 &&
        !robot.isRobot
      ),
    [
      firstName,
      lastName,
      email,
      title,
      privacyAccepted,
      smallDescription,
      categoryArticle,
      subcategoryArticle,
      titlePage,
      metaTitle,
      metaDesc,
      robot,
    ],
  );

  const validateForm = () => {
    const newFormErrors = {
      firstName: !firstName || !/^[A-Za-z]+$/.test(firstName),
      lastName: !lastName || !/^[A-Za-z]+$/.test(lastName),
      email: !email || !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(email),
      title: !title || !/^[A-Za-z0-9\s.,-]+$/.test(title),
      quillValue: !quillValue,
      smallDescription: !smallDescription || !/^[A-Za-z0-9\s.,-]+$/.test(smallDescription),
      categoryArticle: !categoryArticle,
      subcategoryArticle: !subcategoryArticle,
    };
    setFormErrors(newFormErrors);
    return Object.values(newFormErrors).every((error) => !error);
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const isValid = validateForm();
      if (isValid) {
        if (robot.isRobot && !userEditor) {
          return validateNotRobot();
        }
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          email: false,
        }));

        const data = {
          author: `${firstName.trim()} ${lastName.trim()}`,
          description: quillValue,
          email_author: email.trim(),
          category: categoryArticle,
          subCategory: subcategoryArticle,
          titlePage: titlePage.trim(),
          metaTitle: metaTitle.trim(),
          metaDesc: metaDesc.trim(),
          smallDescription: smallDescription.trim(),
        };
        if (fileRef.current) {
          data.imageArticle = fileRef.current
        }

        if (userEditor) {
          if(userEditor?.title !== title) {
             data.title = title.trim();
          }
        } else {
          data.title = title.trim();
        }
        try {
          const response = userEditor
            ? await articleUpdateBody(data, userEditor._id, userEditor.isPublished)
            : await createArticle(data);

          if (response.ok || !response.status) {
            showSuccessPopup.current = true;
            setPopupType('success');
            setPopupMessage('Your article proposal has been successfully submitted');
            console.log('Article submitted successfully');
          } else {
            showSuccessPopup.current = true;
            setPopupType('error');
            if (response.statusText === 'Payload Too Large') {
              console.error('Error submitting article:', response.statusText);
              return setPopupMessage(
                'The article contains too many images or these images have a large size. Please reduce the size of the images and try again.',
              );
            }
            const responseText = await response.text();
            if (responseText) {
              setPopupMessage(responseText);
              console.error('Error submitting article:', response.statusText);
            } else {
              setPopupMessage('An error occurred, please try again later');
              console.error('Error submitting article:', response.statusText);
            }
          }
        } catch (error) {
          showSuccessPopup.current = true;
          setPopupType('error');
          setPopupMessage('An error occurred, please try again later');
          console.error('Error submitting article:', error);
        }
      }
    },
    [
      isFormValid,
      firstName,
      lastName,
      email,
      title,
      quillValue,
      smallDescription,
      categoryArticle,
      subcategoryArticle,
      titlePage,
      metaTitle,
      metaDesc,
      robot,
    ],
  );

  useEffect(() => {
    if (showSuccessPopup.current) {
      const timer = setTimeout(() => {
        showSuccessPopup.current = false;
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, []);

  const handleClosePopup = useCallback(() => {
    showSuccessPopup.current = false;
  }, []);

  const handleCategoryClick = (categoryVal) => {
    setCategoryArticle(categoryVal.machine_name);
  };
  const handleSubcategoryClick = (categoryVal, subcategoryVal) => {
    setSubcategoryArticle(subcategoryVal.machine_name);
  };

  const history = useHistory();

  const handleBackClick = () => {
    history.push({
      pathname: '/blog'
    });
  };

  return (
    <>
      <Helmet>
        <title>Write For Us</title>
        <meta
          name='title'
          content='Write for Us - Submit Your Content Today - Mom n Pop Hub'
        ></meta>
        <meta
          name='description'
          content="Contribute your expertise and insights to Mom n Pop Hub's platform. Submit your article today and share your knowledge with the community!"
        ></meta>
        <meta
          name='keywords'
          content='Small business articles, Business development articles, Business growth tips, article formatting, article submission, blog article form'
        ></meta>
      </Helmet>
      <Button text='BACK' className={'articleDetails-button__back'} onClick={handleBackClick}>
        <svg
          width='7'
          height='11'
          viewBox='0 0 7 11'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M0.4375 5.12695C0.173828 5.39062 0.173828 5.83008 0.4375 6.12305L4.42188 10.1074C4.71484 10.3711 5.1543 10.3711 5.41797 10.1074L6.0918 9.43359C6.35547 9.14062 6.35547 8.70117 6.0918 8.4375L3.25 5.5957L6.0918 2.7832C6.35547 2.51953 6.35547 2.08008 6.0918 1.78711L5.41797 1.14258C5.1543 0.849609 4.71484 0.849609 4.42188 1.14258L0.4375 5.12695Z'
            fill='#787878'
          />
        </svg>
      </Button>
      <section className='form-section'>
        <div className='form-section__container'>
          <BreadcrumbsBlog pageName={'Submit Your Blog Post'} isBlogPage={true}/>
          <div className='form-section__header'>
            <h1 className='form-section__title'>
              {userEditor ? 'Edit article' : 'Submit Your Blog Post'}
            </h1>
            {!userEditor && (
              <p className='form-section__description'>
                Contribute to our blog and get featured among the top authors. Submit your article
                using the form below.
              </p>
            )}
          </div>
          {!showSuccessPopup.current && (
            <form className="form-section__form">
              <div className="form-section__input-container">
                <div className="form-section__input-container__row">
                  <label>
                    <span>
                      First Name<b>*</b>
                    </span>
                    <input
                      type="text"
                      className={`form-section__input ${
                        formErrors.firstName && 'form-section__input-error'
                      }`}
                      placeholder="First Name"
                      name="firstName"
                      value={firstName}
                      onChange={handleInputChange}
                      title="Only Latin letters are allowed"
                    />
                  </label>
                  <label>
                    <span>
                      Last Name<b>*</b>
                    </span>
                    <input
                      type="text"
                      className={`form-section__input ${
                        formErrors.lastName && 'form-section__input-error'
                      }`}
                      placeholder="Last Name"
                      name="lastName"
                      value={lastName}
                      onChange={handleInputChange}
                      title="Only Latin letters are allowed"
                    />
                  </label>
                </div>
                <label>
                  <span>
                    Email<b>*</b>
                  </span>
                  <input
                    type="email"
                    className={`form-section__input ${
                      formErrors.email && 'form-section__input-error'
                    }`}
                    placeholder="Email won't be published"
                    name="email"
                    value={email}
                    onChange={handleInputChange}
                    title="Only Latin letters, numbers, spaces, and certain symbols (., ,, -) are allowed"
                  />
                </label>
                <label>
                  <span>
                    Title of your blog article<b>*</b>
                  </span>
                  <input
                    type="text"
                    className={`form-section__input ${
                      formErrors.title && 'form-section__input-error'
                    }`}
                    placeholder="Title"
                    name="title"
                    value={title}
                    onChange={handleInputChange}
                    title="Only Latin letters, numbers, spaces, and certain symbols (., ,, -) are allowed"
                  />
                </label>

                {userEditor && (
                  <label>
                    <span>
                     title Page
                    </span>
                    <input
                      type="text"
                      className={`form-section__input ${
                        formErrors.titlePage && 'form-section__input-error'
                      }`}
                      placeholder="titlePage"
                      name="titlePage"
                      value={titlePage}
                      onChange={handleInputChange}
                    />
                  </label>
                )}
                {userEditor && (
                  <label>
                    <span>meta Title</span>
                    <input
                      type="text"
                      className={`form-section__input ${
                        formErrors.metaTitle && 'form-section__input-error'
                      }`}
                      placeholder="metaTitle"
                      name="metaTitle"
                      value={metaTitle}
                      onChange={handleInputChange}
                    />
                  </label>
                )}
                {userEditor && (
                  <label>
                    <span>meta Desc</span>
                    <input
                      type="text"
                      className={`form-section__input ${
                        formErrors.metaDesc && 'form-section__input-error'
                      }`}
                      placeholder="metaDesc"
                      name="metaDesc"
                      value={metaDesc}
                      onChange={handleInputChange}
                    />
                  </label>
                )}
                <div>
                  <span>
                    Select article category and subcategory<b>*</b>
                  </span>
                  <MobileCategory
                    filter={true}
                    className={'form-section__category'}
                    onCategoryClick={handleCategoryClick}
                    onSubcategoryClick={handleSubcategoryClick}
                    userEditor={userEditor}
                  />
                </div>
                <label>
                  <span>
                    Article Description - Limited to 130 Characters<b>*</b>
                  </span>
                  <textarea
                    className={`form-section__textarea__description ${
                      formErrors.smallDescription && 'form-section__input-error'
                    }`}
                    placeholder="Description article helps your articles SEO ranking"
                    name="smallDescription"
                    value={smallDescription}
                    onChange={handleInputChange}
                    maxLength={130}
                    title="Only Latin letters, numbers, spaces, and certain symbols (., ,, -) are allowed"
                  />
                </label>
              </div>
              <div
                className={`react-quill-container ${
                  formErrors.quillValue && 'react-quill-container_error'
                }`}
              >
                <span>
                  Article Body - Text, Image content of your blog<b>*</b>
                </span>
                <ReactQuillWrapper onChange={handleQuillChange} userEditor={quillValue} />
              </div>
              <div className="form-section__upload">
                <input
                  id="file-upload"
                  type="file"
                  className="form-section__file"
                  onChange={handleFileChange}
                />
                <div className="form-section__file-text">
                  <label htmlFor="file-upload" className="form-section__file-label">
                    Upload Main Picture
                  </label>
                  {uploadedImage && (
                    <img
                      src={imgSuccess}
                      alt="Success"
                      className="form-section__file-img"
                      style={{ marginLeft: '10px' }}
                    />
                  )}
                </div>
                <p>Max size 1 Mb. PNG, JPG, JPEG</p>
                {userEditor && uploadedImage && (
                  <img
                    src={fileRef.current}
                    alt="image"
                    style={{ marginTop: '20px', marginBottom: '20px' }}
                  />
                )}
              </div>
              {!userEditor && (
                <div className="form-section__row">
                  <input
                    type="checkbox"
                    className="form-section__checkbox"
                    checked={privacyAccepted}
                    onChange={handlePrivacyAcceptedChange}
                  />
                  <label className="form-section__label">
                    I accept <Link to="/privacy">Privacy Policy</Link>
                  </label>
                </div>
              )}
                <Form.Group>
                  <ReCAPTCHA
                    onChange={(value) => {
                      setRobot({
                        isRobot: false,
                        showRobotErr: false,
                      });
                    }}
                    onExpired={(expired) => {
                      setRobot({
                        isRobot: true,
                        showRobotErr: false,
                      });
                    }}
                    sitekey='6Lfea-IZAAAAAAygbpzANjWTq2jzMJNNRSdmidaO'
                  />
                  {robot.showRobotErr && <p style={{ color: 'red' }}>Verify you are not robot</p>}
                </Form.Group>
              <div className="form-section__button">
                <Button
                  text={userEditor ? 'UPDATE' : 'Submit'}
                  isDisabled={!isFormValid}
                  onClick={handleSubmit}
                />
              </div>
            </form>
          )}
          {showSuccessPopup.current && (
            <div className="article-fetch__success">
              <div className="article-fetch__success-title">
                <p>{popupMessage}</p>
                <img src={popupType === 'success' ? imgSuccess : imgError} />
              </div>
              <Link to={userEditor ? '/blog-admin-panel' : '/blog/'}>
                <Button
                  onClick={handleClosePopup}
                  text={userEditor ? 'Back to Admin Panel' : 'Back to Blog'}
                />
              </Link>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default ArticleForm;
