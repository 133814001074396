import './about-us-book.css';

import React from 'react';

const AboutUsBook = () => {
  return (
    <div className='container'>
      <div className='block'>
        <div className='d-flex flex-column align-items-center'>
          <h1 className='contact_us_header m-0'>Contact us</h1>
          <p className='contact_us_desc my-4 col-md-8 col-ls-8 col-xs-12 col-sm-12'>
            Please feel free to send your questions to{' '}
            <span className='contact_us_desc_email'>contact@momnpophub.com</span> or book a demo to
            learn about our platform.
          </p>
          <button className='book_demo_btn'>
            <a
              href='https://calendly.com/momnpophub/mom-n-pop-hub-demo'
              target='_blank'
              className='book_demo_btn_link'
            >
              Book Demo
            </a>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AboutUsBook;
