import './download-app.css';

import React from 'react';

import APPSTORE from '../../../assets/images/social/app.png';
import GOOGLEPLAY from '../../../assets/images/social/google-play.png';

const DownloadApp = () => {
  return (
    <div className='container'>
      <div className='block'>
        <div className='d-flex flex-column my-5 align-items-center signup'>
          <h1 className='mb-5 download_app_header'>
            Download our mobile app to find a small business around you.
          </h1>
          <div className='d-flex flex-row flex-wrap col-8 justify-content-around'>
            <button className='download_app  download_googleplay'>
              <a
                href='https://play.google.com/store/apps/details?id=com.momnpophub'
                target='_blank'
              >
                <img src={GOOGLEPLAY} width='196' height='58' />
              </a>
            </button>
            <button className='download_app'>
              <a href='https://apps.apple.com/us/app/mom-n-pop-hub/id1494101666' target='_blank'>
                <img src={APPSTORE} width='196' height='58' />
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadApp;
