const SubcategoryMeta = [
  {
    catname: 'auto-repair',
    titledesc:
      'Auto Repair business listing made simple with Mom n Pop Hub, List your store for free. Here are the list of all the Auto Repair businesses that have already registred with us.',
    metatitle: 'Auto Repair Small Physical Store List - Mom n Pop Hub',
    metadesc:
      'Auto Repair business listing made simple with Mom n Pop Hub, the ultimate resource helping small businesses develop a brand image for their business.Signup Now!',
  },
  {
    catname: 'auto-detailing',
    titledesc:
      'With Mom n Pop Hub, list and grow your auto detailing for free. Here are the list of all the auto detailing businesses that have already registred with us.',
    metatitle: 'Auto Detailing Small Physical Store List - Mom n Pop Hub',
    metadesc:
      "Grow your auto detailing store business by listing it on Mom n Pop Hub. It's User-friendly and free business listing service site. Sign up today for free listing!",
  },
  {
    catname: 'body-shops',
    titledesc:
      'List your body shops for free on Mom n Pop Hub. Here are the list of all the relatable businesses that have already registred with us.',
    metatitle: 'List Your Body Shops Physical Store with Mom n Pop Hub',
    metadesc:
      'The best ever body shop marketing strategy to build a strong base of customers is listing it on Mom N Pop Hub.  Visit us for business signup and Grow your business.',
  },
  {
    catname: 'car-wash',
    titledesc:
      "Are you owner of  car wash and wants to list your business for more profit then Mom n Pop Hub is the best option for you. List your business here without any fee. Click below to see other's listing. ",
    metatitle: 'Add Your Car Wash Store with Mom n Pop Hub Signup today',
    metadesc:
      'List your car wash business on Mom n Pop Hub. This is a free business listing platform. Visit us today for free signup!',
  },
  {
    catname: 'car-dealers',
    titledesc:
      'Make your Car Dealers business grow more. Register today on Mom n Pop Hub to list your business free of cost. Here are the list of other businesses that are already signed up with us.',
    metatitle: 'Physical Store for Car Dealers with Mom n Pop Hub free signup',
    metadesc:
      'Easy and free business listing for car dealers at Mom n Pop Hub!  Sign up today on our website and discover how we can help your business to grow with good ROI.',
  },
  {
    catname: 'oil-change',
    titledesc:
      "Have a oil change business? Sign up for a listing with Mom n Pop Hub today and see your organization's profile rise in the blink of an eye! also see others relatable listing in given list.  ",
    metatitle: 'Find Oil Change Store Near me - Mom n Pop Hub Signup Now!',
    metadesc:
      'At Mom n Pop Hub, listing oil change business  goes a long way towards gaining potential clients. This is a free business listing service. Visit us for Signup Now!',
  },
  {
    catname: 'parking',
    titledesc:
      'Providing parking services and wants more coustomers? Then list your business on Mom n Pop Hub and see your business growing in no time. Here are the list of all the Auto Repair businesses that have already registred with us.',
    metatitle: 'Find Parking Physical Store Near me - Mom n Pop Hub',
    metadesc:
      'Promote your allocated parking business with listing it free on Mom N Pop Hub! Reach new customers with a free signup!',
  },
  {
    catname: 'towing',
    titledesc:
      'Make your towing business listed in best business listing website for free. List your business on Mom n Pop Hub to get more growth in your coustomers.',
    metatitle: 'Small Physical Store List - Towing - Mom n Pop Hub',
    metadesc:
      'Maximize your towing business\'s potential by listing it on Mom n Pop Hub. Our free platform empowers small businesses. Join now and start for free!',
  },
  {
    catname: 'car-paint',
    titledesc:
      'Mom n Pop Hub provides free listing space for your car paint store. List your business for free. Here are the list of other businesses that are already signed up with us.',
    metatitle: 'Physical Store for Car Paint - Mom n Pop Hub',
    metadesc:
      'Grow your professional car paint business with Mom n Pop Hub. Visit us today and list your business for free. Signup Now!',
  },
  {
    catname: 'auto-security',
    titledesc:
      "Are you owner of auto security firm and wants to list your business for more profit then Mom n Pop Hub is the best option for you. List your business here without any fee. Click below to see other's listing. ",
    metatitle: 'Auto Security Physical Stores Near me - Mom n Pop Hub',
    metadesc:
      'The free business listing that help you sell your auto security systems and services at Mom n Pop Hub! Visit us for free business signup!',
  },
  {
    catname: 'gas-stations',
    titledesc:
      'Gas stations business listing made simple with Mom n Pop Hub, List your store for free. Here are the list of all the Gas stations businesses that have already registred with us.',
    metatitle: 'Gas Stations Physical store Near me - Mon n Pop Hub',
    metadesc:
      'List your Gas Stations business on Mom n Pop Hub. This user-friendly platform will help you to get more leads. Add your business now!',
  },
  {
    catname: 'registration-services',
    titledesc:
      'With Mom n Pop Hub, list and grow your vehicle registration services for free. Here are the list of all the lists of businesses that have already registred with us.',
    metatitle: 'Add Your physical store - Vehicle Registration Services  | Mon n Pop Hub',
    metadesc:
      'List your vehicle registration services to streamline the entire procedure with Mom n Pop Hub. No hidden costs for business sign up!',
  },
  {
    catname: 'used-car-dealers',
    titledesc:
      'List your Used Car Dealers store for free on Mom n Pop Hub. Here are the list of all the relatable businesses that have already registred with us.',
    metatitle: 'List Your Used car dealers Store Near me - Mom n Pop Hub',
    metadesc:
      'Free business listing for used car dealers at Mom n Pop Hub, the ultimate platform to grow your business. Sign up for free today.',
  },
  {
    catname: 'motorcycle-dealers',
    titledesc:
      "Are you motorcycle dealers and wants to list your business for more profit then Mom n Pop Hub is the best option for you. List your business with us for free. Click below to see other's listed listing. ",
    metatitle: 'List Your Motorcycle Dealers Physical Store with Mom n Pop Hub',
    metadesc:
      'Free business listing for motorcycle dealers at Mom n Pop Hub to get instant customers! Visit us for a free sign up!',
  },
  {
    catname: 'motorcycle-repair',
    titledesc:
      'Grow your motorcycle repair business without any fee by listing your business on Mom n Pop Hub.  Here is the list of businsses that are already signup with us.',
    metatitle: 'Add Motorcycle Repair store For Free with Mom n Pop Hub',
    metadesc:
      'Get more leads by listing your motorcycle repair store on Mom n Pop Hub. Visit us and Sign up for free today.',
  },
  {
    catname: 'atv-rentals',
    titledesc:
      'For ATV rentals and tours, Mom n Pop Hub offers free business signup. Our free registration helps boost your business position in local listings. Take a look at similar businesses already registered with us!',
    metatitle: 'Physical Store for ATV Rentals - Mon n Pop Hub - Visit Today',
    metadesc:
      'List your Physical Store of ATV Rentals with Mom n Pop Hub. Grow your business,  customer leads and ROI. Visit us and Signup for free today! ',
  },
  {
    catname: 'airsoft',
    titledesc:
      'Grow your Airsoft firm without any fee by listing your business on Mom n Pop Hub. Here is the list of businesses that are already signup with us.. ',
    metatitle: 'List your Airsoft Physical Store - Mom n Pop Hub -  Signup today',
    metadesc:
      'Get more leads by listing your Airsoft physical store on Mom n Pop Hub. This user-friendly platform will help you to get more leads. Add your business now!',
  },
  {
    catname: 'badminton',
    titledesc:
      'Make your Badminton service business listed in the best business listing website for free. List your business on Mom n Pop Hub to get more growth in your customers.',
    metatitle: 'Business Listing website for Badminton Services - Mom n Pop Hub ',
    metadesc:
      'List your badminton services with Mom n Pop Hub and grow your business with us.Get more collabration with new customers.Visit us and Signup for free today.',
  },
  {
    catname: 'basketball-courts',
    titledesc:
      'If you are a Basketball Courts service provider and want to expand your business then Mom n Pop is the best option. visit us today and signup your business with Mom n Pop hub for free.',
    metatitle: 'Submit Your Basketball Courts on Mom n Pop Hub - Signup today',
    metadesc:
      'Signup today on Mom n Pop Hub and submit your basketball courts business. A free listing to promote small businesses! Sign up now!',
  },
  {
    catname: 'bike-rentals',
    titledesc:
      "Are you owner of Bike Rentals and want to list your business for more customers then Mom n Pop Hub is the best option for you.List your business here without any fee. Click below to see other's listings",
    metatitle: 'List Your Bike Rentals Services - Mom n Pop Hub -  Signup today',
    metadesc:
      'List Your Bike Rental Services on Mom n Pop Hub. This will help you attract and retain more customers. Visit us for free business signup now!',
  },
  {
    catname: 'bowling',
    titledesc:
      'Bowling business listing made simple with Mom n Pop Hub, List your store for free. Here are the list of all the Bowling businesses that have already registered with us.',
    metatitle: 'Add Your Bowling Courts on Mom n Pop Hub - Signup Now!',
    metadesc:
      'If you want to grow your business in your city, add your bowling court on Mom n Pop Hub.  Visit us today and get your business listed for free.',
  },
  {
    catname: 'bungee-junping',
    titledesc:
      'Grow your Bungee Jumping business without any fee by listing your business on Mom n Pop Hub. Here is the list of businesses that are already signup with us.',
    metatitle: 'List Your Bungee Jumping Physical Store with Mom n Pop Hub',
    metadesc:
      "Don't wait and list your Bungee Jumping store on Mom n Pop Hub to attract more local customers. Visit our website now and signup for free!",
  },
  {
    catname: 'climbing',
    titledesc:
      'With Mom n Pop Hub, list and grow your Climbing business for free. Here are the list of all the Climbing business businesses that have already registered with us',
    metatitle: 'List Your Climbing Store on Mom n Pop Hub',
    metadesc:
      'Grow your climbing business and gain new customers by listing your business on Mom n Pop Hub. Signup for free today!',
  },
  {
    catname: 'free-diving',
    titledesc:
      'Providing Free Diving and wants more customers? Then list your business on Mom n Pop Hub and see your business growing in no time. Here are the list of all the Free Diving businesses that have already registered with us.',
    metatitle: 'Free Diving Small Physical Store List - Mom n Pop Hub',
    metadesc:
      'Add your free diving business on Mom n Pop Hub. This is a place where small business owners can get more exposure and more sales. Signup now!',
  },
  {
    catname: 'scuba-diving',
    titledesc:
      'Scuba Diving business listing made simple with Mom n Pop Hub, List your store for free. Here are the list of all the Scuba Diving businesses that have already registred with us.',
    metatitle: 'Submit your Scuba Diving with Us - Free Sign Up Today',
    metadesc:
      'If you are running a small scuba diving business, Mom n Pop Hub is a great place to submit your business and attract new customers. Add your business for free!',
  },
  {
    catname: 'fitness-&-instruction',
    titledesc:
      'If you are a Fitness & Instruction service center provider and want to expand your business then Mom n Pop is the best option. visit us today and signup your business with Mom n Pop hub for free.',
    metatitle: 'Add Your Fitness & Instruction Training Center - Mom n Pop Hub',
    metadesc:
      'Add your fitness & instruction training center on Momnpophub.com and reach more customers. Visit us for free business signup!',
  },
  {
    catname: 'cardio-classes',
    titledesc:
      "Are you owner of Cardio Classes and wants to list your business for more profit then Mom n Pop Hub is the best option for you. List your business here without any fee. Click below to see other's listing.",
    metatitle: 'List Your Cardio Classes Center - Mom n Pop Hub - Signup Now!',
    metadesc:
      "If you're running cardio classes, Mom n Pop Hub is a great place to submit your business. It is a free business listing platform. Sign up now!",
  },
  {
    catname: 'dance-studios',
    titledesc:
      'Grow your Dance Studios business without any fee by listing your business on Mom n Pop Hub. Here is the list of businesses that are already signup with us.',
    metatitle: 'Find & List Dance Studios Near Me - Mom n Pop Hub - Signup Now!',
    metadesc:
      'Find top rated dance school near me and list your own dance studio on Mom n Pop Hub. Our platform helps you connect with potential customers.',
  },
  {
    categoryname: 'health-&-fitness',
    catname: 'gyms',
    titledesc:
      'Make your Gyms service center listed in the best business listing website for free. List your business on Mom n Pop Hub to get more growth in your customers.',
    metatitle: 'Add Your Gym Center with Us - Free Sign Up Today',
    metadesc:
      'Mom n Pop Hub is free, easy and fast to create your own Gym center listing. You can also search for Gym Centers in your area. Sign up now!',
  },
  {
    categoryname: 'active-life',
    catname: 'gyms',
    titledesc:
        'Make your Gyms service center listed in the best business listing website for free. List your business on Mom n Pop Hub to get more growth in your customers.',
    metatitle: ' Add Your Gym to Mom n Pop Hub - Free Sign Up Today!',
    metadesc:
        'Create your own Gym center listing effortlessly on Mom n Pop Hub. It\'s free, easy, and quick. Sign up now to find Gym Centers in your area too!',
  },
  {
    catname: 'karate',
    titledesc:
      'Providing Karate and wants more customers? Then list your business on Mom n Pop Hub and see your business growing in no time. Here are the list of all the Karate businesses that have already registered with us',
    metatitle: 'Find & List Karate Classes on Mom n Pop Hub - Signup now!',
    metadesc:
      'Add your karate classes for free on Mom n Pop Hub to reach out to more potential customers. Visit our website for free business signup!',
  },
  {
    catname: 'self-defence-classes',
    titledesc:
      'If you are a Self-defense Classes provider and want to expand your business then Mom n Pop is the best option. visit us today and signup your business with Mom n Pop hub for free...',
    metatitle: 'Self-defense Classes Near Me - Mom n Pop Hub',
    metadesc:
      'Add your self-defense classes on Mom n Pop Hub and attract  more potential local audience for free. Visit us today for free business signup.',
  },
  {
    catname: 'yoga',
    titledesc:
      'List your Yoga center for free on Mom n Pop Hub. Here are the list of all the relatable businesses that have already registred with us.',
    metatitle: 'Business Listing for Yoga - Mom n Pop hub',
    metadesc:
      'Join Mom n Pop Hub,  to promote and grow your business.  The platform gives a free listing service to promote small businesses! Signup today',
  },
  {
    catname: 'gymnastics',
    titledesc:
      "You can list your Gymnastics business on Mom n Pop Hub if you're looking for new customers and are a  Gymnastics center owner. There is no charge to list your business on this site. Listed below are listings from other people.",
    metatitle: 'Gymnastics Stores Near Me - Mom n Pop hub',
    metadesc:
      'If you are looking for gymnastics stores near me, then Mom n Pop Hub is the right place to visit. Here, you will find all the local listed stores near you. ',
  },
  {
    catname: 'hiking',
    titledesc:
      'Hiking business listing made simple with Mom n Pop Hub, List your store for free. Here are the list of all the Hiking businesses that have already registred with  us.',
    metatitle: 'Business Listing Website for Hiking - Mom n Pop Hub',
    metadesc:
      'List your hiking business on Mom n Pop Hub for free. This is a user-friendly platform for small businesses. Visit us for free business signup now!',
  },
  {
    catname: 'paintball',
    titledesc:
      'With Mom n Pop Hub, list and grow your Paintball business for free. Here are the list of all the Paintball business businesses that have already registered with us.',
    metatitle: 'List Your Paintball Business Locally - Mom n Pop hub',
    metadesc:
      'Add your Paintball business on Mom n Pop Hub and grow your business locally. Visit us today and list your business for free',
  },
  {
    catname: 'skydiving',
    titledesc:
      'Grow your Skydiving center without any fee by listing your business on Mom n Pop Hub. Here is the list of businesses that are already signup with us..',
    metatitle: 'Business Listing for Skydiving - Mom n Pop Hub - Signup now!',
    metadesc:
      'Mom n Pop Hub is a business listing platform that will boost your Skydiving business. Visit us today and list your business for free. ',
  },
  {
    catname: 'swimming-pools',
    titledesc:
      'Providing Swimming Pool service and wants more customers? Then list your business on Mom n Pop Hub and see your business growing in no time. Here are the list of all the Swimming Pool businesses that have already registered with us',
    metatitle: 'List Your Swimming Pools Services with Us - Free Signup Today',
    metadesc:
      'List your swimming pool services on Momnpophub.com today and reach new customers. Visit us today and add your business for free.',
  },
  {
    catname: 'arcades',
    titledesc:
      'Register your arcade business to enhance public entertainment with Mom n Pop Hub. Provide your business details and get started with registering your business with us!',
    metatitle: 'Find Arcades store Near me with Mom n pop Hub for free Signup',
    metadesc:
      'List your arcade store on Mom n Pop Hub to reach out to  more potential audience. Visit our website for free business sign-up!',
  },
  {
    catname: 'art-galleries',
    titledesc:
      "For Art Galleries owners, Mom n Pop Hub is your best bet for getting your business out there. It's completely free to list your business here. Listed below are listings from other businesses.",
    metatitle: 'Add Your Art Galleries store locally with Mom n Pop Hub',
    metadesc:
      'Add your art galleries on Mom n Pop Hub for free to reach out to more local customers. Visit our website for free business sign-up!',
  },
  {
    catname: 'casinos',
    titledesc:
      'Mom n Pop Hub is the best place for the owner of Casino to list their business and attract more customers. There is no charge to list your business on this site. Listed below are listings from other people.',
    metatitle: 'List Your Casinos with Mom n Pop Hub - Casinos Website List',
    metadesc:
      'Add your casinos on Mom n Pop Hub and attract more local customers. If you want to list your business for free, visit our website now!',
  },
  {
    catname: 'cinema',
    titledesc:
      "You can list your Cinema on Mom n Pop Hub if you're looking for new customers and are a Cinema owner. There is no charge to list your business on this site. Listed below are listings from other people.",
    metatitle: 'Browse the list of cinema near you for free - Mon n Pop hub',
    metadesc:
      'List your cinemas on Mom n Pop Hub to reach out to a more potential local target audience. Visit our website for free business sign-up!',
  },
  {
    catname: 'drive-in-theater',
    titledesc:
      'Drive-In Theater owners who want to list their business on Mom n Pop Hub for the benefit of more customers should do so. There is no charge to list your business on this site. Listed below are listings from other people.',
    metatitle: 'List your Drive-In Theater for free - Mom n Pop hub',
    metadesc:
      'Got a drive-in theatre? Then list your theatre on Mom n Pop Hub to reach out to a more potential target audience. Visit us and sign-up for free!',
  },
  {
    catname: 'haunted-houses',
    titledesc:
      'List your Haunted Houses store for free with Mom n Pop Hub. Here are the list of all the relatable businesses that have already registred with us.',
    metatitle: 'Add a Haunted Houses Near You - Mom n Pop hub',
    metadesc:
      "Don't wait and list your cool haunted house on Mom n Pop Hub to attract more local target audiences. Visit us and sign-up for free!",
  },
  {
    catname: 'art-museums',
    titledesc:
      "Are you owner of  Art Museums and wants to list your business for more visibility then Mom n Pop Hub is the best option for you. List your business here without any fee. Click below to see other's listing. ",
    metatitle: 'Free Signup - List Your Art Museums Store With Mom n Pop Hub',
    metadesc:
      'Add your art museums on Mom n Pop Hub for free to reach out to attract and retain more customers. Visit us now for free business sign-up!',
  },
  {
    catname: 'opera-&-ballet',
    titledesc:
      'Make your Opera & Ballet business grow more. Register today on Mom n Pop Hub to list your business free of cost. Here are the list of other businesses that are already signed up with us.',
    metatitle: 'List your Opera & Ballet Store With Mom n Pop Hub - Signup now!',
    metadesc:
      'List your classy opera & ballet for free on Mom n Pop Hub to attract more local customers. Visit us for business sign-up!',
  },
  {
    catname: 'social-clubs',
    titledesc:
      "Have a Social Clubs business? Sign up for a listing with Mom n Pop Hub today and see your organization's profile rise in the blink of an eye! also see others relatable listing in given list.  ",
    metatitle: 'List Your Social Clubs Locally With Mom n Pop Hub - Free Signup',
    metadesc:
      'List your local clubs on Mom n Pop Hub to attract more customers.  This is a user-friendly business listing platform. Visit us for free small business sign-up!',
  },
  {
    catname: 'sports-betting',
    titledesc:
      'Providing sports betting services and wants more coustomers? Then list your business on Mom n Pop Hub and see your business growing in no time. Here are the list of all the sports betting businesses that have already registred with us.',
    metatitle: 'Add Sports Betting store For free - Mon n Pop Hub',
    metadesc:
      'Add your sports betting store on Mom n Pop Hub for free to reach out to more customers. Visit us for free business sign-up!',
  },
  {
    catname: 'astrologers',
    titledesc:
      'Astrologers business listing made simple with Mom n Pop Hub, List your store for free. Here are the list of all the Astrologers businesses that have already registred with us.',
    metatitle: 'Are you Astrologers? Add your store with Mom n Pop hub',
    metadesc:
      'If you are an astrologer then add your service on Mom n Pop Hub to reach out to more potential target audience. Visit us for free business sign-up!',
  },
  {
    catname: 'virtual-reality-centers',
    titledesc:
      'With Mom n Pop Hub, list and grow your Virtual Reality Centers for free. Here are the list of all the  businesses that have already registred with us.',
    metatitle: 'Add your Virtual Reality Centers locally - Mom n Pop Hub',
    metadesc:
      'Add your fabulous virtual reality center on Mom n Pop Hub to reach out to more potential target customers. Visit us and sign-up for free!',
  },
  {
    catname: 'barbers',
    titledesc:
      'For professional barbers, free business registration at Mom n Pop Hub. Easy and free of cost service! Simply submit your business information to register your business with us!',
    metatitle: 'List Your Barbers Shop on Mom n Pop Hub For Free',
    metadesc:
      'Mom n Pop Hub is a business listing website for local shops to get more leads and grow sales. List your Barbers shops free. Signup now!',
  },
  {
    catname: 'cosmetics-&-beauty-supply',
    titledesc:
      'Are you cosmetics & beauty supplier, free business registration at Mom n Pop Hub. Easy and free of cost service! Simply submit your business information to register your business with us!',
    metatitle: 'Add Your Cosmetics & Beauty Supply Business - Mom n Pop Hub',
    metadesc:
      'List your Cosmetics & Beauty Supply business today on Momnpophub.com. Our listing platform is free and easy to use. Visit our website for more information!',
  },
  {
    catname: 'hair-extensions',
    titledesc:
      'With Mom n Pop Hub, list and grow your Hair Extensions Centers for free. Here are the list of all the  businesses that have already registred with us.',
    metatitle: 'Submit Your Hair Extensions Business on Mom n Pop Hub',
    metadesc:
      'Submit your Hair Extensions business on Mom n Pop Hub and make it easier for customers to find you! Visit us for a signup now!',
  },
  {
    catname: 'hair-loss-centers',
    titledesc:
      'For business owners who want to promote their Hair Loss Centers, Mom n Pop Hub is the best option. No charge to list your business with us. Here are the list of all the registration services businesses that have already registred with us.',
    metatitle: 'Business Listing Website for Hair Loss Centers - Mom n Pop Hub',
    metadesc:
      'Add your hair loss centers on Mom n Pop Hub and reach more customers. Visit us for free business signup now!',
  },
  {
    catname: 'hair-removal',
    titledesc:
      'Is your business a Hair Removal Salon? If so, Mom n Pop Hub is the best place for you to list your business so more people can find it. List your business here for free. Here are the list of all the registration services businesses that have already registred with us.',
    metatitle: 'List Your Hair Removal Business Free on Mom n Pop Hub',
    metadesc:
      'List your hair removal business on Momnpophub to attract more local target audiences. Visit us today and get your business listed for free.',
  },
  {
    catname: 'waxing',
    titledesc:
      "Mom n Pop Hub is the best place to list your waxing salon if you want to attract more customers. You don't have to pay to have your business listed here. To see other listings, click the link below.",
    metatitle: 'Free Business Listing Website for Waxing - Mom n Pop Hub',
    metadesc:
      'List your waxing business on Mom n Pop Hub to attract the maximum number of local target customers. Visit our website for more information and signup now!',
  },
  {
    catname: 'hair-stylists',
    titledesc:
      'For Hair Stylists Salon owners, Mom n Pop Hub is the best place to list your business for more customers. You can list your business here for free. Here are the list of all the registration services businesses that have already registred with us.',
    metatitle: 'Free Listing for Hair Stylists - Mom n Pop Hub',
    metadesc:
      'If you are hair stylist and want to grow your business, visit Momnpophub.com to list your business. Our listing platform is free and easy to use. Signup now!',
  },
  {
    catname: 'hot-springs',
    titledesc:
      "Hot Springs shop owners: Mom n Pop Hub is the best place for you to list your business. There is no charge to list your business here. In the box below, you can see other people's listings",
    metatitle: 'Add Hot Springs Business Listing - Mom n Pop Hub',
    metadesc:
      'Add hot springs center on Mom n Pop Hub for free to reach out to maximum target customers. Visit our website and signup today for free!',
  },
  {
    catname: 'makeup-artists',
    titledesc:
      'For those who own Makeup Artists salons, Mom n Pop Hub is the best place to advertise your business. Post a free ad for your business here. Here are the list of all the registration services businesses that have already registred with us.',
    metatitle: 'Get More Leads by Listing Your Business on Mom n Pop Hub',
    metadesc:
      'Add your gorgeous makeup artist business on Mom n Pop Hub for free to reach out to more potential customers. Visit us to signup now!',
  },
  {
    catname: 'massage',
    titledesc:
      'You own a Massage Centers shop and want to get more customers by listing your business. Mom n Pop Hub is the best choice for you. List your business here for free. Here are the list of all the registration services businesses that have already registred with us.',
    metatitle: 'List Your Massage Business on Mom n Pop Hub For Free',
    metadesc:
      "Got a massage business? Don't wait & list your business on Mom n Pop Hub for free to target a more potential target audience. Visit us today to signup for free!",
  },
  {
    catname: 'nail-salons',
    titledesc:
      'The Mom n Pop Hub is the best place for you if you own a nail salon and want to get more customers to see it. You can list your business here for free. To see what other people have for sale, click the button below.',
    metatitle: 'Free Nail Salons Business Listing - Mom n Pop Hub',
    metadesc:
      'List your special nail salon business on Mom n Pop Hub for free to reach out to more potential audiences. No hidden costs for business sign up!',
  },
  {
    catname: 'piercing',
    titledesc:
      "The best place to list your piercing shop on Mom n Pop Hub is if you're the owner of one already. You can list your business here for free. Here are the list of all the registration services businesses that have already registred with us.",
    metatitle: 'Free Business Listing Website for Piercing - Mom n Pop Hub',
    metadesc:
      'Submit your piercing business on Mom n Pop Hub and make it easier for customers to find you! Visit us now for free signup!',
  },
  {
    catname: 'skin-care',
    titledesc:
      "Are you the owner of a Skin Care business looking to expand your customer base? If so, Mom n Pop Hub is the best option for you. There is no cost to list your business here. To view other people's listings, please click the button below.",
    metatitle: 'Add Skin Care Business on Mom n Pop Hub - Free Business Signup!',
    metadesc:
      'Add your skincare business on Mom n Pop Hub for free to reach out to a more potential target audience! No hidden costs for business sign up!',
  },
  {
    catname: 'tattoo',
    titledesc:
      "If you are the owner of a tattoo shop and want to list your business in order to attract more customers, then Mom n Pop Hub is the best choice for you. There is no charge to list your business on this page. Other people's listings can be found by clicking on the links below.",
    metatitle: 'Promote Your Tattoo Business - Mom n Pop Hub - Signup Now!',
    metadesc:
      "Got a cool tattoo business? Then don't wait and add it on Mom n Pop Hub for free to attract more target customers! Visit us to signup now!",
  },
  {
    catname: 'teeth-whitening',
    titledesc:
      'In order to attract more customers to your Teeth Whitening business, Mom n Pop Hub is the best place to list it. No fee to list your business here. See more listings below.',
    metatitle: 'Business Listing Website for Teeth Whitening - Mom n Pop Hub',
    metadesc:
      'List your teeth whitening service on Mom n Pop Hub to reach out to more target customers.  List your business for free!',
  },
  {
    catname: 'education',
    titledesc:
      "List your education institute If you are the owner of the education business, Mom n Pop Hub is the best option for you. There is no cost to list your business here. To view other people's listings, please click the button below.",
    metatitle: 'List Your Education Business For Free - Mom n Pop Hub',
    metadesc:
      'List your education institution on Mom n Pop Hub to grow your business. This is a free business listing platform. Visit us today for free signup!',
  },
  {
    catname: 'adult-education',
    titledesc:
      'Free listing for adult education business at Mom n Pop Hub! Register directly and receive exclusive benefits. See our list below for ideas or to register your adult business with us!',
    metatitle: 'Add Your Adult education Center near me - Mom n Pop Hub ',
    metadesc:
      'Easy and free business listing for adult education at Mom n Pop Hub!  Sign up today on with us and discover how we can help your business to grow with good ROI.',
  },
  {
    catname: 'art-classes',
    titledesc:
      'Does your Art Classes business need more customers? If so, Mom n Pop Hub is your best bet. You can list your business here for free. To see other listings, click below.',
    metatitle: 'Free lisitng for Art Classes - Mom n Pop Hub - Free Sign Up Today',
    metadesc:
      'At Mom n Pop Hub, listing art classes business goes a long way towards gaining potential clients. This is a free business listing service. Visit us for Signup Now!',
  },
  {
    catname: 'college-counseling',
    titledesc:
      'For those who own a college counselling business, Mom n Pop Hub is the best option for promoting your business to new customers. You can list your business here for free. Here are the list of all the registration services businesses that have already registred with us.',
    metatitle: 'List your College Counseling business For Free on Mom n Pop Hub',
    metadesc:
      'List your College Counseling business on Momnpophub. This is a free business listing platform. Visit us today and add your business for free.',
  },
  {
    catname: 'colleges-&-universities',
    titledesc:
      "Want to get more customers? Then Mom n Pop Hub is the best place for you if you're in the Colleges & Universities business? You can list your business here for free. Here are the list of all the registration services businesses that have already registred with us.",
    metatitle: 'Add Your Colleges & Universities near me - Mom n Pop Hub ',
    metadesc:
      'Give your towing business a better boost with listing it on Mom n Pop Hub. This is a no-cost small business listing platform. Sign up today for free to get started!',
  },
  {
    catname: 'elementary-schools',
    titledesc:
      "When it comes to advertising your elementary school, Mom n Pop Hub is the best option. It's completely free to list your business on this site. Here are the list of all the registration services businesses that have already registred with us.",
    metatitle: 'Free lisitng for Elementary Schools with Mom n Pop Hub ',
    metadesc:
      'Grow your professional Elementary Schools business with Mom n Pop Hub. Visit us today and list your business for free. Signup Now!',
  },
  {
    catname: 'middle-schools-&-high-schools',
    titledesc:
      'For Middle and High School business owners looking to expand their students base, Mom n Pop Hub is the best place to list their business. You can list your business here for free. Here are the list of all the registration services businesses that have already registred with us.',
    metatitle: 'Submit your Middle Schools & High Schools on Mom n Pop Hub ',
    metadesc:
      'Add your Middle Schools & High Schools on Mom n Pop Hub! Our listing platform is free and easy to use. Visit us for free business signup!',
  },
  {
    catname: 'private-tutors',
    titledesc:
      'In order to get more customers for your Private Tutors centre, you should list it on Mom n Pop Hub. There is no charge to list your business on this site. To see other listings, click the link below.',
    metatitle: 'List your Private Tutors Center with Mom n Pop Hub - Signup now!',
    metadesc:
      'List your Private Tutors business on Mom n Pop Hub. This user-friendly platform will help you to get more leads. Add your business now!',
  },
  {
    catname: 'special-education',
    titledesc:
      'Are you the owner of a special learning center and want to get more customers? Then Mom n Pop Hub is the best place for you to list your business. List your business here for free. Here are the list of all the registration services businesses that have already registred with us.',
    metatitle: 'Free Signup for your Special Education center on Mom n Pop Hub',
    metadesc:
      'List your special education center to streamline the entire procedure with Mom n Pop Hub. No hidden costs for business sign up!',
  },
  {
    catname: 'test-preparation',
    titledesc:
      "Is your Test Preparation business in need of more customers? If so, Mom n Pop Hub is your best option. It's completely free to list your business on our website. Here are the list of all the registration services businesses that have already registred with us.",
    metatitle: 'List your Test Preparation Classes center on Mom n Pop Hub',
    metadesc:
      'Free business listing for test preparation at Mom n Pop Hub, the ultimate platform to grow your business. Sign up for free today.',
  },
  {
    catname: 'tutoring-centers',
    titledesc:
      'Owners of tutoring centres who want to list their business on Mom n Pop Hub to reach a wider audience will find that it is the best choice. You can list your business here for free. Here are the list of all the registration services businesses that have already registred with us.',
    metatitle: 'Submit Your Tutoring Centers Classes on Mom n Pop Hub',
    metadesc:
      'Free business listing for Tutoring Centers at Mom n Pop Hub to get instant customers! Visit us for a free sign up!',
  },
  {
    catname: 'driving-schools',
    titledesc:
      "Mom n Pop Hub is the best place to list your driving school if you are looking to attract more customers. It's completely free to list your business here. Here are the list of all the registration services businesses that have already registred with us.",
    metatitle: 'Free Signup - List Your Driving Schools store on Mom n Pop Hub',
    metadesc:
      'Get more leads by listing your Driving Schools on Mom n Pop Hub. Visit us and Sign up for free today.',
  },
  {
    catname: 'firearm-training',
    titledesc:
      'When it comes to advertising your Firearm Training Center, Mom n Pop Hub is the best place to start. There is no charge to list your business on this site. To see other listings, click the link below.',
    metatitle: 'Add Your Firearm Training Center with Mom n Pop Hub Signup now!',
    metadesc:
      'Free business listing for Firearm Training at Mom n Pop Hub, the ultimate platform to grow your business. Sign up for free today.',
  },
  {
    catname: 'cooking-schools',
    titledesc:
      'Want to get more customers for your Cooking Schools? Then list your business for free on Mom n Pop Hub today. There is no charge to list your business on this site. To see other listings, click the link below.',
    metatitle: 'Free Business Signup for Cooking Schools - Mom n Pop Hub',
    metadesc:
      'List your Cooking Schools on Mom n Pop Hub! Our listing platform is free and easy to use. Add your business today!',
  },
  {
    catname: 'home-healthcare',
    titledesc:
      'Register your home healthcare service for business profile with Mom n Pop Hub. Reach more customers with our free of cost service. Follow the simple instructions to register!',
    metatitle: 'List your Home Healthcare for free - Mom n Pop Hub',
    metadesc:
      'List your Home Healthcare business at Mom n Pop Hub to reach out to more potential audience. Browse our website for free business signup!',
  },
  {
    catname: 'dental',
    titledesc:
      'List your dental clinic on Mom n Pop Hub. Give your business details and register your clinic directly with us. Click below to see list of other businesses already signed up!',
    metatitle: 'Dental Business Listing Website - Mom n Pop Hub',
    metadesc:
      'If you want more leads, list your dental business on Mom n Pop Hub for free. Add your business now!',
  },
  {
    catname: 'gyms',
    titledesc:
      'Free business registration service for Gyms at Mom n Pop Hub! Check below the list to find the Gyms that are already listed with us.',
    metatitle: 'Add Your Gyms Listing on Mom n Pop Hub For Free',
    metadesc:
      'Generate more leads by listing your Gym business on Mom n Pop Hub. Visit us today and get your business listed for free!',
  },
  {
    catname: 'weight-loss',
    titledesc:
      'Register your weight loss business with Mom n Pop Hub. This is an easy and free of cost service to flourish your business. Click below to check others similar business listing in the given list.',
    metatitle: 'Weight Loss Business Listing Website - Mom n Pop Hub',
    metadesc:
      'If you are providing weight loss products or services, submit your business on Mom n Pop Hub. This website will help you to attract more customers. Signup Now!',
  },
  {
    catname: 'natural-medicine',
    titledesc:
      'See your natural medicine business growing more without any charge by listing your Business on Mom n pop Hub. Listed below are listings from other people.',
    metatitle: 'Submit Natural Medicine Business on Mom n Pop Hub',
    metadesc:
      'If you have Natural Medicine Business, add your business on Mom n Pop Hub for free. This can help you to get more ROI. List your business For Free today!',
  },
  {
    catname: 'diet-&-nutrition',
    titledesc:
      'Mom n Pop Hub provides you the free space for List your Diet & Nutrition business for free. So list your organization and make it standing out in our top listed businesses.',
    metatitle: 'Free Business Listing Website - Diet & Nutrition - Mom n Pop Hub',
    metadesc:
      'List your nutrition business on Mom n Pop Hub. We will help promote your business to a local audience that interested in Diet & Nutrition services.',
  },
  {
    catname: 'clinic-&-medical-center',
    titledesc:
      'Register your Clinic & Medical Center with Mom n Pop Hub. This is an easy and free of cost service to grow your business. Click below to check others similar business listing in the given list.',
    metatitle: 'List Clinic & Medical Center Business on Mom n Pop Hub',
    metadesc:
      'Submit your clinic and medical center business on Mom n Pop Hub. We help small businesses grow and get free exposure. Signup now!',
  },
  {
    catname: 'nurse',
    titledesc:
      "If your business falls into the category of Nurse, list it with Mom n Pop Hub with free. Check out the other's listing by clicking below.",
    metatitle: 'Nurse - Business Listing Website - Mom n Pop Hub',
    metadesc:
      'Business listing website for Nurses. Our website will help employers to connect with you. Visit our website and signup today.',
  },
  {
    catname: 'fitness-classes',
    titledesc:
      'Make your Fitness Classes business listed in our top listings. signup today and list your business on Mom n Pop hub for free. Also do visit on below given list to see relatable businesses. ',
    metatitle: 'Add Your Fitness Classes Listing with Us - Signup Now',
    metadesc:
      'Are you looking to add your fitness classes listing on our website? Signup now and add your fitness classes listing by few simple steps.',
  },
  {
    catname: 'ayurveda',
    titledesc:
      'Promote your own Ayurveda brand with listing it on Mom n Pop Hub. Give your business details and register your business directly with us. Click below to see list of other businesses already signed up!',
    metatitle: 'Grow Your Ayurveda Business with Mom n Pop Hub - Signup Now!',
    metadesc:
      'Mom n Pop Hub is a business listing website to help you grow your Ayurveda business. Visit us and signup now!',
  },
  {
    catname: 'blood-&-plasma-donation-centers',
    titledesc:
      'Make your Blood & Plasma Donation Centers business listed in our top listings. signup today and list your business on Mom n Pop hub for free. Also do visit on below given list to see relatable businesses.',
    metatitle: 'Add Blood & Plasma Donation Centers on Mom n Pop Hub',
    metadesc:
      'If you own a blood and plasma donation centers, you can add your business here to connect with local audience. Signup today!',
  },
  {
    catname: 'cannabis-clinics',
    titledesc:
      'When it comes to advertising your Cannabis Clinics Center, Mom n Pop Hub is the best place to start. There is no charge to list your business on this site. To see other listings, click the link below.',
    metatitle: 'Business Listing for Cannabis Clinics - Mom n Pop Hub',
    metadesc:
      'List your cannabis clinics business on Mom n Pop Hub. This is a free business listing platform to promote small businesses. Visit us for free business signup now!',
  },
  {
    catname: 'counseling-&-mental-health',
    titledesc:
      'For Counseling & Mental Health, Mom n Pop Hub offers free business signup. Our free registration helps boost your business in the local listings. Take a look at similar businesses already registered with us!',
    metatitle: 'Counseling & Mental Health Business Listing - Mom n Pop Hub',
    metadesc:
      'Add a FREE listing of your counseling & mental health business on Mom n Pop Hub. Signup today or Claim you business.',
  },
  {
    catname: 'dentists',
    titledesc:
      "Are you owner of a Dentists center to list your business for more profit then Mom n Pop Hub is the best option for you. List your business here without any fee. Click below to see other's listing",
    metatitle: 'Dentists Business Listing Website - Mom n Pop Hub - Signup Now!',
    metadesc:
      'Sign up now on Mom n Pop Hub and list your Dentists business. We are user-friendly and free business listing platform. ',
  },
  {
    catname: 'diagnostic-services',
    titledesc:
      "Is your business a Diagnostic Services? If so, Mom n Pop Hub is the best place for you to list your business so more people can find it. List your business here for free. Click below to see other's listing.",
    metatitle: 'Add Diagnostic Services For Free on Mom n Pop Hub',
    metadesc:
      'If you provide diagnostic services, add your business on Mom n Pop Hub for free. This can help you to get more ROI. List your business now!',
  },
  {
    catname: 'doctors',
    titledesc:
      'Register your Doctors services with Mom n Pop Hub. This is an easy and free of cost service to flourish your business. Click below to check others similar business listing in the given list.',
    metatitle: 'Business Listing Website for Doctors - Mom n Pop Hub',
    metadesc:
      'If you are a doctor, submit your business on Mom n Pop Hub. Our free listing service helps small businesses gain more exposure and connect with your customers. ',
  },
  {
    catname: 'emergency-rooms',
    titledesc:
      "Are you a Emergency Rooms services provider then list your business on Mom n Pop Hub it is the best option for you. List your business here without any fee. Click below to see other's relatable listings.",
    metatitle: 'Emergency Rooms Business Listing Website - Mom n Pop Hub',
    metadesc:
      'Sign up now on Mom n Pop Hub and add your Emergency Rooms business. We are a free business listing website that helps to grow your business.',
  },
  {
    catname: 'hearing-aid-providers',
    titledesc:
      'Make your Hearing Aid Providers business listed in best business listing website for free. List your business on Mom n Pop Hub to get more growth in your coustomers.',
    metatitle: 'Free Business Listing for Hearing Aid Providers - Mom n Pop Hub',
    metadesc:
      'Do you have a small Hearing Aid Providers business? If yes, we are here for you. List your business on Mom n Pop Hub to get recognition and more customers.',
  },
  {
    catname: 'herbal-shops',
    titledesc:
      'Do you own a business that sells herbs? If so, Mom n Pop Hub is the best place to list your business. You can list your business here for free. To see what other people have for sale, click the button below.',
    metatitle: 'Add Your Herbal Shops on Mom n Pop Hub - Free Business Signup!',
    metadesc:
      'Mom n Pop Hub is a local business listing platform dedicated to small businesses to help them find customers. You can list your Herbal Shops on Mom n Pop Hub. Signup now!',
  },
  {
    catname: 'hospitals',
    titledesc:
      'Free registration service for Hospitals at Mom n Pop Hub! Check  the list below to find the services that’s right for your small business.',
    metatitle: 'Free Business Listing for Hospitals - Mom n Pop Hub',
    metadesc:
      'List your hospital on Mom n Pop Hub. This will help patients to connect with you who live in the nearby area. Signup Now!',
  },
  {
    catname: 'medical-centers',
    titledesc:
      "if you run a Medical Centers business and want to list your business for more customers then Mom n Pop Hub is the best option for you. List your business here without any fee. Click below to see other's listings.",
    metatitle: 'Add Your Medical Centers on Mom n Pop Hub - Free Business Signup!',
    metadesc:
      'If you have a medical center, add your business on Mom n Pop Hub today. A free listing to promote small businesses! Signup today!',
  },
  {
    catname: 'medical-spas',
    titledesc:
      "Are you an owner of Medical Spas then list your business on Mom n Pop Hub for no cost. checkout other's listings which are listed below.",
    metatitle: 'Medical Spas Business Listings at Mom n Pop Hub',
    metadesc:
      'Add your medical spa business on Mom n Pop Hub. This will help you connect with local customers in your area. Visit us today and get your business listed for free!',
  },
  {
    catname: 'memory-care',
    titledesc:
      'Mom n Pop Hub is the best place to list your Memory Care business online. it provides free of cost listing space for your business. Click below to see the list of businsses that are already signup with us.',
    metatitle: 'Submit Memory Care Listing For Free on Mom n Pop Hub',
    metadesc:
      'Mom n Pop Hub is a listing website that allows you to list your Memory Care business for free. Visit us and Sign up for free today.',
  },
  {
    catname: 'nutritionists',
    titledesc:
      'If you are a Nutritionists and looking to list your services for free then Mom n Pop Hub is the best option for you. Take a look at similar businesses already registered with us!',
    metatitle: 'Free Business Listing for Nutritionists - Mom n Pop Hub',
    metadesc:
      'Grow your nutritionist business with Mom n Pop Hub. Fill out the free business sign up form and list your business by follow a few simple steps.',
  },
  {
    catname: 'oxygen-bars',
    titledesc:
      'For Oxygen Bars Services, Mom n Pop Hub offers free business signup. Our free registration helps boost your business in the local listings. Check out the similar businesses that are already registered with us!',
    metatitle: 'List Oxygen Bars For Free on Mom n Pop Hub - Signup Now!',
    metadesc:
      "List your oxygen bar today. With Mom n Pop Hub, you'll get leads from customers looking for oxygen bars in your area. Signup Now!",
  },
  {
    catname: 'rehabilitation-center',
    titledesc:
      'List your Rehabilitation Center with Mom N Pop Hub for better users experiece. Take a look at similar business listings to get started.',
    metatitle: 'Submit Your Rehabilitation Center Listing on Mom n Pop Hub',
    metadesc:
      'List your rehabilitation center business on Mom n Pop Hub. This will make people more aware of your business and services. Visit us for free business signup now! ',
  },
  {
    catname: 'ultrasound-imaging-centers',
    titledesc:
      'Business registration on Mom n Pop Hub helps Ultrasound Imaging Centers owners to manage their business and interact with potential customers. Check out the similar listings that are already registered with us!',
    metatitle: 'Promote Your Ultrasound Imaging Centers with Mom n Pop Hub',
    metadesc:
      'Find more customers by promoting your ultrasound imaging centers through Mom n Pop Hub. Visit us today and list your business for free.',
  },
  {
    catname: 'weight-loss-centers',
    titledesc:
      'Make your Weight Loss Centers business listed in our top listings. signup today and list your business on Mom n Pop hub for free. Also do visit on below given list to see relatable businesses.',
    metatitle: 'List Your Weight Loss Centers For Free on Mom n Pop - Signup Now!',
    metadesc:
      'Get your weight loss centers listed on Mom n Pop and get more clients! Visit us today and list your business for free.',
  },
  {
    catname: 'animal-hospital',
    titledesc:
      'For those who own a Animal Hospital business, Mom n Pop Hub is the best option for listing your business. You can list your business here for free. Here are the list of all the registration services businesses that have already registred with us.',
    metatitle: 'Add Your Animal Hospital Business Listing For Free - Mom n Pop Hub',
    metadesc:
      'We offer a free business listing service on our website. Our goal is to ensure all animal hospitals get the attention they deserve. List your business now!',
  },
  {
    catname: 'banks-&-credit-unions',
    titledesc:
      'Free business registration service for banks& credit Unions at Mom n Pop Hub! Check below the list to find the banks& credit union that’s right for your small business.',
    metatitle: 'List your Banks & Credit Unions business store with Mom n Pop Hub',
    metadesc:
      'Add your banks and credit unions business store on Mom n Pop Hub to reach out to a more potential target audience. Visit us today for a free sign-up!',
  },
  {
    catname: 'business-financing',
    titledesc:
      'Grow your Financing Business by listing it on Mom n Pop Hub. No fee to list your business here. Click below to check others similar business listing in the given list.',
    metatitle: 'Add Your Financing Business Office on Mom n Pop Hub - Signup now!',
    metadesc:
      'Add your business financing office on Mom n Pop Hub for free and reach out to your potential target audience. Visit us today and get your business listed!',
  },
  {
    catname: 'currency-exchange',
    titledesc:
      'Running a Currency Exchange store and want to attract more customers? Register your business with us or Click below to check others similar business listing in the given list.',
    metatitle: 'Free Signup - List Your Currency Exchange Services - Mom n Pop Hub',
    metadesc:
      'Add your currency exchange store on Mom n Pop Hub for free to reach out to a more potential target audience. Signup today or claim your business.',
  },
  {
    catname: 'debt-relief-services',
    titledesc:
      'Are you Providing Debt Relief Services and wants to flourish your business? then list your services on Mom n Pop Hub for no charges. To get started, take a look at the similar businesses already listed with us!',
    metatitle: 'List Your Debt Relief Services on Mom n Pop Hub ',
    metadesc:
      'List your debt relief service on Mom n Pop Hub for free to attract a more target audience. Visit us today for a free sign-up!',
  },
  {
    catname: 'financial-advising',
    titledesc:
      'Here is the list of Financial Advising service Providers that listed with Mom n Pop Hub. If you are a business owner of financial advising service providers and want to grow your business, then list your business with us. ',
    metatitle: 'List your Financial Advising Office Now with Mom n Pop Hub ',
    metadesc:
      'Add your Financial Advising Office Now with Mom n Pop Hub for free. This will make people more aware of your business to attract a more target audience. Signup Now!',
  },
  {
    catname: 'installment-loans',
    titledesc:
      'Add your Installment Loans office on Mom n Pop Hub for free to attract a more target audience. Here is the list of businsses that are already signup with us.',
    metatitle: 'Add Your Installment Loans office on Mom n Pop Hub - Signup Now!',
    metadesc:
      'Add your Installment Loans office on Mom n Pop Hub for free to attract a more target audience. Signup today or claim your business.Signup Now!',
  },
  {
    catname: 'insurance',
    titledesc:
      'Register your Insurance business with Mom n Pop Hub. This is an easy and free of cost service to flourish your business. Click below to check others similar business listing in the given list.',
    metatitle: 'Free Signup For Your Insurance Services - Mom n Pop Hub',
    metadesc:
      'List your Insurance physical store on Mom n Pop Hub for free to attract a more target audience. Signup today or claim your business.Signup Now!',
  },
  {
    catname: 'investing',
    titledesc:
      'For getting more customers to your Investing business, Mom n Pop Hub is the best place. List your business here for free. See more listings by clicking below.',
    metatitle: 'Investing business website for free listing - Mom n Pop Hub',
    metadesc:
      'List your Investing business website for free on Mom n Pop Hub for free to attract a more target audience. Signup today or claim your business.Signup Now!',
  },
  {
    catname: 'mortgage-lenders',
    titledesc:
      'List your Mortgage Lenders business with Mom N Pop Hub. Here is the list of businsses that are already signup with us.',
    metatitle: 'List your Mortgage Lenders Services office - Mom n Pop Hub ',
    metadesc:
      'Add your Mortgage Lenders Services office on Mom n Pop Hub for free to attract a more target audience. Signup today or claim your business.',
  },
  {
    catname: 'tax-services',
    titledesc:
      'Grow your Tax Services by listing it on Mom n Pop Hub. No fee to list your business here. Click below to check others similar business listing in the given list.',
    metatitle: 'List Your Tax Services Business on Mom n Pop Hub',
    metadesc:
      'List your Tax Services Business on Mom n Pop Hub for free to attract a more target audience. Visit us today for a free sign-up!',
  },
  {
    catname: 'restaurants',
    titledesc:
      'If your business falls into the category of restaurants, list it with Mom n Pop Hub. Take a look at similar business listings to get started!',
    metatitle: 'List Restaurants on Mom n Pop Hub - Free Business Signup!',
    metadesc:
      "Grow your restaurant business by listing it on Mom N Pop Hub. It's User-friendly and free business listing service site. Sign up today for free listing",
  },
  {
    catname: 'desserts',
    titledesc:
      'List your desserts store with Mom N Pop Hub for better retunts of investment and the users experiece. Take a look at similar business listings to get started.',
    metatitle: 'Submit Desserts Business Listing  For Free - Mom n Pop Hub',
    metadesc:
      'List your desserts store with Mom N Pop Hub for better retunts of investment and the users experiece.  Visit us for business signup and Grow your business.',
  },
  {
    catname: 'breweries',
    titledesc:
      'List your Breweries store business with Mom N Pop Hub. Here is the list of businsses that are already signup with us.',
    metatitle: 'Add Your Breweries Business on Mom n Pop Hub - Signup Now!',
    metadesc:
      'List your Breweries store business on Mom N Pop Hub. This is a free business listing platform. Visit us today for free signup!',
  },
  {
    catname: 'wineries-&-distilleries',
    titledesc:
      'Business registration helps Wineries & Distilleries businesses to manage their business and interact with potential customers. Check out more listings by clicking below.',
    metatitle: 'Free Business Listing For Wineries & Distilleries - Mom n Pop Hub',
    metadesc:
      'Easy and free business listing for Wineries & Distilleries stores at Mom n Pop Hub!  Sign up today and discover how we can help your business to grow with good ROI.',
  },
  {
    catname: 'cafes-&-treats',
    titledesc:
      'Are you Cafes & Treats services and wants to flourish your business? then list your services on Mom n Pop Hub for no charges. Also see others relatable listing in the given list.  ',
    metatitle: 'Free Business Listing Website For Cafes & Treats - Mom n Pop Hub',
    metadesc:
      'At Mom n Pop Hub, listing Cafes & Treats store business  goes a long way towards gaining potential clients. This is a free business listing service. Visit us for Signup Now!',
  },
  {
    catname: 'groceries-&-markets',
    titledesc:
      'List your Groceries & Markets business with Mom n Pop Hub. we are the best place for you to list your business so more people can find it. List your business here for free. To see what other people have for sale, click below to check the listed businesses.',
    metatitle: 'Add Your Groceries & Markets Store on Mom n Pop Hub - Signup Now!',
    metadesc:
      'Promote your allocated Groceries & Markets business with listing it free on Mom n Pop Hub! Reach new customers with a free signup!',
  },
  {
    catname: 'catering-&-supplies',
    titledesc:
      'Register your Catering & Supplies business with Mom n Pop Hub. This is an easy and free of cost service to flourish your business. Click below to check others similar business listing in the given list.',
    metatitle: 'Grow Your Catering & Supplies Business with Mom n Pop Hub',
    metadesc:
      'Give your Catering & Supplies business a better boost with listing it on Mom n Pop Hub. This is a no-cost small business listing platform. Sign up today for free to get started!',
  },
  {
    catname: 'bagels',
    titledesc:
      'Grow your professional Bagels business with Mom n Pop Hub. Visit us today and list your business for free. Here are the list of other businesses that are already signed up with us.',
    metatitle: 'Add Your Bagels Store on Mom n Pop Hub - Signup Now!',
    metadesc:
      'Grow your professional Bagels business with Mom n Pop Hub. Visit us today and list your business for free. Signup Now!',
  },
  {
    catname: 'beverage-store',
    titledesc:
      'Mom n Pop Hub is the best place for the Beverage Store to list their business and attract more customers. There is no charge to list your business on this site. Listed below are listings from other people.',
    metatitle: 'List Your Beverage Store on Mom n Pop Hub For Free',
    metadesc:
      'The free business listing that help you sell your Beverage Store at Mom n Pop Hub! Visit us for free business signup and get the best returns of inverstments.',
  },
  {
    catname: 'coffee-&-tea',
    titledesc:
      'Expand your Coffee & Tea business by listing it on Mom n Pop Hub. Here are the list of all the relatable businesses that have already registered with us.',
    metatitle: 'Add Coffee & Tea Cafes on Mom n Pop Hub For Free',
    metadesc:
      'List your Coffee & Tea business on Mom n Pop Hub. This user-friendly platform will help you to get more leads. Add your business now!',
  },
  {
    catname: 'convenience-stores',
    titledesc:
      'Get more customers in your Convenience Stores by listing them on Mom n Pop Hub without any cost. Check out the Movers already registered with us! ',
    metatitle: 'Add Your Convenience Stores on Mom n Pop Hub - Signup Now!',
    metadesc:
      'List your Convenience Stores to streamline the entire procedure with Mom n Pop Hub. No hidden costs for business sign up!',
  },
  {
    catname: 'custom-cakes',
    titledesc:
      'For getting more customers to your Custom Cakes business, Mom n Pop Hub is the best place. List your business here without any fee. See more listings by clicking below.',
    metatitle: 'Promote Your Custom Cakes Business with Us - Signup Now!',
    metadesc:
      'Free business listing for Custom Cakes dealers or stores at Mom n Pop Hub, the ultimate platform to grow your business. Sign up for free today.',
  },
  {
    catname: 'food-trucks',
    titledesc:
      'Grow your Food Trucks by listing it on Mom n Pop Hub. No fee to list your business here. Click below to check others similar business listing in the given list.',
    metatitle: 'Add Food Trucks Listing on Mom n Pop Hub - Free Business Signup!',
    metadesc:
      'Free business listing for Food Trucks stores at Mom n Pop Hub to get instant customers! Visit us for a free sign up!',
  },
  {
    catname: 'water-stores',
    titledesc:
      'Are you providing water stores and wants to flourish your business? then list your services on Mom n Pop Hub for no charges. To get started, take a look at the similar businesses already listed with us!',
    metatitle: 'Grow Your Water Stores with Mom n Pop Hub',
    metadesc:
      'Looking for customers for your water stores? Get more leads by listing your Water Stores store on Mom n Pop Hub. Visit us and Sign up for free today.',
  },
  {
    catname: 'herbs-&-spices',
    titledesc:
      'If you are a business owner of Herbs & Spices Service and want to grow your business, then list your business with us. Here is the list of Herbs & Spices Providers that listed with Mom n Pop Hub.',
    metatitle: 'Herbs & Spices Stores Near Me - Mom n Pop Hub',
    metadesc:
      'List your fine herbs & spices business on Mom n Pop Hub for free to attract more potential target customers. Visit us and sign up now!',
  },
  {
    catname: 'meat-shops',
    titledesc:
      'Are you a owner of Meat Shops and want more costumers to profit then register your business on Mom n Pop Hub for no charge. Click below for seeing the similar listings.',
    metatitle: 'List Meat Shops on Mom n Pop Hub - Free Business Signup!',
    metadesc:
      'Add your meat shop on Mom n Pop Hub for free. Visit us and list your business for free to attract more customers. Sign up now!',
  },
  {
    catname: 'air-conditioning-&-heating',
    titledesc:
      'Register your Air Conditioning & Heating business with Mom n Pop Hub. This is an easy and free of cost service to flourish your business. Click below to check others similar business listing in the given list.',
    metatitle: 'Submit Air Conditioning & Heating Business on Mom n Pop Hub',
    metadesc:
      'Give your Air Conditioning & Heating business a better boost with listing it on Mom n Pop Hub. This is a no-cost small business listing platform. Sign up today for free to get started!',
  },
  {
    catname: 'contractors',
    titledesc:
      'Business registration helps Contractors to manage their business and interact with potential customers. Check out more listings by clicking below.',
    metatitle: 'Free Business Listing Website for Contractors - Mom n Pop Hub',
    metadesc:
      'Free business listing for Contractors at Mom n Pop Hub, the ultimate platform to grow your business. Sign up for free today.',
  },
  {
    categoryname: 'wix',
    catname: 'electricians',
    titledesc:
      'Mom n Pop Hub is the best place for the Electricians to list their business and attract more customers. There is no charge to list your business on this site. Listed below are listings from other people.',
    metatitle: 'Add Electricians Business on Mom n Pop Hub - Signup Now!',
    metadesc:
      'List your electricians business on Mom n Pop Hub. This user-friendly platform will help you to get more leads. Submit your business now!',
  },
  {
    categoryname: 'bigcommerce',
    catname: 'electricians',
    titledesc:
        'Mom n Pop Hub is the best place for the Electricians to list their business and attract more customers. There is no charge to list your business on this site. Listed below are listings from other people.',
    metatitle: 'Join Mom n Pop Hub: Add Electrician Business - Sign Up Now!',
    metadesc:
        'Join Mom n Pop Hub to list your electrician business. Our easy-to-use platform boosts leads. Submit your business now for more opportunities!',
  },
  {
    categoryname: 'woocommerce',
    catname: 'electricians',
    titledesc:
        'Mom n Pop Hub is the best place for the Electricians to list their business and attract more customers. There is no charge to list your business on this site. Listed below are listings from other people.',
    metatitle: 'List Your Electricians Business on Mom n Pop Hub - Join Today!',
    metadesc:
        'Feature your electrician business on Mom n Pop Hub. Our user-friendly platform enhances lead generation. Submit your business today!',
  },
  {
    categoryname: 'shopify-store',
    catname: 'electricians',
    titledesc:
        'Mom n Pop Hub is the best place for the Electricians to list their business and attract more customers. There is no charge to list your business on this site. Listed below are listings from other people.',
    metatitle: 'Join Mom n Pop Hub for Electricians - Sign Up Today',
    metadesc:
        'Get your electrician business list on Mom n Pop Hub. Our easy-to-use platform boosts leads. Submit your business now for increased visibility!',
  },
  {
    categoryname: 'independent-website',
    catname: 'electricians',
    titledesc:
        'Mom n Pop Hub is the best place for the Electricians to list their business and attract more customers. There is no charge to list your business on this site. Listed below are listings from other people.',
    metatitle: 'List Your Electrician Business - Join Mom n Pop Hub!',
    metadesc:
        'Register your electrician business on Mom n Pop Hub. Our intuitive platform boosts lead generation. Submit your business today for increased visibility!',
  },
  {
    categoryname: 'for-home',
    catname: 'electricians',
    titledesc:
        'Mom n Pop Hub is the best place for the Electricians to list their business and attract more customers. There is no charge to list your business on this site. Listed below are listings from other people.',
    metatitle: 'Join Mom n Pop Hub: List Your Electrician Business!',
    metadesc:
        'Increase your electrician business\'s visibility on Mom n Pop Hub. Join our user-friendly platform to attract more leads. Submit your business today!',
  },
  {
    catname: 'home-cleaners',
    titledesc:
      'Make your Home Cleaners business listed in best business listing website for free. List your business on Mom n Pop Hub to get more growth in your coustomers.',
    metatitle: 'Grow Your Home Cleaners Business with Mom n Pop Hub',
    metadesc:
      "Grow your home cleaning services business by listing it on Mom N Pop Hub. It's User-friendly and free business listing service site. Signup now!",
  },
  {
    catname: 'plumbers',
    titledesc:
      "Mom n Pop Hub provides you the free space for List your Plumber's Services for no cost. So list your organization and make it stand out in our top listed businesses.",
    metatitle: 'Plumbers Business Listing Website - Mom n Pop Hub',
    metadesc:
      'Grow your professional plumbing services business with Mom n Pop Hub. Visit us today and list your business for free. Signup Now!',
  },
  {
    catname: 'locksmiths',
    titledesc:
      'Expand your Locksmiths business by listing it on Mom n Pop Hub. Here are the list of all the relatable businesses that have already registered with us.',
    metatitle: 'Add Your Locksmiths Business on Mom n Pop Hub - Signup Now!',
    metadesc:
      'Give your Locksmiths business a better boost with listing it on Mom n Pop Hub. This is a no-cost small business listing platform. Sign up today for free to get started!',
  },
  {
    catname: 'movers',
    titledesc:
      'Get more customers in your Movers Business by listing them on Mom n Pop Hub without any cost. Check out the Movers already registered with us! ',
    metatitle: 'Movers and Packers Near Me - Mom n Pop Hub - Signup Now!',
    metadesc:
      'List your movers and packers services on Momnpophub.com. This will help you connect with local customers in your area. Signup now!',
  },
  {
    catname: 'painters',
    titledesc:
      'For getting more customers to your Painters services, Mom n Pop Hub is the best place. List your business here without any fee. See more listings by clicking below.',
    metatitle: 'Free Business Listing for Painters - Mom n Pop Hub',
    metadesc:
      'Find more customers by promoting your professional painter business through Mom n Pop Hub. Visit us today and list your business for free.',
  },
  {
    catname: 'art',
    titledesc:
      'Grow your Art business by listing it on Mom n Pop Hub. No fee to list your business here. Click below to check others similar business listing in the given list.',
    metatitle: 'Art Business Listing - Free Business Signup - Mom n Pop Hub ',
    metadesc:
      'Grow your art business by listing it on Mom N Pop Hub. It is a user-friendly and free business listing website. Add your business now!',
  },
  {
    catname: 'bath',
    titledesc:
      'Are you Providing Bath Services and wants to flourish your business? then list your services on Mom n Pop Hub for no charges. To get started, take a look at the similar businesses already listed with us!',
    metatitle: 'Free Business Listing Website for Bath Stores - Mom n Pop Hub',
    metadesc:
      'Sign up now on Mom n Pop Hub and add your bath stores. We are a free business listing website that helps to grow your business.',
  },
  {
    catname: 'outdoor-decor',
    titledesc:
      'Here is the list of Outdoor Decor services Providers that listed with Mom n Pop Hub. If you are a business owner of Outdoor Decor Service Providers and want to grow your business, then list your business with us. ',
    metatitle: 'List Your Outdoor Decor Services on Mom n Pop Hub - Join Us Now!',
    metadesc:
      'Add your outdoor decor business on Mom n Pop Hub. This will help you connect with local customers in your area. Visit us today and get your business listed for free!',
  },
  {
    catname: 'furniture',
    titledesc:
      'Are you a owner of Furniture business want more costumers and profit then register your business on Mom n Pop Hub for no charge. Click below to see the similar listings.',
    metatitle: 'List Your Furniture Store on Mom n Pop Hub For Free',
    metadesc:
      'If you have a furniture store, add your store on Mom n Pop Hub today. A free listing to promote small businesses! Signup today!',
  },
  {
    catname: 'home-appliances',
    titledesc:
      'Get more customers in your Home Appliances Business by listing them on Mom n Pop Hub without any cost. Check out the Home Appliances services providers that are already registered with us! ',
    metatitle: 'Home Appliances - Business Listing - Mom n Pop Hub',
    metadesc:
      'Sign up now on Mom n Pop Hub and add your home appliances business. We are a free business listing website that helps to grow your business.',
  },
  {
    catname: 'home-decor',
    titledesc:
      'List your Home Decor business for free on Mom n Pop Hub. This is an easy service to flourish your business. Click below to check others similar business listing.',
    metatitle: 'List Your Home Decor Services on Mom n Pop Hub - Join Us Now!',
    metadesc:
      'List your home decor business on Mom n Pop Hub. This will make people more aware of your business and services. Visit us for free business signup now! ',
  },
  {
    catname: 'kitchen-&-dining',
    titledesc:
      'Mom n Pop Hub is the best place for Kitchen & Dining services to list their business and attract more customers. There is no charge to list your business on this site. Listed below are listings from other people.',
    metatitle: 'Kitchen & Dining - Business Listing For Free - Mom n Pop Hub',
    metadesc:
      'Find more customers by promoting your kitchen & dining business through Mom n Pop Hub. Visit us today and list your business for free.',
  },
  {
    catname: 'luggage',
    titledesc:
      'Are you Providing Luggage services and wants to flourish your business? then list your services on Mom n Pop Hub for no charges. also see others relatable listing in given list.  ',
    metatitle: 'Add Luggage Business Listing For Free on Mom n Pop Hub',
    metadesc:
      'Add your luggage business on Mom n Pop Hub. This will help you connect with local customers in your area. Visit us today and get your business listed for free!',
  },
  {
    catname: 'mattresses-&-accessories',
    titledesc:
      "List your Mattresses & Accessories Services on Mom n Pop Hub and see rapid growth in your business in blink's of eyes. Here are the list of all the relatable businesses that have already registered with us.",
    metatitle: 'List Mattresses & Accessories Business on Mom n Pop Hub',
    metadesc:
      'Mom n Pop Hub is a local business listing platform dedicated to small businesses to help them find customers. List your mattresses & accessories business on Momnpophub.com. ',
  },
  {
    catname: 'office-&-school-supplies',
    titledesc:
      'Register your Office & School Supplies business with Mom n Pop Hub. This is an easy and free of cost service to grow your business. Click below to check others similar business listing in the given list.',
    metatitle: 'List Office & School Supplies Services on Mom n Pop Hub',
    metadesc:
      'If you provide office & school supplies services, add your business on Mom n Pop Hub. Our free listing website helps small businesses gain more exposure. Signup now!',
  },
  {
    catname: 'hotels',
    titledesc:
      'Business registration helps hotel owners manage their business and interact with potential customers. Check out the hotels already registered with us!',
    metatitle: 'Hotels Near Me - Hotels Business Listing For Free - Mom n Pop Hub',
    metadesc:
      'Free business listing service for your hotels at Mom n Pop Hub. This will help you reach more potential customers. Sign up now!',
  },
  {
    catname: 'motels',
    titledesc:
      'Get your motels listed on Mom n Pop Hub to improve your business presence and visibility. We offer free of cost business listing service too small businesses. Choose a category for your business profile to list your business with us. Check out the motels already registered with us! ',
    metatitle: 'Motels Near Me - Motels Business Listing Website - Mom n Pop Hub',
    metadesc:
      'Add your motel on Mom n Pop Hub. This will help you attract more local customers in your area. Visit us today and get your business listed for free!',
  },
  {
    catname: 'bus-tours',
    titledesc:
      'It is easier to make your Bus Tours Business flourish with Mom n Pop Hub. To get started, take a look at the similar businesses already listed with us!',
    metatitle: 'Bus Tours Services - Business Listing For Free - Mom n Pop Hub',
    metadesc:
      'If you provide bus tour services, then add your business on Mom n Pop Hub. Our free listing website helps small businesses gain more exposure. Signup now!',
  },
  {
    catname: 'travel-services',
    titledesc:
      'Get more customers in your Travel Services by listing them on Mom n Pop Hub without any cost. Here are the list of other businesses that are already signed up with us.',
    metatitle: 'Add Travel Services - Free Business Signup - Mom n Pop Hub',
    metadesc:
      'List your travel services business on Mom n Pop Hub. This will make people more aware of your business and services. Visit us for free business signup now! ',
  },
  {
    catname: 'travel-agents',
    titledesc:
      'Are you Providing best Travel Agents and wants to flourish your business? then list your services on Mom n Pop Hub for no charges. also see others relatable listing in given list.  ',
    metatitle: 'Travel Agents - List Business For Free - Mom n Pop Hub',
    metadesc:
      'Add your travel agent business on Mom n Pop Hub to reach out to more local customers in your area. Visit us today and get your business listed for free!',
  },
  {
    catname: 'passport-&-visa-services',
    titledesc:
      "List your Passport & Visa Services on Mom n Pop Hub and see rapid growth in your business in blink's of eyes. Here are the list of all the relatable businesses that have already registered with us.",
    metatitle: 'Business Listing for Passport & Visa Services - Mom n Pop Hub',
    metadesc:
      'Add your passport & business services on Mom n Pop Hub to make people more aware of your business and services. Visit us for free business signup now!',
  },
  {
    catname: 'bed-&-breakfast',
    titledesc:
      'If your business falls into the category of Bed & Breakfast, list it with Mom n Pop Hub. Click below and take a look at similar business listings for listing your business.',
    metatitle: 'Add Your Bed & Breakfast Business on Mom n Pop Hub - Join Us Now!',
    metadesc:
      'List your bed & breakfast business on Mom n Pop Hub for free. This will help you generate more leads. Visit our website today for free business signup!',
  },
  {
    catname: '3d-printing',
    titledesc:
      'It is easier to make your 3D business flourish with Mom n Pop Hub. To get started, take a look at the similar businesses already listed with us!',
    metatitle: '3D Printing Business Listing - Mom n Pop Hub - Join Us Now!',
    metadesc:
      'Grow your 3D Printing business with Mom n Pop Hub. Our free listing website helps small businesses gain more exposure. Signup now!',
  },
  {
    catname: 'appliances-&-repair',
    titledesc:
      'Business registration helps Appliances & Repair owners manage their business and interact with potential customers. Check out the similar listings that are already registered with us!',
    metatitle: 'Appliances & Repair Services Near Me - Mom n Pop Hub',
    metadesc:
      'Add a FREE listing of your appliances & repair Services on Mom n Pop Hub. Signup today or Claim you business.',
  },
  {
    catname: 'art-installation',
    titledesc:
      'See your Art Installation Business growing more without any charge by listing your Business on Mom n pop Hub. Listed below are listings from other people.',
    metatitle: 'List Your Art Installation Business on Mom n Pop Hub',
    metadesc:
      'Find more customers by promoting your art installation business through Mom n Pop Hub. Visit us today and get your business listed for free!',
  },
  {
    catname: 'bike-repair-maintenance',
    titledesc:
      'Mom n Pop Hub provides you the free space for List your Bike Repair/Maintenance Services for no cost. So list your organization and make it standing out in our top listed businesses.',
    metatitle: 'Bike Repair and Maintenance Services Business Listing - Signup Now!',
    metadesc:
      'Unlock Bike Repair and Maintenance business opportunities with listing service at Mom n Pop Hub. This is a free service that helps you reach more customers easily.',
  },
  {
    catname: 'bus-rental',
    titledesc:
      'Register your Bus Rental Business with Mom n Pop Hub. This is an easy and free of cost service to grow your business. Click below to check others similar business listing in the given list.',
    metatitle: 'Business Listing for Bus Rental Services - Mom n Pop Hub',
    metadesc:
      'Claim free business listing for bus rental services with Mom n Pop Hub. This free service takes your small business to greater heights. Visit us to signup today.',
  },
  {
    catname: 'calligraphy',
    titledesc:
      "If your business falls into the category of Calligraphy Services, list it with Mom n Pop Hub with no cost. Check out the other's listing by clicking below.",
    metatitle: 'Add Calligraphy Business on Mom n Pop Hub - Join Us Now!',
    metadesc:
      'List your Calligraphy Business on Mom n Pop Hub for free. This will make people more aware of your business and attract customers. Sign up for free today.',
  },
  {
    catname: 'clock-repair',
    titledesc:
      'Make your Clock Repair business listed in our top listings. signup today and list your business on Mom n Pop hub for free. Also do visit on below given list to see relatable businesses. ',
    metatitle: 'Grow Your Clock Repair Business with Mon n Pop Hub for Free!',
    metadesc:
      'add your Clock Repair Business on Mom n Pop Hub for free. This will make people more aware of your business and attract customers. Sign up for free today.',
  },
  {
    catname: 'couriers-&-delivery-services',
    titledesc:
      'Get your Couriers & Delivery Services listed on Mom n Pop Hub to improve your business presence and visibility. We offer free of cost business listing services to all businesses.',
    metatitle: 'Couriers & Delivery Services Near Me - Mom n Pop Hub',
    metadesc:
      'list your Couriers & Delivery Services Near Me on Mom n Pop Hub for free. This will make people more aware of your business to attract a more target audience.Sign up now !',
  },
  {
    catname: 'donation-center',
    titledesc:
      'Flourish your Donation Center without any fee by listing it on Mom n Pop Hub.  Here is the list of businesses that are already signup with us.',
    metatitle: 'List Your Donation Center on Mom n Pop Hub - Signup Now!',
    metadesc:
      'List your donation center on Mom n Pop Hub. This will help you connect with local people in your area. Visit us today and get your business listed for free!',
  },
  {
    categoryname: 'for-home',
    catname: 'electronics-repair',
    titledesc:
      'To get more customers to your Electronics Repair business, Mom n Pop Hub is the best place to list it. No fee to list your business here. See more listings below.',
    metatitle: 'Electronics Repair Services Near Me - Mom n Pop Hub',
    metadesc:
      'If you offer Electronics Repair Services , you can list your business for free on Mom n Pop Hub. This will increase public awareness of your business. Signup now!',
  },
  {
    categoryname: 'local-services',
    catname: 'electronics-repair',
    titledesc:
        'To get more customers to your Electronics Repair business, Mom n Pop Hub is the best place to list it. No fee to list your business here. See more listings below.',
    metatitle: 'Electronics Repair Shops - Mom n Pop Hub',
    metadesc:
        'If you provide Electronics Repair Services, showcase your business for free on Mom n Pop Hub. Boost your visibility and sign up today!',
  },
  {
    catname: 'funeral-services-&-cemeteries',
    titledesc:
      'List your Funeral Services & Cemeteries business for free on Mom n Pop Hub. Here are the list of businsses that are already signup with Mom n Pop Hub.',
    metatitle: 'List Your Funeral Services & Cemeteries on Mom n Pop Hub',
    metadesc:
      'If you are providing Funeral Services & Cemeteries, submit your business on Mom n Pop Hub for free. This will make people more aware of your business to attract a more target audience.',
  },
  {
    catname: 'furniture-rental-&-repair',
    titledesc:
      'See your Furniture Rental & Repair Business growing more with us. List your business on Mom n Pop Hub for no cost. Check out more relatable listings by clicking below.',
    metatitle: 'Add Your Furniture Rental & Repair Business - Mom n Pop Hub',
    metadesc:
      'Find more customers by submitting your Furniture Rental & Repair Business through Mom n Pop Hub for free. Signup now!',
  },
  {
    catname: 'musical-instrument-services',
    titledesc:
      'Register your Musical Instrument Services with Mom n Pop Hub. This is an easy and free of cost service to grow your business. Below are the list of other businesses that are already signed up with us.',
    metatitle: 'List Musical Instrument Services Business on Mom n Pop Hub',
    metadesc:
      'List Musical Instrument Services Business on Mom n Pop Hub for free. This will make people more aware of your business to attract a more target audience.Signup Now!',
  },
  {
    catname: 'it-services-&-computer-repair',
    titledesc:
      "Wants to list your IT Services & Computer Repair services for growth in your business then list your business on Mom n Pop Hub for no cost. Check out the other's listing by clicking below.",
    metatitle: 'IT Services & Computer Repair Near Me - Mom n Pop Hub',
    metadesc:
      'List your  IT Services & Computer Repair Near Me busines on Mom n Pop Hub for free. This will make people aware of your business. Sign up for free today.',
  },
  {
    catname: 'laundry-services',
    titledesc:
      'If you are providing Laundry Services then list your business on Mom n Pop Hub. Take a look at the listed categories and sub categories to increase your small online business’s market presence.',
    metatitle: 'List Laundry Services on Mom n Pop Hub - Free Business Signup!',
    metadesc:
      'List your business on Best Business Listing Website for Laundry Services on Mom n Pop Hub for free. This will make people aware of your business. Signup!',
  },
  {
    catname: 'screen-printing',
    titledesc:
      'Make your Screen Printing business stand out by listing it on Mom n Pop Hub. check out the similar businesses already listed on our site.',
    metatitle: 'Best Business Listing Website for Screen Printing - Mom n Pop Hub',
    metadesc:
      'List your screen printing business on Mom n Pop Hub for free. This will make people more aware of your business. Signup now!',
  },
  {
    catname: 'pest-control',
    titledesc:
      'Are you providing Pest Control services  then register your business on Mom n Pop Hub for no charge. Click below for seeing the similar listings.',
    metatitle: 'Add Pest Control Services on Mom n Pop Hub - Join Us Now for Free!',
    metadesc:
      'List your pest control services on Mom n Pop Hub for free. This will make people more aware of your business.. Signup for free today to attract more customers.',
  },
  {
    catname: 'printing-services',
    titledesc:
      'Have you a Business of Printing Services and wants to expand it? then Mom n Pop Hub is the best option for you to list your business for free. Also do visit on below given list to see relatable businesses. ',
    metatitle: 'List Your Printing Services on Mom n Pop Hub - Signup for Free',
    metadesc:
      'If you offers printing services, list it on Mom n Pop Hub for free. This will make people more aware of your business. Sign up for free today!',
  },
  {
    catname: 'watch-repair',
    titledesc:
      'Make your Watch Repair business growing in similar services providing organizations at no cost. List your business on Mom n Pop Hub to get more growth in your customers.',
    metatitle: 'List Watch Repair Services on Mom n Pop Hub - Signup for Free',
    metadesc:
      'List your watch repair services on Mom n Pop Hub. This will make people more aware of your business and services. Visit us for free business signup now! ',
  },
  {
    catname: 'water-delivery',
    titledesc:
      'If you are providing Water Delivery Services then list your business on Mom n Pop Hub. Take a look at the listed categories and sub categories to increase your small online business’s market presence.',
    metatitle: 'Water Delivery Services Near Me - Mom n Pop Hub - Signup Now!',
    metadesc:
      'Add your water delivery services on Mom n Pop Hub for free. This will make people more aware of your business and attract customers. Sign up for free today.',
  },
  {
    catname: 'wildlife-control',
    titledesc:
      'Mom n Pop Hub is the best option for your Wildlife Control services business. Sign up here and list your business without damaging your pocket. also see others listing related to consultants in given list.  ',
    metatitle: 'Submit Wildlife Control Services on Mom n Pop Hub - Signup for Free',
    metadesc:
      'Mom n Pop Hub is a local business listing platform dedicated to help small businesses  find customers. List your Wildlife Control services on Mom n Pop Hub with a free signup.',
  },
  {
    catname: 'adult-entertainment',
    titledesc:
      'Make your adult entertainment business stand out with Mom n Pop Hub. From bars, nightclubs, to pubs, check out the similar businesses already listed on our platform.',
    metatitle: 'Add Your Adult Entertainment Night Life Club - Mom n Pop Hub',
    metadesc:
      'List your Adult Entertainment Night Life Club on Mom n Pop Hub, the ultimate business directory for small businesses. This is a free-of-cost business listing platform.',
  },
  {
    catname: 'bars',
    titledesc:
      'Are you a Bar owner and want more costumers and profit then register your business on Mom n Pop Hub for no charge. Click below for seeing the similar listings.',
    metatitle: 'List Your Bar Store On Mom n Pop Hub - Free Signup Today!',
    metadesc:
      'List your bar store on Mom n Pop Hub if you want to reach out to more potential customers. Visit us for free business signup today!',
  },
  {
    catname: 'beer-gardens',
    titledesc:
      "Wants to list your Beautiful Beer Gardens for a rapid growth in your business then list your business on Mom n Pop Hub for no cost. Check out the other's listing by clicking below.",
    metatitle: 'Free Signup Now For Add Your Beer Gardens on Mom n Pop Hub',
    metadesc:
      'Got a cool beer garden? Then list it on Mom n Pop Hub for free to reach out to more target customers. Visit us today for free sign-up!',
  },
  {
    catname: 'club-crawl',
    titledesc:
      'Get your Club Crawl business standing in our top listings. signup today and list your business on Mom n Pop hub for free. ',
    metatitle: 'Submit Your Club Crawl Store on Mom n Pop Hub -  Join Us Now!',
    metadesc:
      'List your club crawl store on Mom n Pop Hub for free to attract more potential customers. Visit us for free business sign-up!',
  },
  {
    catname: 'comedy-clubs',
    titledesc:
      'Have you a Business of comedy clubs and wants to expand it? then Mom n Pop Hub is the best option for you to list your business for free. Also do visit on below given list to see relatable businesses. ',
    metatitle: 'List Your Comedy Clubs Bars With Mom n Pop Hub - Free Signup Now!',
    metadesc:
      'List your comedy club on Mom n Pop Hub for free if you want to reach out to more target audience. Sign up on our website for free!',
  },
  {
    catname: 'country-dance-halls',
    titledesc:
      'Get listed your Country Dance Halls Club today for free with Mom n Pop Hub which boost your business rapidly. Click below to check out the similar business that are already listed with us.',
    metatitle: 'List Your Country Dance Halls Club On Mom n Pop Hub -  Join Us',
    metadesc:
      'You can list your country dance hall on Mom n Pop Hub. No stress or hassle, sign up today and reach out to more potential audience!',
  },
  {
    catname: 'dance-clubs',
    titledesc:
      'Get more crowd in your Dance Clubs by listing them on Mom n Pop Hub without any cost. Here are the list of other businesses that are already signed up with us.',
    metatitle: 'Add Your Dance Clubs and Bar On Mom n Pop Hub - Free Signup',
    metadesc:
      'Add your astonishing dance club on Mom n Pop Hub for free and attract more potential customers to grow your business. Sign up today!',
  },
  {
    catname: 'music-venues',
    titledesc:
      "List your Music Venues on Mom n Pop Hub and make them more famous in blink's of eyes. Here are the list of all the relatable businesses that have already registered with us.",
    metatitle: 'List  Your Live Music Venues With Mom n Pop Hub - Free Signup',
    metadesc:
      'List your live music venue for free on Mom n Pop Hub to reach out to a more potential target audience. Visit our website for free sign-up!',
  },
  {
    catname: 'piano-bars',
    titledesc:
      'If you have a Piano Bars then list them on Mom n Pop Hub for best growth. Browse the list below and find the relevant information about the Piano Bars.',
    metatitle: 'Submit Your Night life Piano Bars On Mom n Pop Hub - Free Signup',
    metadesc:
      'If you run a Night life Piano Bars then list it on Mom n Pop Hub for free. This will make people more aware of your business .Sign up for free today.',
  },
  {
    catname: 'pool-halls',
    titledesc:
      "Wants to list your Beautiful Pool Halls for a rapid growth in your business then list your business on Mom n Pop Hub for no cost. Check out the other's listing by clicking below.",
    metatitle: 'Free Signup Now For Add Your Pool Halls Center on Mom n Pop Hu ',
    metadesc:
      'If you own or operate a pool halls center, you may list it for free on Mom n Pop Hub. This will raise awareness of your business. Signup today!',
  },
  {
    catname: 'accountants',
    titledesc:
      'If you are an accountant and wants to expand your business then Mom n Pop is the best option. visit us today and signup your business with Mom n Pop hub for free. ',
    metatitle: 'Business Listing for Accountants - Mom n Pop Hub - Join Us Now!',
    metadesc:
      'Free business listing service for professional accountants at Mom n Pop Hub. A user-friendly small business listing platform that helps you reach more customers.',
  },
  {
    catname: 'advertising',
    titledesc:
      'Get listed your adevrtising business today for free with Mom n Pop Hub which boost your business rapidly. Click below to check out the Advertising business that are already listed with us.',
    metatitle: 'Add Your Professional Advertising Services on Mom n Pop Hub',
    metadesc:
      'List your professional Advertising Services on Mom n Pop Hub to attract and retain more customers. Visit us for free business signup!',
  },
  {
    catname: 'architects',
    titledesc:
      'If you wants to list your Architects business for free then Mom n Pop Hub is the best option for you.  Browse the list below and find businesses that are already signup with us.',
    metatitle: 'Architects Business Listing - Mom n Pop Hub - Free Signup!',
    metadesc:
      'Free business listing signup for architects at Mom n Pop Hub! This is the ultimate platform to build a strong base of customers. Visit us to Signup today!',
  },
  {
    catname: 'consultants',
    titledesc:
      'Mom n Pop Hub is the best option for your Consultants Business. Sign up here and list your business without damaging your pocket. also see others listing related to consultants in given list.  ',
    metatitle: 'Business Listing for Consultants - Mom n Pop Hub - Signup Now!',
    metadesc:
      'Give your consulting business a boost with Mom n Pop Hub, the platform offering free listing service to the consultants. Signup today for free!',
  },
  {
    catname: 'billing-services',
    titledesc:
      'Are you a Billing Services provider and wants to grow your Business then Sign up today with Mom n Pop Hub and list your business for free. Visit the given below list to see similar listings.',
    metatitle: 'Professional Billing Services Near Me - Mom n Pop Hub',
    metadesc:
      'Add a FREE listing for your professional billing services at Mom n Pop Hub. Signup today list your business with us and increase your visibilty in your local areas.',
  },
  {
    catname: 'business-consulting',
    titledesc:
      'Grow your Business Consulting firm without any fee by listing your business on Mom n Pop Hub.  Here is the list of businsses that are already signup with us.',
    metatitle: 'A guide to Business Consulting at Any Age with Mom n Pop Hub',
    metadesc:
      'Small business consulting listing service at Mom n Pop Hub for free! If you run a business consulting firm, avail the service with a free signup!',
  },
  {
    catname: 'career-counseling',
    titledesc:
      'Have you a Business of Career Counselling provider and wants to expand it? then Mom n Pop Hub is the best option for you to list your business for free. Also do visit on below given list to see relatable businesses. ',
    metatitle: 'List Your Career Counseling Office On Mom n Pop Hub - Join Us',
    metadesc:
      'If you run a career counseling agency, list it on Mom n Pop Hub. The platform provides the ideal way to reach potential customers. Sign up now!',
  },
  {
    catname: 'customs-brokers',
    titledesc:
      'Get listed your Customs Brokers Business today for free with Mom n Pop Hub which boost your business rapidly. Browse the list below and find the relevant information about Customs Brokers.',
    metatitle: 'Add Your Cutoms Brokers Office On Mom n Pop Hub - Free Signup!',
    metadesc:
      'Claim local business listing for custom brokers at Mom n Pop Hub. The platform offer free business listing service to help custom brokers reach local customers.',
  },
  {
    catname: 'graphic-design',
    titledesc:
      'Are you Owening a Business of Graphic Design and wants more connections and coustomers then sign up today on Mom n Pop Hub for free. Click the given below list for seeing the similar listings.',
    metatitle: 'Free Signup! Submit Your Graphic Design Office On - Mom n Pop Hub',
    metadesc:
      'Free business listing for professional graphic design services at Mom n Pop Hub to promote awareness about your business. Visit us for free business signup.',
  },
  {
    catname: 'internet-service-providers',
    titledesc:
      'Here is the list of Internet Service Providers that listed with Mom n Pop Hub. If you are a business owner of Internet Service Providers and want to grow your business, then list your business with us. ',
    metatitle: 'List Your Internet Service Providers - Mom n Pop Hub Join Us Now',
    metadesc:
      'Business listing at Mom n Pop Hub help Internet Service Providers easily reach local consumers! It is a free of cost business listing platform. Visit us for signup!',
  },
  {
    catname: 'lawyers',
    titledesc:
      'List your Lawyer services for free on Mom n Pop Hub. Here are the list of all the relatable businesses that have already registred with us.',
    metatitle: 'Add Your Lawyers Chamber Office - Mom n Pop Hub',
    metadesc:
      'Add Your Lawyers Chamber Office at Mom n Pop Hub to reach more potential clients. It is a free platform that helps small business owners grow! Sign up today!',
  },
  {
    catname: 'marketing',
    titledesc:
      'Make your Marketing business grow more. Register today on Mom n Pop Hub to list your business free of cost. Here are the list of other businesses that are already signed up with us.',
    metatitle: 'List Your Marketing Business on Mom n Pop Hub - Signup Now!',
    metadesc:
      'List Your Marketing Business for free at Mom n Pop Hub! The platform allows you to reach your target audience on the Internet. Signup now!',
  },
  {
    catname: 'video/film-production',
    titledesc:
      "Have a Video/Film Production business? Sign up for a listing with Mom n Pop Hub today and see your organization's profile rise in the blink of an eye! also see others relatable listing in given list.  ",
    metatitle: 'List Your Video and Film Production Business - Signup Now!',
    metadesc:
      'Add your video and film production services at Mom n Pop Hub to reach more potential clients. It is a free platform that helps small business owners grow! Signup today!',
  },
  {
    catname: 'translation-services',
    titledesc:
      'Providing Translation Services and wants more coustomers? Then list your business on Mom n Pop Hub and see your business growing in no time.',
    metatitle: 'Certified Translation Services Near Me - List Now!',
    metadesc:
      'Professional translation services listing at Mom n Pop Hub for free! Add your business with us and generate more leads for your business. Signup now!',
  },
  {
    catname: 'wholesalers',
    titledesc:
      'Make your Wholesalers business listed in top wholesaling providing organizations at no cost. List your business on Mom n Pop Hub to get more growth in your customers.',
    metatitle: 'Business Listing for Wholesalers - Mom n Pop Hub',
    metadesc:
      'Business Listing for Wholesalers at Mom n Pop Hub! User-friendly and free of charge small business listing service. Sign up today for free',
  },
  {
    catname: 'patent-law',
    titledesc:
      "Mom n Pop Hub provides free space for your Patent Law organization's listing. List your business without damaging your pocket. Here is the list of other businesses that are already signed up with us.",
    metatitle: 'Top Patent Law Firms 2022 Near Me - Mom n Pop Hub',
    metadesc:
      'For patent law firms, We offers the free listing service to help increase their business visibility. Free listing service for small businesses! Signup today!',
  },
  {
    catname: 'product-design',
    titledesc:
      "Are you owner of  Product Designing firm and wants to list your business for more profit then Mom n Pop Hub is the best option for you. List your business here without any fee. Click below to see other's listing. ",
    metatitle: 'Professional Product Design Near Me - Mom n Pop Hub',
    metadesc:
      'If you run a Professional Product Design business, list it on Mom n Pop Hub. The platform provides the ideal way to attract potential customers. Signup now!',
  },
  {
    catname: 'private-investigation',
    titledesc:
      'Get your Private Investigation Business listed for no cost on Mom n Pop Hub and see your business growing rapidly. also visit given below link to see more similar listings.',
    metatitle: 'List Your Private Investigation business Near Me',
    metadesc:
      'List your private investigation services with Mom n Pop Hub, the ultimate one-stop resource for free local listings in your area. Sigup today for free listing!',
  },
  {
    catname: 'web-design',
    titledesc:
      "List your Web Design business free of charge on Mom n pop Hub and make it rise more. Check out the other's listing by clicking below.",
    metatitle: 'List Your Web Design Business on Mom n Pop Hub - Hurry Up!',
    metadesc:
      'List Your Web Design business on Mom n Pop Hub for free! This is a free business listing website for better business visibility. Hurry Up and signup today!',
  },
  {
    catname: 'security-services',
    titledesc:
      'We have a free space for listing your Security Services business. Register on Mom n Pop Hub for no cost to grow your business more. Click below to check out the business listings related to security services.',
    metatitle: 'Security Services Near Me  - Mom n Pop Hub',
    metadesc:
      'Add your security services on Mom n Pop Hub for free and attract more potential customers to grow your business. Signup today!',
  },
  {
    catname: 'software-development',
    titledesc:
      'Are you an owner of a Software Development Business and want more clients and connections then register your business on Mom n Pop Hub for no charge. Click below to see the similar listings.',
    metatitle: 'List Your Software Development Business on Mom n Pop Hub ',
    metadesc:
      'List Your Software Development business on Mom n Pop Hub for free! It is a free business listing website. Signup today with us!',
  },
  {
    categoryname: 'wix',
    catname: 'automotive-parts',
    titledesc:
      'Here is the list of automotive parts online business that listed with Mom n Pop Hub. If you are a business owner for automotive Parts and want to sells products or services to customers through a online store, list your business with us. ',
    metatitle: 'Online Store List for Automotive Parts - Mom n Pop Hub',
    metadesc:
      'At Mom n Pop Hub, list your online automotive parts business with a free signup. Our free business listing website can help you to get more ROI. Signup Now!',
  },
  {
    categoryname: 'independent-website',
    catname: 'automotive-parts',
    titledesc:
        'Here is the list of automotive parts online business that listed with Mom n Pop Hub. If you are a business owner for automotive Parts and want to sells products or services to customers through a online store, list your business with us. ',
    metatitle: 'Automotive Online Store List- Mom n Pop Hub',
    metadesc:
        'Join Mom n Pop Hub to List your online automotive parts business with a complimentary signup. Boost your ROI with our free business listing platform. Sign up now!',
  },
  {
    categoryname: 'shopify-store',
    catname: 'automotive-parts',
    titledesc:
        'Here is the list of automotive parts online business that listed with Mom n Pop Hub. If you are a business owner for automotive Parts and want to sells products or services to customers through a online store, list your business with us. ',
    metatitle: 'Websites List of Automotive Parts- Mom n Pop Hub',
    metadesc:
        'Boost your online automotive parts business with a complimentary listing at Mom n Pop Hub. Sign up for free and maximize your ROI on our business listing platform.',
  },
  {
    categoryname: 'bigcommerce',
    catname: 'automotive-parts',
    titledesc:
        'Here is the list of automotive parts online business that listed with Mom n Pop Hub. If you are a business owner for automotive Parts and want to sells products or services to customers through a online store, list your business with us. ',
    metatitle: 'Online Store List of Automotive Parts- Mom n Pop Hub',
    metadesc:
        'Start boosting your online automotive parts business today with a free listing on Mom n Pop Hub. Maximize your ROI with our complimentary business listing service.',
  },
  {
    categoryname: 'woocommerce',
    catname: 'automotive-parts',
    titledesc:
        'Here is the list of automotive parts online business that listed with Mom n Pop Hub. If you are a business owner for automotive Parts and want to sells products or services to customers through a online store, list your business with us. ',
    metatitle: 'Explore Automotive Parts Online Stores - Mom n Pop Hub',
    metadesc:
        'Join Mom n Pop Hub to showcase your online automotive parts business for free! Boost your ROI with our complimentary business listing service. ',
  },
  {
    categoryname: 'shopify-store',
    catname: 'cbd-&-prodcts',
    titledesc:
      'If you are a business owner that have CBD products and want to sells products to customers through a online store, list your business with us for free. Here are the list of businsses that are already signup with Mom n Pop hub.',
    metatitle: 'Online CBD Products Store List - Mom n Pop Hub',
    metadesc:
      'Successfully sell CBD Products online with free business listing at Mom n Pop Hub! This is the reliable platform exclusive to small businesses! Signuo today!',
  },
  {
    categoryname: 'wix',
    catname: 'cbd-&-prodcts',
    titledesc:
        'If you are a business owner that have CBD products and want to sells products to customers through a online store, list your business with us for free. Here are the list of businsses that are already signup with Mom n Pop hub.',
    metatitle: 'Online Store of CBD & Products- Mom n Pop Hub',
    metadesc:
        'Achieve online success selling CBD Products with a free business listing on Mom n Pop Hub! Join our trusted platform dedicated to small businesses.',
  },
  {
    categoryname: 'woocommerce',
    catname: 'cbd-&-prodcts',
    titledesc:
        'If you are a business owner that have CBD products and want to sells products to customers through a online store, list your business with us for free. Here are the list of businsses that are already signup with Mom n Pop hub.',
    metatitle: 'Websites List of CBD  Online Stroe- Mom n Pop Hub',
    metadesc:
        'Effectively market CBD products online through a free business listing on Mom n Pop Hub! This trustworthy platform is exclusively designed for small businesses. ',
  },
  {
    categoryname: 'bigcommerce',
    catname: 'cbd-&-prodcts',
    titledesc:
        'If you are a business owner that have CBD products and want to sells products to customers through a online store, list your business with us for free. Here are the list of businsses that are already signup with Mom n Pop hub.',
    metatitle: 'Explore Online CBD Stores List- Mom n Pop Hub',
    metadesc:
        'Achieve success selling CBD products online by listing your business for free on Mom n Pop Hub! This reliable platform caters exclusively to businesses. Sign up!',
  },
  {
    categoryname: 'independent-website',
    catname: 'cbd-&-prodcts',
    titledesc:
        'If you are a business owner that have CBD products and want to sells products to customers through a online store, list your business with us for free. Here are the list of businsses that are already signup with Mom n Pop hub.',
    metatitle: 'CBD Online Stores List- Mom n Pop Hub',
    metadesc:
        'Tap into success selling CBD products online with a free business listing on Mom n Pop Hub! Our reliable platform caters exclusively to small businesses.',
  },
  {
    categoryname: 'woocommerce',
    catname: 'clothing',
    titledesc:
      'Best online clothing stores list that list with Mom n Pop Hub. Expand your brand awareness and take your clothing business to a wider audience with Mom n Pop Hub.',
    metatitle: 'Best Online Clothing Store List - Mom n Pop Hub',
    metadesc:
      'Expand your brand awareness and take your clothing business to a wider audience with Mom n Pop Hub. List your business and get more leads with us! Signup today!',
  },
  {
    categoryname: 'bigcommerce',
    catname: 'clothing',
    titledesc:
        'Best online clothing stores list that list with Mom n Pop Hub. Expand your brand awareness and take your clothing business to a wider audience with Mom n Pop Hub.',
    metatitle: 'Clothing: Unique Online Store Finds- Mom n Pop Hub',
    metadesc:
        'Expand your brand awareness and extend the reach of your clothing business by leveraging Mom n Pop Hub. List your business with us to generate more leads.',
  },
  {
    categoryname: 'independent-website',
    catname: 'clothing',
    titledesc:
        'Best online clothing stores list that list with Mom n Pop Hub. Expand your brand awareness and take your clothing business to a wider audience with Mom n Pop Hub.',
    metatitle: 'Clothing Stores Lists at  Mom n Pop Hub',
    metadesc:
        'Increase your brand visibility and reach a broader audience for your clothing business by joining Mom n Pop Hub. List your business with us!',
  },
  {
    categoryname: 'wix',
    catname: 'clothing',
    titledesc:
        'Best online clothing stores list that list with Mom n Pop Hub. Expand your brand awareness and take your clothing business to a wider audience with Mom n Pop Hub.',
    metatitle: 'List of Online Clothing Store- Mom n Pop Hub',
    metadesc:
        'Expand your brand\'s visibility and reach a broader audience for your clothing business by leveraging Mom n Pop Hub. List your business to generate more leads!',
  },
  {
    categoryname: 'shopify-store',
    catname: 'clothing',
    titledesc:
        'Best online clothing stores list that list with Mom n Pop Hub. Expand your brand awareness and take your clothing business to a wider audience with Mom n Pop Hub.',
    metatitle: 'Online Clothing Stores - Mom n Pop Hub',
    metadesc:
        'Boost your brand visibility and expand your reach in the clothing industry with Mom n Pop Hub. Showcase your business and attract more leads by joining us! ',
  },
  {
    categoryname: 'shopify-store',
    catname: 'shoes',
    titledesc:
      'Mom n Pop Hub is a free online directory for your shoes business. List your shoes business with us and Take a look at the listed categories and sub categories to increase your small online business’s market presence.',
    metatitle: 'Online Shoe Stores List - Mom n Pop Hub',
    metadesc:
      'Mom n Pop Hub - The Free online directory for your shoes business! The platform offers free business listing service. Visit us for signup today!',
  },
  {
    categoryname: 'woocommerce',
    catname: 'shoes',
    titledesc:
        'Mom n Pop Hub is a free online directory for your shoes business. List your shoes business with us and Take a look at the listed categories and sub categories to increase your small online business’s market presence.',
    metatitle: 'Shoes Website Stores List - Mom n Pop Hub',
    metadesc:
        'Explore Mom n Pop Hub, the premier online directory for your shoe business! Benefit from our complimentary business listing service. Sign up today!',
  },
  {
    categoryname: 'bigcommerce',
    catname: 'shoes',
    titledesc:
        'Mom n Pop Hub is a free online directory for your shoes business. List your shoes business with us and Take a look at the listed categories and sub categories to increase your small online business’s market presence.',
    metatitle: 'Online Shoe Stores List at Mom n Pop Hub',
    metadesc:
        'Discover Mom n Pop Hub - Your go-to online directory for shoe businesses! Benefit from our free business listing service. Sign up today!',
  },
  {
    categoryname: 'independent-website',
    catname: 'shoes',
    titledesc:
        'Mom n Pop Hub is a free online directory for your shoes business. List your shoes business with us and Take a look at the listed categories and sub categories to increase your small online business’s market presence.',
    metatitle: 'Explore Online Shoe Stores - Mom n Pop Hub',
    metadesc:
        'Explore the possibilities for your shoe business on Mom n Pop Hub - the premier online directory. Unlock free business listings and elevate your brand presence.',
  },
  {
    categoryname: 'wix',
    catname: 'shoes',
    titledesc:
        'Mom n Pop Hub is a free online directory for your shoes business. List your shoes business with us and Take a look at the listed categories and sub categories to increase your small online business’s market presence.',
    metatitle: 'Discover Online Stores for Shoes - Mom n Pop Hub',
    metadesc:
        'Explore Mom n Pop Hub, your ultimate online directory for your shoe business! Take advantage of our free business listing service. Sign up today!',
  },

  {
    catname: 'electricians',
    titledesc:
      'Browse the list below and find the relevant information about the online electronics businesses. If you have not signup yet? visit us today and signup your business with Mom n Pop hub for free. ',
    metatitle: 'Best Online Electronics Store List - Mom n Pop Hub',
    metadesc:
      'Quick and Easy business listing service for online electronics store at Mom n Pop Hub! List your business for free with us ! Sign up today to avail benefits.',
  },
  {
    categoryname: 'independent-website',
    catname: 'books-movies-games',
    titledesc:
      'Running multiple online businesses like Books, Movies, Games etc. Mom n Pop Hub is a free online directory where you can register your online business for free. Browse the list below and find the relevant information about the online books, movies and games.',
    metatitle: 'Online Store List for Books, Movies, Games - Mom n Pop Hub',
    metadesc:
      'Online business listing for Books, Movies and games store at Mom n Pop Hub! Sign up today to gain a strong online presence and earn more profits!',
  },
  {
    categoryname: 'shopify-store',
    catname: 'books-movies-games',
    titledesc:
        'Running multiple online businesses like Books, Movies, Games etc. Mom n Pop Hub is a free online directory where you can register your online business for free. Browse the list below and find the relevant information about the online books, movies and games.',
    metatitle: 'Mom n Pop Hub: Browse Online Selection of Books, Movies, and Games',
    metadesc:
        'Join Mom n Pop Hub\'s business listing website for Books, Movies, and Games stores! Sign up today to strengthen your online presence and boost your profits!',
  },
  {
    categoryname: 'wix',
    catname: 'books-movies-games',
    titledesc:
        'Running multiple online businesses like Books, Movies, Games etc. Mom n Pop Hub is a free online directory where you can register your online business for free. Browse the list below and find the relevant information about the online books, movies and games.',
    metatitle: 'List of Online Stores for Books, Movies, Games - Mom and Pop Hub',
    metadesc:
        'List your Books, Movies, and Games store on Mom n Pop Hub\'s online business listing! Sign up today to strengthen your online presence and boost profits!',
  },
  {
    categoryname: 'bigcommerce',
    catname: 'books-movies-games',
    titledesc:
        'Running multiple online businesses like Books, Movies, Games etc. Mom n Pop Hub is a free online directory where you can register your online business for free. Browse the list below and find the relevant information about the online books, movies and games.',
    metatitle: 'Books, Movies, Games Online Stores List- Mom n Pop Hub',
    metadesc:
        'Discover the online business listing for your Books, Movies, and Games store on Mom n Pop Hub! Register today to boost your online presence and increase profits!',
  },
  {
    categoryname: 'woocommerce',
    catname: 'books-movies-games',
    titledesc:
        'Running multiple online businesses like Books, Movies, Games etc. Mom n Pop Hub is a free online directory where you can register your online business for free. Browse the list below and find the relevant information about the online books, movies and games.',
    metatitle: 'Discover Online Store List Books, Movies, Games- Mom n Pop Hub',
    metadesc:
        'Uncover the online listing for your Books, Movies, and Games store at Mom n Pop Hub! Join today to amplify your online presence and grow your profits!',
  },
  {
    categoryname: 'shopify-store',
    catname: 'cosmetics-&-body-care',
    titledesc:
      'If you are a business owner for cosmetics & body care and want to sells products or services to customers through online, list your business with us for free. Make your cosmetics & body care business more profitable and visible with us.',
    metatitle: 'Cosmetics & Body Care Websites List - Mom n Pop Hub',
    metadesc:
      'Improve the online visibility of your cosmetics and Body Care products with Mom n Pop Hub. Visit us today and list your business for free with us!',
  },
  {
    categoryname: 'woocommerce',
    catname: 'cosmetics-&-body-care',
    titledesc:
        'If you are a business owner for cosmetics & body care and want to sells products or services to customers through online, list your business with us for free. Make your cosmetics & body care business more profitable and visible with us.',
    metatitle: 'Explore Cosmetics & Body Care Sites - Mom n Pop Hub',
    metadesc:
        'Enhance the online presence of your cosmetics and body care products with Mom n Pop Hub. List your business for free today! Visit us now!',
  },
  {
    categoryname: 'bigcommerce',
    catname: 'cosmetics-&-body-care',
    titledesc:
        'If you are a business owner for cosmetics & body care and want to sells products or services to customers through online, list your business with us for free. Make your cosmetics & body care business more profitable and visible with us.',
    metatitle: 'Compilation of Cosmetics and Body Care Sites - Mom n Pop Hub',
    metadesc:
        'Enhance your cosmetics and body care products\' online visibility with Mom n Pop Hub. Join us today to list your business for free and expand your reach!',
  },
  {
    categoryname: 'independent-website',
    catname: 'cosmetics-&-body-care',
    titledesc:
        'If you are a business owner for cosmetics & body care and want to sells products or services to customers through online, list your business with us for free. Make your cosmetics & body care business more profitable and visible with us.',
    metatitle: 'Listing for Cosmetics & Body Care Sites- Mom n Pop Hub ',
    metadesc:
        'Elevate your cosmetics and body care products online presence by joining Mom n Pop Hub. Sign up for free today to lsit your business and increase visibility!',
  },
  {
    categoryname: 'wix',
    catname: 'cosmetics-&-body-care',
    titledesc:
        'If you are a business owner for cosmetics & body care and want to sells products or services to customers through online, list your business with us for free. Make your cosmetics & body care business more profitable and visible with us.',
    metatitle: 'List of Cosmetics & Body Care Sites- Mom n Pop Hub',
    metadesc:
        'Give your cosmetics and body care products a digital edge on Mom n Pop Hub. Sign up for free today to list your business and enhance online visibility!',
  },
  {
    categoryname: 'bigcommerce',
    catname: 'bags-&-accessories',
    titledesc:
      "Sell bags and other accessories online? Sign up for a listing with Mom n Pop Hub today and see the sales soar and your business's profile rise in no time! Here are the list of businsses that are already signup with Mom n Pop hub.",
    metatitle: 'Bags & Accessories Selling Websites - Mom n Pop Hub',
    metadesc:
      'Sell bags and accessories online with listing your business at Mom n Pop Hub, the ultimate platform that helps small businesses increase sales. Sign up now!',
  },
  {
    categoryname: 'woocommerce',
    catname: 'bags-&-accessories',
    titledesc:
        "Sell bags and other accessories online? Sign up for a listing with Mom n Pop Hub today and see the sales soar and your business's profile rise in no time! Here are the list of businsses that are already signup with Mom n Pop hub.",
    metatitle: 'Bags & Accessories Websites - Mom n Pop Hub',
    metadesc:
        'Expand your bag and accessories sales online by listing your business on Mom n Pop Hub. Join the ultimate platform for small businesses to boost sales. Sign up now!',
  },
  {
    categoryname: 'independent-website',
    catname: 'bags-&-accessories',
    titledesc:
        "Sell bags and other accessories online? Sign up for a listing with Mom n Pop Hub today and see the sales soar and your business's profile rise in no time! Here are the list of businsses that are already signup with Mom n Pop hub.",
    metatitle: 'Bags & Accessories Online Shops - Mom n Pop Hub',
    metadesc:
        'Market bags and accessories effectively by listing your business on Mom n Pop Hub. The ultimate platform for small businesses to boost sales. Register today!',
  },
  {
    categoryname: 'shopify-store',
    catname: 'bags-&-accessories',
    titledesc:
        "Sell bags and other accessories online? Sign up for a listing with Mom n Pop Hub today and see the sales soar and your business's profile rise in no time! Here are the list of businsses that are already signup with Mom n Pop hub.",
    metatitle: 'Bags & Accessories: Top Stores Lists - Mom n Pop Hub',
    metadesc:
        'Elevate your bags and accessories sales online by listing your business on Mom n Pop Hub. Join the ultimate platform for small businesses to boost sales. Sign up!',
  },
  {
    categoryname: 'wix',
    catname: 'bags-&-accessories',
    titledesc:
        "Sell bags and other accessories online? Sign up for a listing with Mom n Pop Hub today and see the sales soar and your business's profile rise in no time! Here are the list of businsses that are already signup with Mom n Pop hub.",
    metatitle: 'Best Bags & Accessories Websites List - Mom n Pop Hub',
    metadesc:
        'Maximize your bag and accessory sales online by listing your business on Mom n Pop Hub. Join the ultimate platform for small businesses to boost sales. Sign up now!',
  },
  {
    categoryname: 'woocommerce',
    catname: 'foods-&-drinks',
    titledesc:
      'Food and beverage products business? Now is the time to list your company with Mom n Pop Hub in order to see a rapid increase in sales and revenue for your online business. Here are the list of businsses that are already signup with Mom n Pop hub.',
    metatitle: 'Online Stores for Foods & Drinks - Mom n Pop Hub',
    metadesc:
      'List your online foods & drinks store at Mom n Pop Hub to obtain the global presence. Save money and signup today for free small business listing service!',
  },
  {
    categoryname: 'bigcommerce',
    catname: 'foods-&-drinks',
    titledesc:
        'Food and beverage products business? Now is the time to list your company with Mom n Pop Hub in order to see a rapid increase in sales and revenue for your online business. Here are the list of businsses that are already signup with Mom n Pop hub.',
    metatitle: 'List of Online Food & Drinks Stores- Mom n Pop Hub',
    metadesc:
        'Expand your online food and drink store\'s reach by listing it on Mom n Pop Hub. Sign up for our free small business listing service today and gain global exposure!',
  },
  {
    categoryname: 'independent-website',
    catname: 'foods-&-drinks',
    titledesc:
        'Food and beverage products business? Now is the time to list your company with Mom n Pop Hub in order to see a rapid increase in sales and revenue for your online business. Here are the list of businsses that are already signup with Mom n Pop hub.',
    metatitle: 'Search Food & Drink Online Stores List- Mom n Pop Hub ',
    metadesc:
        'Maximize your online foods & drinks store\'s visibility with Mom n Pop Hub. Join now for free and unlock global opportunities for your small business!',
  },
  {
    categoryname: 'shopify-store',
    catname: 'foods-&-drinks',
    titledesc:
        'Food and beverage products business? Now is the time to list your company with Mom n Pop Hub in order to see a rapid increase in sales and revenue for your online business. Here are the list of businsses that are already signup with Mom n Pop hub.',
    metatitle: 'Online Stores List for Foods & Drinks- Mom n Pop Hub',
    metadesc:
        'Unlock global exposure for your online foods & drinks business by enlisting it on Mom n Pop Hub. Join now for free and amplify your small business\'s presence!',
  },
  {
    categoryname: 'wix',
    catname: 'foods-&-drinks',
    titledesc:
        'Food and beverage products business? Now is the time to list your company with Mom n Pop Hub in order to see a rapid increase in sales and revenue for your online business. Here are the list of businsses that are already signup with Mom n Pop hub.',
    metatitle: 'Foods & Drinks Online Store Websites - Mom n Pop Hub',
    metadesc:
        'Boost your online foods & drinks store\'s global presence by listing it on Mom n Pop Hub. Sign up now for our free small business listing service and save money!',
  },
  {
    categoryname: 'independent-website',
    catname: 'household-appliances',
    titledesc:
      'Run a house hold appliances products business online? Get listed your business today for free with Mom n Pop Hub which can increase your sales and boost your business rapidly. Click below to check out the household appliances for business listings.',
    metatitle: 'List of Household Appliances Online Websites - Mom n Pop Hub',
    metadesc:
      'Enhance your household appliances purchase with free business listing at Mom n Pop Hub! Visit us give your online business a much needed boost!',
  },
  {
    categoryname: 'bigcommerce',
    catname: 'household-appliances',
    titledesc:
        'Run a house hold appliances products business online? Get listed your business today for free with Mom n Pop Hub which can increase your sales and boost your business rapidly. Click below to check out the household appliances for business listings.',
    metatitle: 'Household Appliances Websites List - Mom n Pop Hub',
    metadesc:
        'Boost your household appliance purchases with a complimentary business listing on Mom n Pop Hub! Visit us today to give your online business the boost it needs!',
  },
  {
    categoryname: 'woocommerce',
    catname: 'household-appliances',
    titledesc:
        'Run a house hold appliances products business online? Get listed your business today for free with Mom n Pop Hub which can increase your sales and boost your business rapidly. Click below to check out the household appliances for business listings.',
    metatitle: 'Online List of Household Appliances Websites- Mom n Pop Hub',
    metadesc:
        'Elevate your household appliances shopping experience by securing a complimentary business listing on Mom n Pop Hub! Visit us to give your online business the boost.',
  },
  {
    categoryname: 'wix',
    catname: 'household-appliances',
    titledesc:
        'Run a house hold appliances products business online? Get listed your business today for free with Mom n Pop Hub which can increase your sales and boost your business rapidly. Click below to check out the household appliances for business listings.',
    metatitle: 'Household Appliances Online Sotres List- Mom n Pop Hub',
    metadesc:
        'Boost your household appliances purchase with a complimentary business listing on Mom n Pop Hub! Visit us to give your online business the boost it deserves!',
  },
  {
    categoryname: 'shopify-store',
    catname: 'household-appliances',
    titledesc:
        'Run a house hold appliances products business online? Get listed your business today for free with Mom n Pop Hub which can increase your sales and boost your business rapidly. Click below to check out the household appliances for business listings.',
    metatitle: 'Discover Household Appliance Websites - Mom n Pop Hub',
    metadesc:
        'Improve your household appliances purchase with a free business listing on Mom n Pop Hub! Visit us to give your online business the much-needed boost it deserves!',
  },
  {
    categoryname: 'bigcommerce',
    catname: 'furniture-&-household-goods',
    titledesc:
      'Have an online Furniture & Household goods business? For free, list your business with Mom n Pop Hub, which can help your business grow quickly and increase your sales. Here is the list of businsses that are already signup with Mom n Pop hub.',
    metatitle: 'Online Stores for Furniture & Household Goods - Mom n Pop Hub',
    metadesc:
      'Sell furniture & household goods through free listing service at Mom n Pop Hub. No investment required, just a hassle-free sign up to list your business online',
  },
  {
    categoryname: 'shopify-store',
    catname: 'furniture-&-household-goods',
    titledesc:
        'Have an online Furniture & Household goods business? For free, list your business with Mom n Pop Hub, which can help your business grow quickly and increase your sales. Here is the list of businsses that are already signup with Mom n Pop hub.',
    metatitle: 'Furniture & Houehold Goods Websites List - Mom n Pop Hub',
    metadesc:
        'Achieve success selling CBD products online by listing your business for free on Mom n Pop Hub! This reliable platform caters exclusively to businesses. Sign up!',
  },
  {
    categoryname: 'woocommerce',
    catname: 'furniture-&-household-goods',
    titledesc:
        'Have an online Furniture & Household goods business? For free, list your business with Mom n Pop Hub, which can help your business grow quickly and increase your sales. Here is the list of businsses that are already signup with Mom n Pop hub.',
    metatitle: 'List of Furniture & Household Goods Online Stores',
    metadesc:
        'Promote your furniture and household goods with a free listing on Mom n Pop Hub. No investment needed, just a simple sign-up for hassle-free online business listing.',
  },
  {
    categoryname: 'wix',
    catname: 'furniture-&-household-goods',
    titledesc:
        'Have an online Furniture & Household goods business? For free, list your business with Mom n Pop Hub, which can help your business grow quickly and increase your sales. Here is the list of businsses that are already signup with Mom n Pop hub.',
    metatitle: 'Online Stores List of Furniture & Household Goods- Mom n Pop Hub',
    metadesc:
        'Elevate your furniture & household goods sales with Mom n Pop Hub\'s no-cost listing service. Effortlessly showcase your business online by signing up today!',
  },
  {
    categoryname: 'independent-website',
    catname: 'furniture-&-household-goods',
    titledesc:
        'Have an online Furniture & Household goods business? For free, list your business with Mom n Pop Hub, which can help your business grow quickly and increase your sales. Here is the list of businsses that are already signup with Mom n Pop hub.',
    metatitle: 'Furniture & Household Goods Stores List- Mom n Pop Hub',
    metadesc:
        'Expand your market for furniture & household goods with Mom n Pop Hub\'s free listing service. Start listing your business online today!',
  },
  {
    categoryname: 'bigcommerce',
    catname: 'sports-&-outdoor',
    titledesc:
      'Are you in the business of selling sporting goods and outdoor gear online? Get your business listed on Mom n Pop Hub right away. Here is the list of businsses that are already signup with Mom n Pop hub.',
    metatitle: 'Best Sports & Outdoor Websites - Mom n Pop Hub',
    metadesc:
      'Generate more leads and build customer loyalty by listing your Sports & Outdoor business online at Mom n Pop Hub. Signup today for free!',
  },
  {
    categoryname: 'independent-website',
    catname: 'sports-&-outdoor',
    titledesc:
        'Are you in the business of selling sporting goods and outdoor gear online? Get your business listed on Mom n Pop Hub right away. Here is the list of businsses that are already signup with Mom n Pop hub.',
    metatitle: 'Top Sports & Outdoor Sites - Mom n Pop Hub',
    metadesc:
        'Increase your customer base and foster loyalty by registering your Sports & Outdoor business on Mom n Pop Hub. Join us today!',
  },
  {
    categoryname: 'wix',
    catname: 'sports-&-outdoor',
    titledesc:
        'Are you in the business of selling sporting goods and outdoor gear online? Get your business listed on Mom n Pop Hub right away. Here is the list of businsses that are already signup with Mom n Pop hub.',
    metatitle: 'Websites Lists of Sports & Outdoor- Mom n Pop Hub',
    metadesc:
        'Boost your Sports & Outdoor business by listing it on Mom n Pop Hub, generating more leads and nurturing customer loyalty. Sign up today for free!',
  },
  {
    categoryname: 'shopify-store',
    catname: 'sports-&-outdoor',
    titledesc:
        'Are you in the business of selling sporting goods and outdoor gear online? Get your business listed on Mom n Pop Hub right away. Here is the list of businsses that are already signup with Mom n Pop hub.',
    metatitle: 'Discover Sports & Outdoor Websites - Mom n Pop Hub',
    metadesc:
        'Enhance your Sports & Outdoor business by listing it on Mom n Pop Hub, attracting more leads and customer loyalty. Sign up for free!',
  },
  {
    categoryname: 'woocommerce',
    catname: 'sports-&-outdoor',
    titledesc:
        'Are you in the business of selling sporting goods and outdoor gear online? Get your business listed on Mom n Pop Hub right away. Here is the list of businsses that are already signup with Mom n Pop hub.',
    metatitle: 'Lists of Sports & Outdoor Websites- Mom n Pop Hub',
    metadesc:
        'Enhance your Sports & Outdoor business\'s visibility and customer connections by listing it on Mom n Pop Hub. Take advantage of our free sign-up offer today!',
  },
  {
    categoryname: 'independent-website',
    catname: 'toys-&-baby-products',
    titledesc:
      'Are you are in the toy and baby product busines? Now is the time to list your company with Mom n Pop Hub in order to see a rapid increase in sales and revenue for your online business. Here are the list of businsses that are already signup with Mom n Pop hub.',
    metatitle: 'Toys & Baby Products Online Store List - Mom n Pop Hub',
    metadesc:
      'Toys & Baby Products promotion online with free business listing service at Mom n Pop Hub! Sell useful products and make big profits from your online business.',
  },
  {
    categoryname: 'shopify-store',
    catname: 'toys-&-baby-products',
    titledesc:
        'Are you are in the toy and baby product busines? Now is the time to list your company with Mom n Pop Hub in order to see a rapid increase in sales and revenue for your online business. Here are the list of businsses that are already signup with Mom n Pop hub.',
    metatitle: 'Toys & Baby Websites List of Online Store- Mom n Pop Hub',
    metadesc:
        'Boost online sales of Toys & Baby Products! List on Mom n Pop Hub for free. Elevate your business and increase profits today!',
  },
  {
    categoryname: 'bigcommerce',
    catname: 'toys-&-baby-products',
    titledesc:
        'Are you are in the toy and baby product busines? Now is the time to list your company with Mom n Pop Hub in order to see a rapid increase in sales and revenue for your online business. Here are the list of businsses that are already signup with Mom n Pop hub.',
    metatitle: 'Online Stores List of Toys & Baby Products',
    metadesc:
        'Amplify your Toys & Baby Products sales online through Mom n Pop Hub\'s free business listing! Expand your reach and increase profits effortlessly.',
  },
  {
    categoryname: 'woocommerce',
    catname: 'toys-&-baby-products',
    titledesc:
        'Are you are in the toy and baby product busines? Now is the time to list your company with Mom n Pop Hub in order to see a rapid increase in sales and revenue for your online business. Here are the list of businsses that are already signup with Mom n Pop hub.',
    metatitle: 'List of Toys & Products Online Stores- Mom n Pop Hub',
    metadesc:
        'Drive sales for your Toys & Baby Products online with a free listing on Mom n Pop Hub! Expand your reach and increase profits effortlessly.',
  },
  {
    categoryname: 'wix',
    catname: 'toys-&-baby-products',
    titledesc:
        'Are you are in the toy and baby product busines? Now is the time to list your company with Mom n Pop Hub in order to see a rapid increase in sales and revenue for your online business. Here are the list of businsses that are already signup with Mom n Pop hub.',
    metatitle: 'Online Stores Websites List of Toys & Products',
    metadesc:
        'Maximize your online presence for Toys & Baby Products with Mom n Pop Hub\'s free listing service! Increase sales and connect with more customers now.',
  },
  {
    categoryname: 'independent-website',
    catname: 'stationary-&-hobby-supplies',
    titledesc:
      "Have a stationery and hobby supplies business? Sign up for a listing with Mom n Pop Hub today and see your sales soar and your company's profile rise in no time! Here are the list of businsses that are already signup with Mom n Pop hub.",
    metatitle: 'Online Store List for Stationary & Hobby Supplies - Mom n Pop Hub',
    metadesc:
      'Do online business listing at Mom n Pop Hub and profitably sell stationary and hobby supplies! Signup today to avail benefits and to list your business for free!',
  },
  {
    categoryname: 'shopify-store',
    catname: 'stationary-&-hobby-supplies',
    titledesc:
        "Have a stationery and hobby supplies business? Sign up for a listing with Mom n Pop Hub today and see your sales soar and your company's profile rise in no time! Here are the list of businsses that are already signup with Mom n Pop hub.",
    metatitle: 'List of Stationary & Hobby Supplies Stores - Mom n Pop Hub',
    metadesc:
        'List your stationary and hobby supply business on Mom n Pop Hub and start making profits! Sign up today to enjoy exclusive benefits and list your business for free!',
  },
  {
    categoryname: 'woocommerce',
    catname: 'stationary-&-hobby-supplies',
    titledesc:
        "Have a stationery and hobby supplies business? Sign up for a listing with Mom n Pop Hub today and see your sales soar and your company's profile rise in no time! Here are the list of businsses that are already signup with Mom n Pop hub.",
    metatitle: 'Stationary & Hobby Supplies Online Stores List - Mom n Pop Hub',
    metadesc:
        'Explore the world of online business listings on Mom n Pop Hub and discover a profitable opportunity to sell stationery and hobby supplies! Join us today and list your business for free.',
  },
  {
    categoryname: 'wix',
    catname: 'stationary-&-hobby-supplies',
    titledesc:
        "Have a stationery and hobby supplies business? Sign up for a listing with Mom n Pop Hub today and see your sales soar and your company's profile rise in no time! Here are the list of businsses that are already signup with Mom n Pop hub.",
    metatitle: 'Explore Online Stores List Stationary & Hobby Supplies',
    metadesc:
        'List your stationary and hobby supplies business on Mom n Pop Hub and start making profits online! Sign up today to enjoy benefits and list your business for free!',
  },
  {
    categoryname: 'bigcommerce',
    catname: 'stationary-&-hobby-supplies',
    titledesc:
        "Have a stationery and hobby supplies business? Sign up for a listing with Mom n Pop Hub today and see your sales soar and your company's profile rise in no time! Here are the list of businsses that are already signup with Mom n Pop hub.",
    metatitle: 'Online Stores List of Stationary & Hobby Supplies - Mom n Pop Hub',
    metadesc:
        'Start your online business listing on Mom n Pop Hub and start making profits selling stationary and hobby supplies! Sign up today to take advantage of benefits.',
  },
  {
    categoryname: 'shopify-store',
    catname: 'diy-graden-&-pets',
    titledesc:
      'Own a DIY, gardening, or pet products businesses online? Get your business listed on Mom n Pop Hub right away and watch your revenue grow. Here are the list of businsses that are already signup with Mom n Pop hub.',
    metatitle: 'DIY, Garden & Pets Websites List - Mom n Pop Hub',
    metadesc:
      'Expand your DIY, Garden & Pets business with listing it free on Mom n Pop Hub. Visit us today and list your online small business for free!',
  },
  {
    categoryname: 'woocommerce',
    catname: 'diy-graden-&-pets',
    titledesc:
        'Own a DIY, gardening, or pet products businesses online? Get your business listed on Mom n Pop Hub right away and watch your revenue grow. Here are the list of businsses that are already signup with Mom n Pop hub.',
    metatitle: 'Lists of DIY, Garden and Pets Sites- Mom n Pop Hub',
    metadesc:
        'Grow your DIY, Garden & Pets business by listing it for free on Mom n Pop Hub. Visit us today and list your small online business for free!',
  },
  {
    categoryname: 'wix',
    catname: 'diy-graden-&-pets',
    titledesc:
        'Own a DIY, gardening, or pet products businesses online? Get your business listed on Mom n Pop Hub right away and watch your revenue grow. Here are the list of businsses that are already signup with Mom n Pop hub.',
    metatitle: 'Website Lisiting of DIY, Garden & Pets - Mom n Pop Hub',
    metadesc:
        'Boost your DIY, Garden & Pets enterprise by showcasing it for free on Mom n Pop Hub. Head over to our website today and register your online small business.',
  },
  {
    categoryname: 'bigcommerce',
    catname: 'diy-graden-&-pets',
    titledesc:
        'Own a DIY, gardening, or pet products businesses online? Get your business listed on Mom n Pop Hub right away and watch your revenue grow. Here are the list of businsses that are already signup with Mom n Pop hub.',
    metatitle: 'DIY, Garden & Pets Listing- Mom n Pop Hub- Sign Up!',
    metadesc:
        'Enhance your business in DIY, gardening, and pet care by registering for free on Mom n Pop Hub. Explore our platform now and showcase your online business.',
  },
  {
    categoryname: 'independent-website',
    catname: 'diy-graden-&-pets',
    titledesc:
        'Own a DIY, gardening, or pet products businesses online? Get your business listed on Mom n Pop Hub right away and watch your revenue grow. Here are the list of businsses that are already signup with Mom n Pop hub.',
    metatitle: 'Sites List of DIY, Garden & Pets Websites- Mom n Pop Hub',
    metadesc:
        'Boost your DIY, Garden & Pets enterprise by featuring it for free on Mom n Pop Hub. Drop by our platform now and showcase your online small business at no cost!',
  },
  {
    categoryname: 'woocommerce',
    catname: 'other',
    titledesc:
      'Here are the list of other businsses that are already signup with Mom n Pop hub. Signup today and list your business today for free.',
    metatitle: 'Other Online Business Websites List - Mom n Pop Hub',
    metadesc:
      'Get more leads or sales for your business with Mom n Pop Hub. This is a free business listing platform that helps small businesses get the attention they deserve.',
  },
  {
    categoryname: 'shopify-store',
    catname: 'other',
    titledesc:
        'Here are the list of other businsses that are already signup with Mom n Pop hub. Signup today and list your business today for free.',
    metatitle: 'Discover the List of Online Business - Mom n Pop Hub',
    metadesc:
        'Increase your business\'s leads and sales with Mom n Pop Hub! Our free business listing platform empowers businesses to collect the attention they deserve.',
  },
  {
    categoryname: 'bigcommerce',
    catname: 'other',
    titledesc:
        'Here are the list of other businsses that are already signup with Mom n Pop hub. Signup today and list your business today for free.',
    metatitle: 'Website List of Other Online Business - Mom n Pop Hub',
    metadesc:
        'Boost your business leads and sales with Mom n Pop Hub! Our free business listing platform is designed to give all businesses the visibility they need to thrive.',
  },
  {
    categoryname: 'independent-website',
    catname: 'other',
    titledesc:
        'Here are the list of other businsses that are already signup with Mom n Pop hub. Signup today and list your business today for free.',
    metatitle: 'Othe Online Business Lists - Mom n Pop Hub ',
    metadesc:
        'Elevate your business\'s leads and sales with Mom n Pop Hub. Experience the power of our free listing platform designed to spotlight businesses.',
  },
  {
    categoryname: 'wix',
    catname: 'other',
    titledesc:
        'Here are the list of other businsses that are already signup with Mom n Pop hub. Signup today and list your business today for free.',
    metatitle: 'Other Online Business Category Lists - Mom n Pop Hub ',
    metadesc:
        'Supercharge your business growth with Mom n Pop Hub. Our free platform gives small businesses the spotlight they deserve. Boost leads and sales today!',
  },
  {
    catname: 'self-defense-classes',
    titledesc:
      'If you are a Self-defense Classes provider and want to expand your business then Mom n Pop is the best option. visit us today and signup your business with Mom n Pop hub for free...',
    metatitle: 'Self-defense Classes Near Me - Mom n Pop Hub',
    metadesc:
      'Add your self-defense classes on Mom n Pop Hub and attract  more potential local audience for free. Visit us today for free business signup.',
  },
  {
    catname: 'bungee-jumping',
    titledesc:
      'Grow your Bungee Jumping business without any fee by listing your business on Mom n Pop Hub. Here is the list of businesses that are already signup with us.',
    metatitle: 'List Your Bungee Jumping Physical Store with Mom n Pop Hub',
    metadesc:
      "Don't wait and list your Bungee Jumping store on Mom n Pop Hub to attract more local customers. Visit our website now and signup for free!",
  },
];

export default SubcategoryMeta;
