import './notfound.css';

import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = ({ staticContext = {} }) => {
  staticContext.status = 404;

  return (
    <>
      <div className='oops'>
        <h1>OOPS!</h1>
      </div>
      <div className='pageNotFound'>
        <h2>404 - Page not Found</h2>
      </div>
      <div className='msg'>
        <p>
          The page you are looking for might have been removed had its name changed or is
          temporarily unavailable.
        </p>
      </div>
      <div className='homePage'>
        <Link to='/'>
          <button>Visit Home Page</button>
        </Link>
      </div>
    </>
  );
};

export default NotFoundPage;
