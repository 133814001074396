import './store_header.css';

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import Breadcrumb from '../../../components/breadcrumbs/breadcrumb';
import Button from '../../../components/button/Button';
import ContactUs from '../../../components/contact-us';
import categorydata from '../../../pages/business/components/categorydata';
import onlinecategorydata from '../../../pages/business/components/onlinecategorydata';
import StoreHeader from './store_header';

const Categorylist = (props) => {
  const { type } = useParams();
  console.log(type);
  useEffect(() => {
    props.setWhere('Business');
  }, []);

  let data;
  if (type === 'physical-store') {
    data = categorydata;
  } else {
    data = onlinecategorydata;
  }
  const catCount = data.category.length;

  const _onButtonClick = () => {
    setCategoryList(true);
  };

  let desc = '';
  const metaDesc = data.category.map((item) => {
    desc += item.name + ' ';
    return desc;
  });
  const metaDescription = metaDesc.join().substring(0, 160);

  return (
    <>
      <Helmet>
        {type === 'physical-store' ? (
          <title>Running 1 a physical store list your business with Mom n Pop hub</title>
        ) : (
          <title>Running a online business - list your business with Mom n Pop hub</title>
        )}
        {type === 'physical-store' ? (
          <meta
            name='description'
            content='If you are a business owner who sells products or services to customers through a physical store, list your 
        business with Mom n Pop hub. Signup today.'
          />
        ) : (
          <meta
            name='description'
            content='If you are a business owner who 
        sells products or services to customers through online website, list your business with Mom n Pop hub. Signup today.'
          />
        )}

        {/* <meta name="description" content={metaDescription}/> */}
        <meta name='keywords' content='Business Mom n Pop Hub, Profile Mom n Pop Hub' />
        <meta name='publisher' content='Mom n Pop Hub' />
        <meta property='twitter:title' content={type + ' ' + 'STORE BUSINESS CATEGORIES'} />
        <meta property='twitter:description' content={metaDescription} />
        <meta property='twitter:site' content='twitter id' />
        <meta property='og:title' content={type + ' ' + 'STORE BUSINESS CATEGORIES'} />
        <meta property='og:description' content={metaDescription} />
        <meta property='og:publisher' content='Mom n Pop Hub' />
        <meta property='og:locale' content='en_US' />
        <meta property='og:type' content='website' />
        <meta property='og:site_name' content={process.env.RAZZLE_RUNTIME_FRONTBASE_URL} />
        <link rel='canonical' href={`/business-category/${type}`} />
      </Helmet>
      <Breadcrumb />
      <div className='container'>
        <StoreHeader type={type} catcout={catCount} />
        <div className='block'>
          <div className='d-flex flex-wrap'>
            {data.category.map((cats, index) => {
              return (
                <div
                  key={index}
                  className='col-md-4 col-lg-3 col-sm-6 col-xs-12'
                  style={{ marginBottom: '30px' }}
                >
                  <Button name={cats.name} link={cats.machine_name} large={true} type={type} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <ContactUs />
    </>
  );
};

export default Categorylist;
