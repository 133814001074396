import './Customer-business-app.css';

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import apps from './../../assets/images/social/app.png';
import googlePlay from './../../assets/images/social/google-play.png';

const CustomerBusinessApp = (props) => {
  useEffect(() => {
    props.setWhere('CustomerApp');
  }, []);
  return (
    <>
      <Helmet>
        <title>
          Online Deals - Get the Great Deals ongoing by small business near to your home
        </title>
        <meta name="robots" content="index, follow" />
        <meta name='keywords' content='Customer Mom n Pop Hub, Business Mom n Pop Hub'></meta>
        <meta name='publisher' content='Mom n Pop Hub'></meta>
        <link rel='canonical' href='/customer-business-app' />
        <meta name='publisher' content='Mom n Pop Hub'></meta>
        <meta
          name='description'
          content='Download the Mom n Pop Hub Mobile app to find ongoing great deals by small businesses near to your home'
        ></meta>

        <meta
          property='twitter:title'
          content='Online Deals - Get the Great Deals ongoing by small business near to your home'
        />
        <meta
          property='twitter:description'
          content='Download the Mom n Pop Hub Mobile app to find ongoing great deals by small businesses near to your home'
        />
        <meta
          property='twitter:image'
          content='https://momnpophub.com/static/media/momnpophub-logo.1f15802f.svg'
        />
        <meta
          property='twitter:card'
          content="We have single app for both small business owners AND the customers who buy from small businesses. Download the app from app store to advertise your small business and its's deal for FREE. If you're a customer, use the same app redeem deal at a small business nearby or nationally for FREE."
        />
        <meta property='twitter:site' content='twitter id' />

        <meta
          property='og:title'
          content='Online Deals - Get the Great Deals ongoing by small business near to your home'
        />
        <meta
          property='og:description'
          content='Download the Mom n Pop Hub Mobile app to find ongoing great deals by small businesses near to your home'
        />
        <meta property='og:publisher' content='Mom n Pop Hub' />
        <meta property='og:locale' content='en_US' />
        <meta property='og:type' content='website' />
        <meta
          property='og:image'
          content='https://momnpophub.com/static/media/momnpophub-logo.1f15802f.svg'
        />
        <meta property='og:site_name' content='https://momnpophub.com/' />
        <meta property='og:url' content='/customer-business-app' />
        <meta property='og:url' content='https://momnpophub.com/customer-business-app' />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@type': 'SoftwareApplication',
            name: 'Mom n Pop Hub',
            operatingSystem: ['ANDROID', 'IOS'],
            applicationCategory: 'BusinessApplication',
            aggregateRating: {
              '@type': 'AggregateRating',
              ratingValue: '4.6',
              ratingCount: '8864',
            },
            '@context': 'https://schema.org',
          })}
        </script>
      </Helmet>
      <section className='customer-business-container container p-0'>
        <div className='col-lg-10 mx-auto text-center'>
          <h1 className='customer-business-container__title'>Customer Mobile App</h1>
          <p className='customer-business-container__desc'>
            Download our <span> FREE </span> mobile app to find a small business around you or
            online. Our mobile app also has exclusive deals you can buy from a small business around
            you.
          </p>
          <div className='d-flex flex-wrap align-items-center justify-content-center justify-content-center'>
            <div className=' mx-2  mb-3'>
              <a
                href='https://apps.apple.com/us/app/mom-n-pop-hub/id1494101666 '
                className='d-block'
              >
                <img src={apps} alt='IOS App  Mom n Pop Hub' />
              </a>
            </div>
            <div className=' mx-2  mb-3'>
              <a
                href='https://play.google.com/store/apps/details?id=com.momnpophub'
                className='d-block mb-0'
              >
                <img src={googlePlay} alt='Andriod App  Mom n Pop Hub' />
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CustomerBusinessApp;
