import './Block-header.css';

import Cookies from 'js-cookie';
import React from 'react';
import { Button } from 'react-bootstrap';

import { callApi } from '../../api/API';

const BlockHeader = ({
  title,
  formclicked,
  buttonText,
  buttonIcon,
  handler,
  edit,
  subscriptionPurchased,
}) => {
  const purchaseSubscription = () => {
    const token = Cookies.get('authtoken');

    let apiRoute = '/users/purchase-subscription';
    if (subscriptionPurchased) {
      apiRoute = '/stripe/create-portal-session';
    }

    callApi(apiRoute, 'POST', undefined, `Bearer ${token}`)
      .then((data) => {
        console.log(data.url);
        if (data?.url) window.location.href = data.url;
      })
      .catch((err) => {
        console.log(err?.response?.data);
      });
  };

  let icon = buttonIcon ? `${buttonIcon} orange-btn edit-btn` : null;
  let editButton = edit ? (
    <>
      <Button
        type='button'
        className='btn border-0 bg-transparent shadow-none p-0 d-flex align-items-center mr-3'
        onClick={() => handler(false)}
      >
        <i className='icon-times-circle light-gray d-flex' />
        <span className='light-gray'> Cancel</span>
      </Button>
      <Button
        type='button'
        className='btn border-0 bg-transparent shadow-none p-0'
        onClick={() => handler(false)}
      >
        <i className={icon} />
        <span className='orange-btn'>{'Save changes'}</span>
      </Button>
    </>
  ) : (
    <Button
      type='button'
      className='btn border-0 bg-transparent shadow-none p-0'
      onClick={() => handler(false)}
    >
      <i className={icon} />
      <span className='orange-btn'>{buttonText}</span>
    </Button>
  );

  return (
    <header className='d-flex flex-wrap justify-content-between align-items-center secttion-container__header'>
      <h2 className='title m-0  d-flex align-items-center'>{title}</h2>
      {/* <div className="d-flex">{subscriptionPurchased?"Membership" : "Purchased Membership"}</div> */}
      {/* {subscriptionPurchased &&
        typeof subscriptionPurchased !== "undefined" && (
          <Button onClick={purchaseSubscription}>
            {subscriptionPurchased
              ? "Manage Membership"
              : "Purchase Membership"}
          </Button>
        )} */}
        {title==="Deals" && editButton}
    </header>
  );
};

export default BlockHeader;
