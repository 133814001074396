import axios from 'axios';
import queryString from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router-dom';

const Unsubscribe = () => {
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    let query = queryString.parse(location.search);
    axios
      .post(process.env.RAZZLE_RUNTIME_APP_BASEURL + '/users/unsubscribed/', {
        userid: query.userid,
      })
      .then((res) => {
        if (res.status === 200) {
        }
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>Unsubscribe</title>
        <meta name='description' content='Unsubscribe Business'></meta>
        <meta name='keywords' content='Unsubscribe, Mom n Pop Hub'></meta>
        <meta name='publisher' content='Mom n Pop Hub'></meta>

        <link rel='canonical' href='/unsubscribe' />
      </Helmet>
      <div className='logo-container'>
        <div className=' col-xl-11 mx-auto'>
          <div className='row flex-column flex-md-row'>
            <section className='col-md-6'>
              <h1 className='general-title'>Unsubscribed</h1>
              <p className='description'>You have been unsubcribe successfully</p>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};
export default Unsubscribe;
