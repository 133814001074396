import React from 'react';

const DateIcon = (props) => (
  <svg width={17} height={19} viewBox='0 0 17 19' fill='none' {...props}>
    <path
      d='M0.625 16.5625C0.625 17.5117 1.36328 18.25 2.3125 18.25H14.6875C15.6016 18.25 16.375 17.5117 16.375 16.5625V7H0.625V16.5625ZM2.875 9.8125C2.875 9.53125 3.12109 9.25 3.4375 9.25H13.5625C13.8438 9.25 14.125 9.53125 14.125 9.8125V12.0625C14.125 12.3789 13.8438 12.625 13.5625 12.625H3.4375C3.12109 12.625 2.875 12.3789 2.875 12.0625V9.8125ZM14.6875 2.5H13V0.8125C13 0.53125 12.7188 0.25 12.4375 0.25H11.3125C10.9961 0.25 10.75 0.53125 10.75 0.8125V2.5H6.25V0.8125C6.25 0.53125 5.96875 0.25 5.6875 0.25H4.5625C4.24609 0.25 4 0.53125 4 0.8125V2.5H2.3125C1.36328 2.5 0.625 3.27344 0.625 4.1875V5.875H16.375V4.1875C16.375 3.27344 15.6016 2.5 14.6875 2.5Z'
      fill='#D0D0D0'
    />
  </svg>
);

export default DateIcon;
