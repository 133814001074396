import React, { useEffect, useState } from 'react';
import Blog from '../blog';
import Cookies from 'js-cookie';
import { userInfo } from '../../api/blog';

const AdminPanelBlog = () => {
  const [userData, setUserData] = useState(null);

  const authToken = Cookies.get('authtoken');
  useEffect(async () => {
    if (authToken) {
      try {
        const usId = Cookies.get('id');
        const fetchUserInfo = await userInfo(usId);
        await setUserData(fetchUserInfo?.user_editor);
      } catch (err) {
        setUserData(false);
      }
    }
  }, [authToken]);

  return <>{userData && <Blog userEditor={userData} />}</>;
};

export default AdminPanelBlog;
