import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import Arrowimg from '../../assets/images/logo/icon_arrow-01.svg';
import categorydata from '../../pages/business/components/categorydata';
import onlinecategorydata from '../../pages/business/components/onlinecategorydata';

const physicalcategorylist = categorydata.category.map((category) => {
  let subcat = category.subcategory.map((subcategory) => {
    return (
      <li
        style={{
          listStyle: 'none',
          marginLeft: 150,
        }}
      >
        <Link
          to={
            'business-category/physical-store/' +
            category.machine_name +
            '/' +
            subcategory.machine_name
          }
        >
          <img src={Arrowimg} width='12' />
          {subcategory.name}
        </Link>
      </li>
    );
  });
  return (
    <>
      <p style={{ marginLeft: 100, listStyle: 'square' }}>
        <li>
          <Link to={'business-category/physical-store/' + category.machine_name}>
            {category.name}
          </Link>
        </li>
      </p>
      {subcat}
    </>
  );
});

const onlinecategorylist = onlinecategorydata.category.map((category) => {
  let subcatonline = category.subcategory.map((subcategory) => {
    return (
      <li
        style={{
          listStyle: 'none',
          marginLeft: 150,
        }}
      >
        <Link
          to={
            'business-category/online-store/' +
            category.machine_name +
            '/' +
            subcategory.machine_name
          }
        >
          <img src={Arrowimg} width='12' />
          {subcategory.name}
        </Link>
      </li>
    );
  });
  return (
    <>
      <p style={{ marginLeft: 100, listStyle: 'square' }}>
        <li>
          <Link to={'business-category/online-store/' + category.machine_name}>
            {category.name}
          </Link>
        </li>
      </p>
      {subcatonline}
    </>
  );
});

const Htmlsitemap = () => {
  return (
    <>
      <Helmet>
        <title>HTML Sitemap - Mom n Pop Hub.</title>
        <meta name='description' content='html site map'></meta>
        <meta name='keywords' content='HTML SITEMAP Mom n Pop Hub'></meta>
        <meta name='publisher' content='Mom n Pop Hub'></meta>

        <link rel='canonical' href='/sitemap' />

        <meta property='twitter:title' content='sitemap - Mom n Pop Hub' />
        <meta
          property='twitter:description'
          content='htmls sitemap describes everything related to our website and a lot more.
'
        />
        <meta
          property='twitter:image'
          content={`${process.env.RAZZLE_RUNTIME_FRONTBASE_URL}/static/media/momnpophub-logo.1f15802f.svg`}
        />
        <meta property='twitter:card' content='html sitemap for Monnpop Hub' />
        <meta property='twitter:site' content='twitter id' />

        <meta property='og:title' content='htmls sitemap - Mom n Pop Hub' />
        <meta
          property='og:description'
          content='htmls sitemap describes everything related to  our website and a lot more.
'
        />
        <meta property='og:publisher' content='Mom n Pop Hub' />
        <meta property='og:locale' content='en_US' />
        <meta property='og:type' content='website' />
        <meta
          property='og:image'
          content={`${process.env.RAZZLE_RUNTIME_FRONTBASE_URL}/static/media/momnpophub-logo.1f15802f.svg`}
        />
        <meta property='og:site_name' content={process.env.RAZZLE_RUNTIME_FRONTBASE_URL} />
        <meta property='og:url' content='/sitemap' />
        <meta property='og:url' content={`${process.env.RAZZLE_RUNTIME_FRONTBASE_URL}/sitemap`} />
      </Helmet>

      <div className='term-padding'>
        <h1>Sitemap</h1>
        <p>
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/free-business-invitation/">Free Business Signup</a>
            </li>
            <li>
              <a href="/login/">Login</a>
            </li>
            <li>
              <a href="/business-category">Business Categories</a>
            </li>
            <li>
              <a href="/faq">Faq</a>
            </li>
            <li>
              <a href="/customer-business-app">Customer Business App</a>
            </li>
            <li>
              <a href="/term">Term</a>
            </li>
            <li>
              <a href="/privacy">Privacy</a>
            </li>
            <li>
              <a href="/blog">Blog</a>
            </li>
            <li>
              <a href="/blog/submit-blog">Submit your article</a>
            </li>
          </ul>
        </p>
        <ul>
          <p style={{ marginLeft: 50, listStyle: 'circle' }}>
            <li>
              <a href='/business-category/physical-store'>Physical store</a>
            </li>
          </p>
        </ul>

        <p>
          <ul>{physicalcategorylist}</ul>
        </p>
        <ul>
          <p style={{ marginLeft: 50, listStyle: 'circle' }}>
            <li>
              <a href='/business-category/online-store'>Online store</a>
            </li>
          </p>
        </ul>
        <p>
          <ul>{onlinecategorylist}</ul>
        </p>
      </div>
    </>
  );
};
export default Htmlsitemap;
