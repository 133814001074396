import React, { useEffect, useState } from 'react';
import ArticleForm from '../../blog/ArticleForm';
import { useLocation, useParams } from 'react-router-dom';
import { fetchArticlesUniqueAddress, userInfo } from '../../../api/blog';
import Cookies from 'js-cookie';

const EditorArticle = () => {
  const { uniqueAddress } = useParams();
  const [article, setArticle] = useState({});
  const [filterVisible, setFilterVisible] = useState(true);
  const [userData, setUserData] = useState(null);
  const [params, setParams] = useState(false);
  const location = useLocation();

  const authToken = Cookies.get('authtoken');
  useEffect(async () => {
    if (authToken) {
      try {
        const usId = Cookies.get('id');
        const fetchUserInfo = await userInfo(usId);
        await setUserData(fetchUserInfo?.user_editor);
      } catch (err) {
        setUserData(false);
      }
    }

    const searchParams = new URLSearchParams(location);
    const filterVisibleVal = searchParams.get('filterVisibleVal');
    if (filterVisibleVal !== null) {
      setFilterVisible(filterVisibleVal === 'true');
      setParams(true);
    }
  }, [location, authToken]);

  useEffect(async () => {
    if (params) {
      const articleFetch = await fetchArticlesUniqueAddress(uniqueAddress, filterVisible);
      setArticle(articleFetch[0]);
    }
  }, [uniqueAddress, userData, params]);

  return <>{userData && !!Object.keys(article).length && <ArticleForm userEditor={article} />}</>;
};

export default EditorArticle;
