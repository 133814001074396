import './ShareModal.css';

import React from 'react';
import { Modal } from 'react-bootstrap';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';

function ShareModal(props) {
  const { showModal, toggleModal, shareurl, businessName, businessDisc, titleheader } = props;

  const title = { businessName };
  return (
    <Modal show={showModal} onHide={toggleModal} centered dialogClassName='my-modal'>
      <div className='modal_Header'>
        <h5>{titleheader}</h5>
        <i className='icon-times' onClick={toggleModal} />
      </div>
      <div className='modal_Body'>
        <div className='business_block'>
          <img
            src='https://api.momnpophub.com/image/online_logo.png'
            alt='Online Logo Mom n Pop Hub'
          />

          <div className='business_details'>
            <h6>{businessName} </h6>
            <p className='business_deatils_discreption'>{businessDisc}</p>
          </div>
        </div>

        <>
          <span className='group_title'>Social Media </span>

          <div className='items_row'>
            <div className='icons_group'>
              <FacebookShareButton
                url={shareurl}
                quote={'Hello '}
                className='Demo__some-network__share-button'
              >
                <FacebookIcon size={32} round />
                <span className='icon_group_text'>Facebook</span>
              </FacebookShareButton>
            </div>
            <div className='icons_group'>
              <TwitterShareButton
                url={shareurl}
                title={title}
                className='Demo__some-network__share-button'
              >
                <TwitterIcon size={32} round />
                <span className='icon_group_text'>Twitter</span>
              </TwitterShareButton>
            </div>
          </div>
        </>

        <>
          <span className='group_title'> Messaging & Email</span>

          <div className='items_row'>
            <div className='icons_group'>
              <EmailShareButton
                url={shareurl}
                quote={'Hello '}
                className='Demo__some-network__share-button'
              >
                <EmailIcon size={32} round />
                <span className='icon_group_text'>Email</span>
              </EmailShareButton>
            </div>
            <div className='icons_group'>
              <WhatsappShareButton
                url={shareurl}
                title={title}
                className='Demo__some-network__share-button'
              >
                <WhatsappIcon size={32} round />
                <span className='icon_group_text'>WhatsApp</span>
              </WhatsappShareButton>
            </div>
          </div>

          <div className='items_row'>
            <div className='icons_group'>
              <FacebookMessengerShareButton
                url={shareurl}
                quote={'Hello '}
                className='Demo__some-network__share-button'
              >
                <FacebookMessengerIcon size={32} round />
                <span className='icon_group_text'>Messenger</span>
              </FacebookMessengerShareButton>
            </div>
            <div className='icons_group'>
              <TelegramShareButton
                url={shareurl}
                title={title}
                className='Demo__some-network__share-button'
              >
                <TelegramIcon size={32} round />
                <span className='icon_group_text'>Telegram</span>
              </TelegramShareButton>
            </div>
          </div>
        </>
        <>
          <span className='group_title'>Copy Link</span>
          <div className='copyable_text_field'>
            <div className='shareUrl_text'>{shareurl}</div>
            <button
              className='btn_custom'
              onClick={() => {
                navigator.clipboard.writeText(shareurl);
              }}
            >
              copy
            </button>
          </div>
        </>
      </div>
    </Modal>
  );
}

export default ShareModal;
