import React from 'react';

import ContactUs from '../../components/contact-us';
import StoreDescList from './components/store_desc_list';

//import Breadcrumb from "../../components/breadcrumbs/breadcrumb";

const StoreDescription = () => {
  return (
    <>
      {/* <Breadcrumb /> */}
      {/* <StoreDescHeader /> */}
      <StoreDescList />
      <ContactUs />
    </>
  );
};

export default StoreDescription;
