/* eslint-disable jsx-a11y/anchor-is-valid */
import './ShareBussinessProfile.css';

import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import Loader from 'react-loader-spinner';
import { useHistory, useLocation } from 'react-router-dom';

import { callApi } from '../../../api/API';
import Exclamatory from '../../../assets/images/exclamatory.svg';
import ShareModal from '../react-share/ShareModal';
import {fixUrl} from "../../../utils/utils";

const ShareBussinessProfile = ({ profile, apiStatus }) => {
  const [profileData, setProfileData] = useState(profile);

  const [url, setUrl] = useState('');
  const [mapUrl, setMapUrl] = useState('');
  const [streetAdd, setstreetAdd] = useState('');
  const [addLocal, setaddLocal] = useState('');
  const [addCountry, setaddCountry] = useState('');
  const [addRegio, setaddRegio] = useState('');
  const [postalCO, setpostalCO] = useState('');
  const [geoLAT, setgeoLAT] = useState('');
  const [geoLONGI, setgeoLONGI] = useState('');
  const [metaDescri, setmetaDescri] = useState('');
  const [metaShortDesc, setmetaShortDesc] = useState('');
  const [metaTitle, setmetaTitle] = useState('');
  const [shareLink, setshareLink] = useState('');
  const [loadProfleApiCall, setLoadProfileApiCall] = useState({
    didCall: false,
    success: false,
    error: false,
  });
  const [showModal, setShowModal] = useState(false);

  let title = '';
  try {
    let urlpath = location.pathname.split('/');
    let urlarray = urlpath[urlpath.length - 1];
    title = urlarray.replaceAll(/-/g, ' ');
  } catch (e) {}

  const history = useHistory();
  const location = useLocation();

  const { register, handleSubmit, errors, ...form } = useForm();

  const loadProfileData = useCallback(async () => {
    setLoadProfileApiCall({ didCall: true, success: false, error: false });

    // const result = await callApi(`/businessinfo/profilejson/${id}`, "POST", {
    //   id: user_id,
    //   email: "",
    // });

    const result = await callApi(`/businessinfo/profilejsonBname/${title}`, 'GET', {
      //id: user_id,
      //email: "",
    });

    console.log( "my result",result)
    if (result.status == 404) {
      history.push('/notfound');
    }
    if (Object.keys(result).length > 0) {
      if (result.profile[0].baddress) {
        let tempAdd = result.profile[0].baddress.split(',');
        setstreetAdd(tempAdd[0]);
        setaddLocal(tempAdd[1]);
        setaddCountry(tempAdd[3]);

        let temp_state = tempAdd[2] ? tempAdd[2].split(' ') : '';
        // console.log("temp_state",temp_state)
        setpostalCO(temp_state[2]);
        setaddRegio(temp_state[1]);
      }
      if (result?.profile[0]?.description?.length >= 140) {
        let tempdes = result.profile[0].description;
        setmetaDescri(tempdes);
      } else {
        // console.log(result.profile[0].bname)

        let tempdes = result.profile[0].bname + ', ';
        // console.log("tempdes",tempdes)
        if (result.profile[0].baddress) {
          let tempAdd = result.profile[0].baddress.split(',');
          // console.log("tempAdd")

          if (tempAdd[1] !== undefined) {
            tempdes += tempAdd[1] + ', ';
          }
          if (tempAdd[3] !== undefined) {
            tempdes += tempAdd[3] + '- ';
          }
        }
        tempdes += result.profile[0].description;
        // console.log("descripto", tempdes)
        setmetaDescri(tempdes);
      }
      // ashok ///
      //console.log(result.profile[0].shortdescription + '*******');
      //if(result.profile[0].shortdescription.length >= 0){
      if (
        result?.profile[0]?.shortdescription &&
        result?.profile[0]?.shortdescription?.length >= 160
      ) {
        let tempShortdesc = result?.profile[0]?.description.slice(0, 160) + '...';
        setmetaShortDesc(tempShortdesc);
      } else if (result?.profile[0]?.shortdescription !== undefined) {
        let tempShortdesc = result.profile[0].shortdescription;

        setmetaShortDesc(tempShortdesc);
      }
      //}
      if (result?.profile[0]?.lat) {
        setgeoLAT(result.profile[0].lat);
        setgeoLONGI(result.profile[0].long);
      }

      let tempMetatitle = '';
      tempMetatitle = result.profile[0].bname;
      if (result.profile[0].subcategory) {
        tempMetatitle += ' - ' + result.profile[0].subcategory;
      }

      let sharelink = '';
      if (result.profile[0].btype === 'offline') {
        sharelink = 'business-category/physical-store/';
      } else {
        sharelink = 'business-category/online-store/';
      }
      if (result.profile[0].category) {
        sharelink += result.profile[0].category + '/';
      }
      if (result.profile[0].subcategory) {
        sharelink += result.profile[0].subcategory + '/';
      }
      setshareLink(sharelink);

      tempMetatitle += ' - Mom n Pop Hub';

      setmetaTitle(tempMetatitle);

      setProfileData(result);
      const weblink = result.profile[0].website.replace('https://', '');
      const url = 'https://' + weblink;
      setUrl(url);

      const mapUr =
        'http://maps.google.com/maps?q=' + result.profile[0].lat + ',' + result.profile[0].long;
      // console.log(mapUr);
      setMapUrl(mapUr);
      setLoadProfileApiCall({ didCall: false, success: true, error: false });
    } else {
      setLoadProfileApiCall({ didCall: false, success: false, error: true });
    }
  }, []);

  const modal =
    profileData && showModal ? (
      <ShareModal
        businessDisc={profileData.description}
        showModal={showModal}
        toggleModal={() => setShowModal(!showModal)}
        shareurl={location.origin + location.pathname}
        businessName={profileData.bname}
        titleheader='Share this Business with your friends and family'
      />
    ) : null;

  useEffect(() => {
    // loadProfileData();
  }, [loadProfileData, title]);

  return (
    <>
      {apiStatus?.loading && (
        <div className='text-center'>
          <Loader type='Bars' color='#FF992E' />
          <p>Loading profile information...</p>
        </div>
      )}

      {apiStatus?.success && (
        <div className='business-profile-page-share'>
          {profileData && (
            <>
              <Helmet>
                <title>{profileData.metaTitle} </title>
                <meta name='description' content={profileData.metaShortDesc}></meta>

                <meta name='publisher' content='Mom n Pop Hub'></meta>
                <meta property='twitter:title' content={profileData.metaTitle} />
                <meta property='twitter:description' content={profileData.description} />
                <meta
                  property='twitter:image'
                  content={
                    profileData.btype == 'online'
                      ? 'https://test.momnpophub.com:3005/image/online_logo.png'
                      : 'https://test.momnpophub.com:3005/image/store_logo.png'
                  }
                />
                <meta property='twitter:card' content={profileData.description} />
                <meta property='twitter:site' content='twitter id' />

                <meta property='og:title' content={profileData.metaTitle} />
                <meta property='og:description' content={profileData.description} />
                <meta property='og:publisher' content='Mom n Pop Hub' />
                <meta property='og:locale' content='en_US' />
                <meta property='og:type' content='website' />
                <meta
                  property='og:image'
                  content={
                    profileData.btype == 'online'
                      ? 'https://test.momnpophub.com:3005/image/online_logo.png'
                      : 'https://test.momnpophub.com:3005/image/store_logo.png'
                  }
                />
                <meta property='og:site_name' content='https://momnpophub.com/' />
                <meta property='og:url' content={location.pathname} />
                <meta property='og:url' content={location.href} />
                <script type='application/ld+json'>
                  {JSON.stringify({
                    '@type': 'LocalBusiness',
                    name: profileData.bname,
                    category: profileData.caegory,
                    description: profileData.description,
                    image: [
                      `${process.env.RAZZLE_RUNTIME_FRONTBASE_URL}/image/${
                        profileData.btype == 'online' ? 'online' : 'store'
                      }_logo.png`,
                    ],
                    address: {
                      '@type': 'PostalAddress',
                      streetAddress: profileData.addressStreet,
                      addressLocality: profileData.addressLocal,
                      addressRegion: profileData.addressRegion,
                      postalCode: profileData.addressPostal,
                      addressCountry: profileData.addressCountry,
                    },

                    geo: {
                      '@type': 'GeoCoordinates',
                      latitude: profileData.latitude,
                      longitude: profileData.longitude,
                    },

                    url: location.pathname,
                    telephone: profileData.phonenumber,
                    '@context': 'http://schema.org',
                  })}
                </script>
              </Helmet>

              {/* {console.log(profileData)} */}
              {modal}

              <div className='d-flex flex-wrap justify-content-between align-items-start border-bottom py-3 mb-2 px-4'>
                <div className='d-flex justify-content-between align-items-start profile-heading'>
                  Profile
                </div>
                <a
                  onClick={() => setShowModal(!showModal)}
                  type='buttn'
                  className='btn bg-transparent border-0 shadow-none p-0'
                >
                  <i className='icon-share-square' />
                </a>
              </div>
              <div className='bus_mid_share border-bottom'>
                <div className='bus_mid_image'>
                  {profileData.btype == 'online' ? (
                    <img
                      src='https://api.momnpophub.com/image/online_logo.png'
                      alt='Online Logo Mom n Pop Hub'
                    />
                  ) : (
                    <img
                      src='https://api.momnpophub.com/image/store_logo.png'
                      alt='Store Logo Mom n Pop Hub'
                    />
                  )}
                </div>
                <div className='bus_mid_flex '>
                  <h1 className='business-name'>
                    {' '}
                    <p className='business-profile-name text-decoration-none'>
                      {profileData.bname}
                    </p>
                  </h1>

                  {profileData.claimed === '0' && (
                    <div className='d-flex flex-row align-items-center exclamatory'>
                      <img src={Exclamatory} width='12' height='12' />
                      <p> Unclaimed </p>
                    </div>
                  )}

                  <div className='bus_mid_share_data'>
                    <div className='bus_mid_data1'>
                      <h2 className='business-phone'>
                        <i className='icon-phone business-profile-icon' />
                        <a href={`tel:${profileData.phonenumber}`} className='textNumber'>
                          {profileData.phonenumber}
                        </a>
                      </h2>
                    </div>
                    <div className='bus_mid_data2'>
                      <h2 className='business-link'>
                        <i className='icon-globe business-profile-icon' />
                        <a
                          href={fixUrl(profileData.website)}
                          className='orange-link'
                        >
                          {profileData.bname} Website
                        </a>
                      </h2>
                    </div>

                    <div>
                      {profileData.baddress && (
                        <h2 className='business-location'>
                          <i className='icon-map-marker-alt business-profile-icon' />
                          <a
                            href={profileData.mapUrl}
                            className='orange-link'
                          >
                            {profileData.baddress}
                          </a>
                        </h2>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-between align-items-start px-4'>
                <p className='aboutBussinessDisp'>
                  <span className='about-the-business-heading'>
                    <span className='aboutBussinessSpan'>About the business</span>
                  </span>
                  <h3 className='about-business-details'>{profileData?.description}</h3>
                </p>
              </div>
            </>
          )}
        </div>
      )}

      {apiStatus.error && (
        <div className='text-center' style={{ padding: '5%' }}>
          <p style={{ color: 'red' }}>Problem loading profile</p>
          <Button onClick={() => loadProfileData()}>Try again</Button>
        </div>
      )}
    </>
  );
};

export default ShareBussinessProfile;
