import './components/category.css';

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import ContactUs from '../../components/contact-us';
import CategoryList from './components/category';

const Categories = (props) => {
  useEffect(() => {
    props.setWhere('Business');
  }, []);
  let mtitle = 'Choose the relevant categories to list your business with Mom n Pop Hub';
  let metatitle = mtitle.substring(0, 60);
  return (
    <>
      <Helmet>
        <title>{metatitle}</title>

        <meta
          name='keywords'
          content='Register Business with Mom n Pop Hub, How Mom n Pop Hub works, Find Business, Mom n Pop Hub'
        ></meta>
        <meta name='publisher' content='Mom n Pop'></meta>
        <link rel='canonical' href='/free-business-invitation' />
        <meta name='publisher' content='Mom n Pop'></meta>
        <meta
          name='description'
          content='Physical store and online store, we have 2 types of businesses categories to list your small business with Mom n Pop Hub. Signup today and will list your business for free.'
        ></meta>

        <meta property='twitter:title' content='Free Business Signup - Mom n Pop Hub' />
        <meta
          property='twitter:description'
          content='Simply fill the signup form on Mom n Pop Hub to list your small business on our mobile app platform. Our signup is geared towards brick and mortar as well e-commerce small businesses.'
        />
        <meta
          property='twitter:image'
          content='https://momnpophub.com/static/media/momnpophub-logo.1f15802f.svg'
        />
        <meta property='twitter:card' content='List your business On Mom n Pop Hub for FREE' />
        <meta property='twitter:site' content='twitter id' />

        <meta property='og:title' content='Free Business Signup - Mom n Pop Hub' />
        <meta
          property='og:description'
          content='Simply fill the signup form on Mom n Pop Hub to list your small business on our mobile app platform. Our signup is geared towards brick and mortar as well e-commerce small businesses.'
        />
        <meta property='og:publisher' content='Mom n Pop Hub' />
        <meta property='og:locale' content='en_US' />
        <meta property='og:type' content='website' />
        <meta
          property='og:image'
          content='https://momnpophub.com/static/media/momnpophub-logo.1f15802f.svg'
        />
        <meta property='og:site_name' content='https://momnpophub.com/' />
        <meta property='og:url' content='/free-business-invitation' />
        <meta property='og:url' content='https://momnpophub.com/' />
      </Helmet>

      {/* <AboutSearch history={history} setSelectedProfileId={props.setSelectedProfileId} setSelectedDealId={props.setSelectedDealId} /> */}
      {/* <CategoryList/> */}

      <CategoryList />
      <ContactUs />
    </>
  );
};

export default Categories;
