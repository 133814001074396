import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import FAQList from './components/accordion';

const FAQ = (props) => {
  useEffect(() => {
    props.setWhere('FAQ');
  }, []);
  return (
    <>
      <Helmet>
        <title>Frequently Asked Questions - Mom n Pop Hub</title>
        <meta
          name='description'
          content='Visit our FAQ page and get your queries answered. Here are some common questions small business owners may have when signing up for a free business listing or posting a deal on the Mom n Pop Hub.'
        ></meta>
        <meta name="robots" content="index, follow" />
      </Helmet>
      <FAQList />
    </>
  );
};
export default FAQ;
