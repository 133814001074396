import React, { useState } from 'react';
import searchIcon from '../../../../assets/images/blog/search-icon.svg';

const Search = ({ onSearch, children,}) => {
  const [searchText, setSearchText] = useState('');

  const handleButtonClick = () => {
    onSearch(searchText);
  };

  return (
    <div className="blog__sidebar-search">
      <img className="blog__sidebar-search_icon" src={searchIcon} />
      <input
        className="blog__sidebar-search_input"
        type="text"
        placeholder="What are you looking for?"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
      {React.cloneElement(children, { onClick: handleButtonClick })}
    </div>
  );
};

export default Search;
