import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';

import UserContext from '../contexts/userContext';

const UnAuthenticatedRoute = ({ component, ...props }) => {
  const { userData } = useContext(UserContext);

  const RouteComponent = component;

  if (!userData.token) {
    return <Route render={(routeProps) => <RouteComponent {...routeProps} />} {...props} />;
  }

  return <Redirect to={{ pathname: '/business-home' }} />;
};

export default UnAuthenticatedRoute;
