export const QUESTIONS = [
  {
    id: 1,
    question: 'Frequently Asked Questions (FAQ)',
    answer:
      'Before listing your small business on Mom n Pop Hub, get some quick information and answers to your questions. Below are some common questions small business owners had while signing up for free business listing or posting a deal on Mom n Pop Hub.',
  },
  {
    id: 2,
    question: 'What is Mom n Pop Hub?',
    answer:
      'Mom n Pop Hub is a business directory exclusive to small business owners, we have spend considerable efforts in keeping our directory exclusive to small businesses. We allow 2 types of small businesses to list on Mom n Pop Hub- 1. Brick and mortar stores 2. Online only stores. For detail list of categories within these 2 categories of small businesses, please visit here <insert link to the landing page of categories> ',
  },
  {
    id: 3,
    question: 'What type of businesses are allowed to list on Mom n Pop Hub?',
    answer:
      'Mom n Pop Hub is a business directory exclusive to small business owners, we have spend considerable efforts in keeping our directory exclusive to small businesses. We allow 2 types of small businesses to list on Mom n Pop Hub- 1. Brick and mortar stores 2. Online only stores. For detail list of categories within these 2 categories of small businesses, please visit here <insert link to the landing page of categories>',
  },
  {
    id: 4,
    question: 'Does business listing help increase my small business?',
    answer:
      '84% of customers trust online resources as a personal recommendation before making any purchase. Thus, having a strong online presence is a must to boost up your business, and listing is the first step towards it. Furthermore, in a increasingly busy marketing space small businesses are constantly competing with massive marketing budgets of large corporations, the need for a small business to be represented by a brand like Mom n Pop Hub which is only exclusive to small businesses is being increasingly felt.',
  },
  {
    id: 5,
    question: 'What to include in the business listing?',
    answer: `<ul>
      <li>
          <p>Business name/title</p>
      </li>
      <li>
          <p>Address (you are allowed to skip this if you are a online business)</p>
      </li>
      <li>
          <p>Contact details</p>
      </li>
      <li>
          <p>Business Website URL</p>
      </li>
      <li>
          <p>Business long description&nbsp;</p>
      </li>
      <li>
          <p>Business short description&nbsp;</p>
      </li>
      <li>
          <p>Business category</p>
      </li>
      <li>
          <p>Business sub-category</p>
      </li>
  </ul>
  <p><br></p>
  <p>The small business owners can fill out the form at&nbsp;</p>
  <p><a style="color: #FFB648" target="_blank" href="https://momnpophub.com/free-business-invitation">https://momnpophub.com/free-business-invitation</a> to sign up with Mom n Pop Hub.&nbsp;</p>`,
  },
  {
    id: 6,
    question: 'What does an unclaimed tag on business list/profile indicates? ',
    answer: `<p>A unclaimed business is a business listing which has not yet been verified by the small business owner. If you are a small business owner who found their business in unclaimed status and would like to change that to remove that status, please contact us at&nbsp;<a style="color: #FFB648" href="mailto:contact@momnpophub.com">contact@momnpophub.com</a> and we will help you with the verification process.</p>`,
  },
  {
    id: 7,
    question: 'How do I know if a business is already listed on Mom n Pop Hub?',
    answer: `<p>Please use search functionality on our website to&nbsp;<a style="color: #FFB648" target="_blank" href="https://momnpophub.com">https://momnpophub.com</a> or download our mobile app on Apple <a style="color: #FFB648" target="_blank" href="https://apps.apple.com/us/app/mom-n-pop-hub/id1494101666">(https://apps.apple.com/us/app/mom-n-pop-hub/id1494101666)</a> or Google app <a style="color: #FFB648" target="_blank" href="https://play.google.com/store/apps/details?id=com.momnpophub">(https://play.google.com/store/apps/details?id=com.momnpophub)</a> store to search for a business near you without creating a new account.&nbsp;</p>`,
  },
  {
    id: 8,
    question: 'Why do I need a Business listing?',
    answer:
      'Online business listings allow you to achieve better search engine rankings and improve mobile device results. Having access to a business listing will allow consumers to easily find you. Further, your online business presence will help you divert targeted traffic to your website or physical brick and mortar store.',
  },
  {
    id: 9,
    question:
      'How do I spread the word for customers to know that my business is listed on Mom n Pop Hub?',
    answer: `<p>Word of mouth is an important tactic for any small business. We encourage you to use the share icon on your social media feed to let customers know they are listed on Mom n Pop Hub. We also mail small business owners free stickers to post at prominent areas of their physical store, please email us the request at&nbsp;<a style="color: #FFB648" href="mailto:contact@momnpophub.com">contact@momnpophub.com</a> and we will send you a FREE sticker to your mailing address.&nbsp;</p>`,
  },
  {
    id: 10,
    question: 'How does Mom n Pop Hub benefit small businesses?',
    answer: `<p>Compared to other business promotional means, our free business listing service boosts your online presence and builds trust among your customers.</p>
      <ul>
          <li>
              <p>Attract and retain customers</p>
          </li>
          <li>
              <p>Mobile apps for small businesses</p>
          </li>
          <li>
              <p>Free CRM-500 Customers&nbsp;</p>
          </li>
      </ul>
      <p>Our sign-up service allows you to get a free profile page for your business on our website and mobile app to grow your business.</p>`,
  },
  {
    id: 11,
    question: 'How do I create a deal for my small business ?',
    answer: 'When you list your business, Mom n Pop Hub platform allows you to create deals.',
  },
  {
    id: 12,
    question: 'What kind of deals can I post to help me generate revenue for my small business?',
    answer: `<p>Great question! A deal could be anything you are offering for a limited time. Some examples of deals we have found to be successful for small business owners are-&nbsp;</p>
      <ol>
          <li>
              <p>Discounted gift cards.&nbsp;</p>
          </li>
          <li>
              <p>A discount across your entire store on overall purchase.&nbsp;</p>
          </li>
          <li>
              <p>A discount for a hot product you are looking to sell.&nbsp;</p>
          </li>
      </ol>
      <p><br></p>
      <p>Please feel to contact us at&nbsp;<a style="color: #FFB648" href="mailto:contact@momnpophub.com">contact@momnpophub.com</a> if you have questions or just need ideas on deals you want to post for sale.&nbsp;</p>
      <p><br></p>
      <p>We also offer a free 15 min strategy session for a small business to get demo of our system and brainstorm ideas on deals they could post using our system. Please request for the free demo session by reserving your spot&nbsp;<a style="color: #FFB648" target="_blank" href="https://calendly.com/momnpophub/mom-n-pop-hub-demo?month=2022-03">here</a>.</p>`,
  },
  {
    id: 13,
    question: 'How do I inform my existing customers of a deal we posted on Mom n Pop Hub?',
    answer:
      'You can add your customers on our FREE CRM and they will get a email asking them to download Mom n Pop Hub mobile app as they were invited by your business. They will continue getting email on any deal you post even if they don’t download and install the Mom n Pop Hub mobile app instantly. You can also share the deal via your existing email service provider if you wish or post the link to the deal on your social media feed. Easily shareable links are available on each deal via the website or mobile app listing page.',
  },
  {
    id: 14,
    question: 'How long does it takes for my listing or deal to go live?',
    answer:
      'A listing goes live within seconds of successful signup on our website as well as mobile app. A deal also goes live instantly as long as your publish date is the current date, if the publish date is not  current date then you will have to wait for the deal to go live as per the publish date you have setup while creating the deal.',
  },
  {
    id: 15,
    question: 'Should I hire an expert for business listings?',
    answer: `<p>Online business listing involves some steps, including adding business address, description, and contact details. You can do it yourself by simply filling up the signup form here:&nbsp;<a style="color: #FFB648" target="_blank" href="https://momnpophub.com/free-business-invitation">https://momnpophub.com/free-business-invitation</a></p>`,
  },
  {
    id: 16,
    question: 'How does listing help me get more traffic?',
    answer:
      '75% of consumers use the Internet as an information source when shopping locally for products and services.The online business listing gives you the power to attract and engage customers when they search for your business.',
  },
  {
    id: 17,
    question:
      'How should I contact someone at Mom n Pop Hub for technical help or any other questions I have?',
    answer: `<p>Please email us at&nbsp;<a style="color: #FFB648" href="mailto:contact@momnpophub.com">contact@momnpophub.com</a> and we will revert back to your question within 48-72 business hours.&nbsp;</p>`,
  },
];
