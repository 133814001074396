import Cookies from 'js-cookie';
import React, { useEffect } from 'react';

const Temp = () => {
  useEffect(() => {
    Cookies.remove('authtoken');
    Cookies.remove('uname');
  }, []);
  return (
    <>
      <p>Hello!</p>
    </>
  );
};
export default Temp;
