import axios from 'axios';
import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Loader from 'react-loader-spinner';
import OtpInput from './OTPVerify';
import "./DeleteUser.css";
import toast from 'react-hot-toast';


const DeleteUser = () => {
  const { register, handleSubmit, errors } = useForm();
  const [otpSent, setOtpSent] = useState(false);
  const [callingApi, setCallingApi] = useState(false);
  const [userId, setUserId] = useState('');
  const [robot, setRobot] = useState({ isRobot: true, showRobotErr: false });
  const [emailId, setEmailId] = useState('');

  const validateNotRobot = () => {
    if (robot.isRobot) {
      setRobot({ ...robot, showRobotErr: true });
      return false;
    }
    return true;
  };
  

  const handleSendOtp = (payload) => {
    if (!validateNotRobot()) return;
    setCallingApi(true);
    axios
      .post(process.env.RAZZLE_RUNTIME_APP_BASEURL + '/email/send-otp', payload)
      .then((res) => {
        setEmailId(payload.email)
        setCallingApi(false);
        if (res.status === 200) {
          setOtpSent(true);
          setUserId(res?.data?.id)
          toast.success(`${res?.data?.message}`)
        }

      })
      .catch((err) => {
        toast.error(`${err?.response?.data?.message}`)
        setCallingApi(false);
      });
  };

  return (
    <>
      <div className='col-sm-6 mx-auto text-center form-group'>
        <h1 className='general-title'>Mobile App User Delete Request</h1>

        {otpSent && (
          <>
            <p>Otp sent to your email address.</p>
            <OtpInput userId={userId} setOtpSent={setOtpSent} resendOtp={handleSendOtp} emailId={emailId} />
          </>
        )}

        {!otpSent && (

          <div>

            <div className="delete-info a1_sub1_text flex-column">
              <div>
                We respect user privacy and have made it seamless for our mobile app user to request for their data and profile deletion. Upon completion of the form field below, following data points will be deleted permanently within a few minutes:
              </div>

              <ol className="my-2 text-left">
                <li> <b> Profile information: </b> Email, First, Last name and any other identifiers.</li>
                <li> <b> App usage activity: </b> Session details like last login, favorite shops or favorite deals.</li>
                <li><b> Purchase activity: </b>Purchase activity: Deals purchased</li>
              </ol>

              <div className="mt-2">
                After you submit the email request below, we will send you a email confirmation with OTP and we request you to enter the OTP here for us to verify your ownership of the email being requested for data deletion. OTP is valid only for 20 min and a new OTP can be requested here if your OTP has expired.
              </div>

              <div className="mt-2">
                Once successfully verified, we request you to wait for a email confirming the deletion. If the app is still on your mobile app, we request you to delete the mobile app from your iOS or Android device. You are welcome to continue to use Mom n Pop Hub mobile app but for your privacy we request you to logout. Once logged out from the mobile app, your account will be deleted permanently. You may continue using the app as a logged out user to find your favorite small business around you.
              </div>
            </div>

            <div>


            </div>

            <Form onSubmit={handleSubmit(handleSendOtp)} className='login-form'>
              {callingApi && (
                <>
                  <p>Sending otp to your email id</p>
                  <Loader color='#FF992E' />
                </>
              )}
              <Form.Group>
                <Form.Label className='modal-label'>Email address</Form.Label>
                <Form.Control
                  name='email'
                  ref={register({ required: true, pattern: /\S+@\S+\.\S+/ })}
                  type='email'
                  className={`input ${errors.email ? 'is-invalid' : ''} shadow-none`}
                  placeholder='Email Address *'
                />
                {errors.email && errors.email.type === 'required' && (
                  <p style={{ color: 'red' }}>Email address is required</p>
                )}
                {errors.email && errors.email.type === 'pattern' && (
                  <p style={{ color: 'red' }}>Invalid email</p>
                )}
              </Form.Group>

              <Form.Group className='text-center'>
                <ReCAPTCHA
                  onChange={(value) => {
                    setRobot({ isRobot: false, showRobotErr: false });
                  }}
                  onExpired={(expired) => {
                    setRobot({ isRobot: true, showRobotErr: false });
                  }}
                  sitekey='6Lfea-IZAAAAAAygbpzANjWTq2jzMJNNRSdmidaO'
                />
                {robot.showRobotErr && <p style={{ color: 'red' }}>Verify you are not robot</p>}
              </Form.Group>

              <Button
                variant='submit'
                className='red-btn'
                type='submit'
              >
                Send OTP
              </Button>
            </Form>

          </div>
        )}
      </div>
    </>
  );
};

export default DeleteUser;
